﻿@import '../style.scss';

.control-body {
    padding: 0;
    background: $color-0xf3f0eb;
    height: 100%;

    .section {
        display: flex;
        flex-direction: column;
    }

    .table {
        padding: 0 10%;
        margin: 20px 0;

        table {
            width: 100%;
        }

        tr {
            height: 60px;
            border-bottom: solid 1px $color-0xd7ceba;
            background: $color-0xffffff;
        }

        tr.header {
            background: $color-0xf3f0eb;
        }

        td {
            background: $color-0xffffff;
            font-size: 16px;
            margin: auto;
        }

        th {
            text-align: center;
            font-size: 16px;
            margin: auto;

            .free-text {
                font-size: 12px;
            }
        }

        th.info {
        }

        th.standard-premium {
            padding: 0 5px;
        }

        td.info {
            padding: 0 5px;
        }

        td.standard-premium {
            text-align: center;

            span.sprite {
                margin: auto;
            }
        }
    }
}
