.shortcut-icon-popup-modal {
    .modal-content {
        .modal-body {
            .shortcut-icon-popup {
                div {
                    display: block;
                }
            }
        }
    }
}
