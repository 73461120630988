﻿@import 'colors.scss';

.tab-menu {
    $button-size: 48px;
    padding-top: 1px;

    position: relative;
    width: $button-size;

    .tabs-menu-scroll {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        overflow-y: auto;
        overflow-x: hidden;
        background: $color-light-gray;
        display: flex;
        flex-direction: column;
        box-shadow: 0 1px 3px 0 $color-medium-gray;
        z-index: 2;

        .left-navigation-separator {
            width: 48px;
            display: flex;
            align-items: center;
            justify-content: center;

            .separator {
                height: 1px;
                background: $color-light-yellowish-gray;
                border: 1px solid $color-light-yellowish-gray;
                width: 44px;
            }
        }

        .tab-button {
            border: none;
            background: none;
            margin: 0;
            padding: 0;
            height: $button-size;
            flex-shrink: 0;
            width: 100%;

            &:focus {
                outline: none;
            }

             &-selected {
                background: $color-light-yellowish-gray;
            }

            &:hover {
                background: $color-light-yellowish-gray;
            }

            .tab-button-content {
                display: flex;
                height: 100%;
                position: relative;
                align-items: center;
                justify-content: center;
                margin-left: -4px;
                border-bottom: $color-medium-gray 1px solid;

                .tab-button-image {
                    display: flex;
                    width: 32px;
                    height: 32px;

                    .image {
                        display: flex;
                        align-items: center;
                        flex-grow: 1;
                        min-width: 0;

                        &.image-menu {
                            justify-content: center;
                        }

                        .sprite {
                            display: inline-block;
                        }
                    }
                }
            }
        }
    }
}