@import '../../../src/styles/control-helpers.scss';

.dropdown {
    .control-container {
        position: relative;
        width: 100%;

        .item {
            display: flex;
            align-items: center;
            margin-left: -10px;

            .sub-item {
                flex: 0 0 16px;
            }

            .text, .image {
                margin: 0 0 0 10px;
                padding: 0;
            }

            .text {
                overflow: hidden;
                text-overflow: ellipsis;
                min-width: 50%;
                text-align: left;
                width: 100%;
            }
        }

        .space {
            flex-grow: 1;
            min-width: 5px;
        }

        .dropdown-button {
            @include controlStyle($style-dropdown);
            width: 100%;
            padding: 0;
            min-height: 32px;
            display: block;
            overflow: hidden;
            border: 1px solid $color-medium-gray;
            color: $color-iron;
            font-weight: bold;

            &:hover:not([disabled]) {
                @include controlStyle($style-dropdown-hover);
            }

            &:focus {
                @include controlStyle($style-dropdown-focus);
                outline: 0;
            }

            &[disabled] {
                @include controlStyle($style-dropdown-disabled);
                cursor: not-allowed;

                .caret-container {
                    .caret {
                        color: $color-white;
                    }
                }
            }

            .button-item {
                position: relative;
                padding: 6px 8px;
                min-width: 0;
                min-height: 30px;
                overflow: hidden;
                width: 100%;
            }

            .caret-container {
                display: flex;
                flex-direction: column;
                width: 16px;
                background: $color-light-tangeloish-gray;
                display: flex;
                justify-content: center;
                align-self: stretch;
                flex-shrink: 0;
                padding-right: 1px;

                .caret {
                    margin-left: auto;
                    margin-right: 20px;
                    color: $color-iron;
                }

                .caret-up {
                    border-top: none;
                    border-bottom: 4px dashed;
                    margin-bottom: 2px;
                }
            }

            .text {
                white-space: nowrap;
            }
        }

        .dropdown-button-container {
            display: flex;
            align-items: center;
            position: relative;
            top: 0;
            left: 0;
        }

        .dropdown-items {
            @include controlStyle($style-dropdown-item);
            display: none;
            position: absolute;
            left: 0;
            right: 0;
            top: 100%;
            z-index: 10000;
            max-height: 336px;
            overflow-y: auto;
            overflow-x: hidden;
            text-overflow: ellipsis;
            background-clip: padding-box;
            box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);

            &.opend {
                display: block;
            }

            &.inline {
                padding-left: 5px;
                padding-right: 5px;

                .dropdown-item {
                    display: inline-block;
                    width: auto;
                    vertical-align: middle;
                }
            }

            .dropdown-item {
                @include controlColor($style-dropdown-item-color);
                @include controlSize($style-dropdown-item-size);
                @include controlWeight($style-dropdown-item-weight);
                display: block;
                width: 100%;
                padding: 0;
                margin: 0;
                border: none;
                background: none;

                &, &:hover, &:active {
                    outline: none;
                }

                &:hover {
                    @include controlStyle($style-dropdown-item-hover);
                }

                &.selected, &.selected:hover {
                    @include controlStyle($style-dropdown-item-selected);
                }

                &.disabled, &.disabled:hover {
                    @include controlStyle($style-dropdown-item-disabled);
                    cursor: not-allowed;
                }


                .item {
                    position: relative;
                    padding: 5px 8px;
                }

                .text {
                    white-space: pre-line;
                    font-weight: bold;
                }
            }

            .dropdown-header {
                margin: 5px 0;
                padding: 2px 8px;
                overflow: hidden;
                text-overflow: ellipsis;
                font-size: 12px;
                background: #222;
                margin-left: -5px;
                margin-right: -5px;
                color: #9b9b9b;
            }
        }
    }
}
