﻿.grid-bom-control {
     height: 785px;
     position: absolute;
     left: 0;
     right: 0;
     top: 0;

     $lineheight: 58px;

    .grid {
        height: 785px;

        .ui-grid-top-panel {
            background: #f3f3f3;
            color: #111;
        }

        .ui-grid-viewport
        {
            overflow:auto !important;
        }

        .ui-grid-pager-row-count-label, .ui-grid-pager-max-pages-number
        {
            line-height: 27px;
        }

        .ui-grid-pager-max-pages-number {
            font-size:10pt;
        }

        .grid-cell {
            text-align:center;
            overflow: visible;

            &-checkbox {
                line-height:$lineheight;
            }

            textarea {
                width: 100%;
                height: 100%;
                min-height: 100%;
                max-height: 100%;
                min-width: 100%;
                max-width: 100%;
                resize: none;
                border: 1px solid #d4d4d4;
            }
            
            input[type='text'] {
                line-height: $lineheight;
                height: 100%;
                padding-left: 6px;
                padding-right: 6px;
            }

            &-label {
                text-align:left;
                padding-left: 6px;
                line-height: $lineheight;
            }

            &-btn {
                line-height:$lineheight;
            }
        }
   
        .grid-cell-numeric
        {
            &-label {
                text-align:right;
                padding-right: 6px;
            }

            input {
                text-align:right;
            }
        }

        .grid-input {
            input {
                max-width:100% !important;
            }
        }
    }
}

.grid-dropdown {
    .button-item, .item {
        margin: 0 16px 0 6px;
    }

    .image, .sprite, .sprite-responsive {
        max-width: 100px;
    }
}