﻿.grid-bom-details-control {
     width: 100%;
     height: 590px;
     position: absolute;
     left: 0;
     right: 0;
     top: 0;

    .grid {
        height: 580px;

        .ui-grid-top-panel {
            background: #f3f3f3;
            color: #111;
        }

        .dropdown-toggle {
            padding: 0;
	        border: none;
	        height: 36px;
        }

        .button-item, .item {
            margin: 0 16px 0 6px;
        }

        .image, .sprite, .sprite-responsive {
            max-width: 100px;
        }

        .orderAmount-cell-label
        {
            font-weight:bold;
            margin-right: 2px;
            margin-left: 2px;
        }

        .ui-grid-viewport
        {
            overflow:auto !important;
        }

        .ui-grid-pager-row-count-label, .ui-grid-pager-max-pages-number
        {
            line-height:27px;
        }

        .ui-grid-pager-max-pages-number {
            font-size:10pt;
        }

        .grid-cell {
            text-align:center;
            overflow: visible;

            &-checkbox {
                padding-top:8px;
            }

            &-text {
                padding-top: 8px;
            }

            &-label {
                padding-top: 8px;
            }
        }
   
        .grid-cell-numeric
        {
            &-label {
                text-align:right;
                padding-right: 6px;
                padding-top:8px;
            }

            $size: 18px;
            .round-button {
	            width:$size;
                margin-left:-12px;
            }
            .round-button-circle {
	            width: $size;
	            height:0;
	            padding-bottom: 100%;
                border-radius: 50%;
                overflow:hidden;
                background: #808080; 
                color:#ffffff;
                text-align:center;
                padding-left:2px;
                font-weight:bold;
                cursor: pointer;
            }

            input {
                text-align:right;
            }
        }

        .grid-input {
            input {
                max-width:100% !important;
            }
        }
    }
}

.grid-dropdown {
    .button-item, .item {
        margin: 0 16px 0 6px;
    }

    .image, .sprite, .sprite-responsive {
        max-width: 100px;
    }
}