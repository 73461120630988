@import '../../../src/styles/colors.scss';

.group-control {
    .controls {
        display: flex;
        align-items: center;

        > .control {
            display: flex;
            align-items: center;
            margin-right: 10px;

            &:last-child {
                margin-right: 0;
                flex: 1;
            }

            .control-header {
                margin-bottom: 0;
                margin-right: 10px;

                .control-label {
                    min-width: 20px;
                    color: $color-eggplant;
                }
            }
        }
    }
}
