.anchor-number-not-found {
    background-color: $color-light-amberish-gray;
    border-style: solid;
    border-width: 1px;
    border-color: $color-cappuccino;
    cursor: pointer;
    display: flex;
    margin-top: 5px;

    .contact-hilti-text {
        margin: 0;
        padding: 0;
        border: none;
        background: none;
        color: $color-eggplant;
        min-width: 0;
        overflow: hidden;
        max-width: 100%;
        padding: 8px 4px 4px 0;
    }
}
