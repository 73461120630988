﻿@import '../bundles/main.scss';

@mixin sprite($sprite) {
    $sprite-image: nth($sprite, 9);
    $sprite-image-bare: str-slice($sprite-image, 1);
    $sprite-offset-x: nth($sprite, 3);
    $sprite-offset-y: nth($sprite, 4);

    background-image: url($sprite-image-bare);
    background-position: $sprite-offset-x $sprite-offset-y;
    width: nth($sprite, 5);
    min-width: nth($sprite, 5);
    height: nth($sprite, 6);
}

@mixin sprite-responsive($sprite) {
    $sprite-x: nth($sprite, 1);
    $sprite-y: nth($sprite, 2);
    $sprite-width: nth($sprite, 5);
    $sprite-height: nth($sprite, 6);
    $sprite-total-width: nth($sprite, 7);
    $sprite-total-height: nth($sprite, 8);
    $sprite-image: nth($sprite, 9);
    $sprite-image-bare: str-slice($sprite-image, 1);

    max-width: $sprite-width;
    width: $sprite-width;
    background-image: none;
    background-position: 0% 0%;
    height: auto;

    &::after {
        background-image: url($sprite-image-bare);
        background-position: ($sprite-x * 100 / ($sprite-total-width - $sprite-width)) * 1% ($sprite-y * 100 / ($sprite-total-height - $sprite-height)) * 1%;
        background-size: ($sprite-total-width * 100 / $sprite-width) * 1% auto;
        padding-bottom: ($sprite-height * 100 / $sprite-width) * 1%;
        width: ($sprite-total-width * 100 / $sprite-width) * 1%;
    }
}
