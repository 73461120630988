﻿@import '../../../src/styles/colors.scss';

.control-tooltip {
    text-align: left;

    .title {
        color: $color-white;
    }

    .content {
        white-space: pre-wrap;
    }
}

.control-tooltip-popup {
    margin: 0 0 4px 4px;
    padding: 0;
    border: none;
    background: transparent;
}
