﻿@import 'sprites.scss';
@import 'colors.scss';

.main-header {
    width: 100%;
    display: flex;
    flex: 0 0 48px;
    height: 48px;
    background: $color-white;
    color: $color-iron;
    font-family: hilti, Arial, sans-serif;
    font-size: 12px;
    border-bottom: 2px solid $color-medium-gray;
    z-index: 10;

    .header {
        .border {
            width: 2px;
            background: $color-medium-gray;
            margin: 6px 0;
            align-self: stretch;
        }

        .space {
            flex: 1;
        }

        &.header-left {
            display: flex;
            align-items: center;
            height: 100%;
            flex: 0 0 320px;

            .logo {
                margin: 0 8px 0 8px;
            }

            .application-name {
                margin: 0;
                font-weight: 700;
                text-transform: uppercase;

                .sub-application-name {
                    color: #c2c2c2;
                    font-weight: 700;
                }
            }

            .dropdown-menu-container {
                align-self: stretch;
                display: flex;
                align-items: stretch;

                .dropdown-menu-button {
                    margin: 0;

                    .dots {
                        @include sprite($sprite-dots);
                    }
                }
            }
        }

        &.header-center {
            display: flex;
            align-items: center;
            height: 100%;
            flex: 1;

            .home-button {
                margin: 0;
                background: none;
                border: none;
                align-self: stretch;
                padding: 0 12px;
            }

            .upgrade-to-premium-wrapper {
                height: 32px;
                display: flex;
                flex-direction: column;

                .upgrade-to-premium-button {
                    background-color: $color-cappuccino;
                    border: none;
                    padding: 0 16px;
                    margin: 0 10px;
                    color: $color-hilti;
                    font-size: 11px;
                    font-weight: 700;
                    text-transform: uppercase;
                    display: flex;

                    .upgrade-to-premium-text {
                        display: flex;
                        align-items: center;
                        height: 32px;
                        white-space: nowrap;
                    }
                }
            }
        }

        &.header-right {
            display: flex;
            align-items: center;
            height: 100%;
            flex: 0 0 272px;

            .border {
                display: flex;
                flex: 0 0 2px;
            }


            .user-name {
                margin-left: 16px;
            }

            .dropdown-menu-container {
                align-self: stretch;
                display: flex;
                align-items: stretch;

                .dropdown-menu-button {
                    margin: 0;
                    padding: 0;

                    .dots {
                        @include sprite($sprite-dots);
                    }
                }

                .dropdown-menu {
                    left: auto;
                    right: 0;
                }
            }
        }
    }
}
