@import 'LoadingFunctions.scss';

.loading {
    .bar{
        display: flex;
        flex-direction: row;
        position: relative;

        .fill {
            flex: 1;
            height: 100%;
            transform: skew(-30deg, 0deg);
            opacity: 0.1;
        }

        .space{
            flex: 0.6;
            height: 100%;
            transform: skew(-30deg, 0deg);
        }
    }

    @include setLoading();
}

@keyframes fade {
    0% {
        opacity: 0.1;
    }
    10% {
        opacity: 1;
    }
    50% {
        opacity: 1;
    }
    60% {
        opacity: 0.1;
    }
    100% {
        opacity: 0.1;
    }
}
