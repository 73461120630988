﻿.design-file-info-modal.default-modal {
    .modal-content .modal-body {
        font-size: 12px;
        padding: 16px;

        .main-container {
            display: flex;
            flex-direction: column;
            margin-bottom: 32px;

            &:last-child {
                margin-bottom: 0;
            }

            .title {
                margin: 0 0 16px 0;
                font-weight: 700;
                border-bottom: 1px solid $color-iron;
                color: $color-iron;
            }

            .main-content {
                flex: 1 0 auto;
                margin-bottom: 16px;
            }
        }

        .details {
            .details-title {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .main-content {
                display: flex;
                margin: 0 -16px;

                .data-column {
                    flex: 1;
                    min-width: 0;
                    padding: 0px 16px;

                    .control-row {
                        margin-bottom: 16px;
                        display: block;

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }
}
