﻿@import '../../../src/styles/control-helpers.scss';
@import '../../../src/styles/colors.scss';

.user-agreement-privacy-modal.default-modal {
    .modal-content .modal-body {
        padding: 0;
        height: 500px;
        justify-content: center;
        display: flex;
        align-items: center;

        .content {
            max-width: 80%;
            padding: 16px;
            display: flex;
            flex-direction: column;

            .content-row {
                display: flex;
                margin-bottom: 20px;

                .title {
                    color: $color-hilti;
                    font-size: 22px;
                }

                .sub-title {
                    color: $color-grape;
                    font-size: 16px;
                    font-weight: bold;
                }

                &.first {
                    flex-direction: column;
                    text-align: center;
                }

                &.second {
                    margin-left: 78px;
                }

                &.third {
                    margin-left: 30px;
                }

                .checkbox {
                    align-items: flex-start;
                    margin-bottom: 16px;
                    margin-top: 15px;

                    &.diagnostics {
                        margin-left: 20px;
                    }

                    &.notify {
                        margin-left: 13px;
                    }

                    .checkbox-label {
                        display: flex;
                        flex-direction: column;

                        .text {
                            max-width: 350px;
                        }

                        .sub-text {
                            max-width: 350px;
                            color: #929292;
                        }
                    }
                }
            }
        }
    }
}
