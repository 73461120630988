﻿@import '../../../src/styles/control-helpers.scss';
@import '../../../src/styles/colors.scss';

.user-agreement-modal.default-modal {
    .modal-content .modal-body {
        padding: 0;
        height: 200px;
        text-align: center;

        .content {
            padding: 16px;
            overflow-x: hidden;
            overflow-y: scroll;
            max-height: 500px;

            .message {
                margin: 0;
            }
        }

        .user-agreement-title {
            color: $color-hilti;
            font-size: 22px;
            margin: 16px 0 8px 0;
        }

        .user-agreement-sub-title {
            color: $color-iron;
            font-size: 16px;
            margin: 0;
        }

        .open-document,
        .open-document:hover,
        .open-document:visited,
        .open-document:active {
            text-decoration: none;
            font-size: 16px;
            color: $color-licorice;
            padding: 0;
            display: inline-flex;
            align-items: center;
            margin: 48px 0 0 0;
            margin-left: 32px;
            font-weight: 700;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .modal-content .modal-footer {
        justify-content: space-between;
        height: auto;
        align-items: flex-end;

        .checkboxes-agreement {
            display: flex;
            flex-direction: column;
            margin-top: 15px;
            flex: 1;

            .checkbox-with-link {
                display: flex;
                flex-direction: row;

                .checkbox {
                    align-items: flex-start;
                    margin-bottom: 16px;
                }

                .checkbox-link {
                    color: $color-hilti;
                    cursor: pointer;
                    margin-left: 5px;

                    &:hover {
                        text-decoration: underline;
                    }
                }

                .checkbox-after-link {
                    color: $color-eggplant;
                    margin-left: 5px;
                }
            }

            .checkbox {
                align-items: flex-start;
                margin-bottom: 8px;
            }
        }

        .buttonContainer {
            margin-left: auto;
        }

        .modal-button {
            margin: 0px 0px 15px 20px;
        }
    }
}
