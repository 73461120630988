﻿@import '../style.scss';

.license-comparison-modal.base-modal {
    .modal-full-screen,
    .modal-content {
        display: block;
        min-height: 100%;
        min-width: 100%;
        border: 0;
        margin: 0;
        padding: 0;
        background: $color-0xf3f0eb;
        box-shadow: none;
    }

    .modal-content {
        .section {
            display: flex;
            flex-direction: column;
        }

        .modal-body {
            padding: 0;

            .section {
                display: flex;
                flex-direction: column;
            }

            button {
                width: 220px;
                display: inline-block;
            }

            .modal-body-header {
                width: 100%;
                padding: 0 0 0 4%;
                float: left;
                background: $color-0xffffff;

                .trial-header-img {
                    float: right;
                }

                .header-text {
                    position: absolute;
                    width: 60%;
                    float: left;
                    padding: 20px 0;
                    display: flex;
                    flex-direction: column;

                    .body-header-title {
                        color: $color-0xd2051e;
                        font-size: 32px;
                        white-space: pre-line;
                    }

                    .body-header-sub-title {
                        color: $color-0x671a3d;
                        font-size: 24px;
                        font-weight: bold;
                        height: 70px;
                    }

                    .body-header-Text {
                        font-size: 16px;
                        font-weight: bold;
                        margin: 10px 0 0 0;
                    }

                    .space {
                        margin: 30px 0;
                    }

                    .small-space {
                        margin: 15px 0;
                    }

                    .start-trial {
                        display: flex;
                    }
                }
            }

            .trial-info {
                padding: 0 4%;
                margin: 30px 0;

                .trial-info-title {
                    color: $color-0xd2051e;
                    font-size: 20px;
                }

                .trial-info-text {
                    font-size: 18px;
                }

                .start-trial {
                    padding: 0 10%;
                    display: block;
                    width: 100%;

                    button {
                        display: block;
                        float: right;
                        width: unset;
                        padding: 14px;
                        font-size: 14px;
                    }

                    button#buy-now-button {
                        margin-left: 30px;
                    }
                }
            }
        }
    }
}
