@import '../bundles/main.scss';

@import 'sprites.scss';

@mixin create-sprites($sprites) {
    @each $sprite in $sprites {
        .sprite.#{nth($sprite, 10)} {
            @include sprite($sprite)
        }
    }
}

@mixin create-responsive-sprites($sprites) {
    @each $sprite in $sprites {
        .sprite-responsive.#{nth($sprite, 10)} {
            @include sprite-responsive($sprite)
        }
    }
}

.sprite {
    display: block;
}

.sprite-responsive {
    display: block;

    &::after {
        content: ' ';
        display: block;
        max-width: 100%;
        height: 0;
    }
}

// static sprites
@include create-sprites($spritesheet-main-sprites);
