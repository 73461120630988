﻿@import '../../App/Controls/Loading/LoadingFunctions.scss';
@import 'control-helpers.scss';
@import 'sprites.scss';

.general-error {
    background:#EEEEEE;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    height: 100%;
    position: absolute;

    .general-error-container {
        width: 480px;
        height: 368px;
        background-color: #ffffff;
        border: solid 1px #999999;
        display: flex;
        align-items: center;
        justify-content: center;
        position:relative;

        .general-error-header {
            position: absolute;
            top: 15px;
            left: 0;
        }

        .general-error-content {
            text-align: left;

            p {
                width: 391px;
            }

            .title-row-1 {
                font-family: hilti, Arial, sans-serif;
                font-size: 18px;
                font-weight: normal;
                color: $color-hilti;
                padding: 0;
                margin: 0;
            }

            .title-row-2 {
                font-family: hilti, Arial, sans-serif;
                font-size: 18px;
                font-weight: bold;
                color: $color-hilti;
                padding: 0;
                margin: 0;
            }

            .message-row {
                font-family: hilti, Arial, sans-serif;
                font-size: 12px;
                font-weight: normal;
                line-height: 1.33;
                color: #261d24;
                padding: 0;
                margin: 0;
                margin-top: 28px;

                p {
                    margin-top: 15px;
                }
            }

            .icon-row {
                display: inline-flex;

                .icon-row {
                    display: inline-flex;
                }
            }
        }
    }
}
