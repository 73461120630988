@import '../../App/Controls/Loading/LoadingFunctions.scss';
@import './colors.scss';

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
    display: block;
}

[ng\:cloak], [ng-cloak], .ng-cloak {
    display: none !important;
}

[ng-if].ng-leave, [data-ng-if].ng-leave {
    display: none;
}

[hidden] {
    display: none;
}

.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Chrome/Safari/Opera */
    -khtml-user-select: none; /* Konqueror */
    -moz-user-select: none; /* Firefox */
    user-select: none; /* non-prefixed version, currently not supported by any browser */
}

.nowrap {
    white-space: nowrap;
}

html {
    height: 100%;
}

body {
    display: flex;
    min-height: 100%;
    width: 100%;
    color: #111;
    font-family: hilti, Arial, sans-serif;
    font-size: 12px;
    background: white;
    margin: 0;
    padding: 0;
    min-width: 1200px;
    > .view {
        display: flex;
        width: 100%;
        min-height: 100vh;
    }

    > .application-loading {
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background: white;
        z-index: 100;
        flex-direction: column;

        &.application-loading-hide {
            animation: application-loading-hide 0.4s forwards ease-in-out;
        }

        .loading {
            @include setLoading($width: 192px, $height: 48px);

            .logo-small {
                width: 64px;
                height: 47px;
                // ~/Content/Images/loading-logo.png
                background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAAAvAQMAAABpBV4SAAAABlBMVEXYIyr///5lXSuHAAAAHUlEQVQY02OgK+D/x8BQ/4Fkxn8goCGDTIfREwAAwH5UmvPMwzcAAAAASUVORK5CYII=');
            }
        }

        > .trial-information {
            font-size: 25px;
            font-weight: 700;
            margin-bottom: 70px;

            > div {
                margin-bottom: 35px;
            }

            .button {
                color: $color-white;
                border-color: $color-eggplant;
                background: $color-iron;
            }

            .button-primary {
                font-weight: 700;
                border-radius: 2px;
                min-width: 96px;
                min-height: 32px;
                border: 1px solid;
                outline: none;
                display: block;
                padding: 6px 40px;
                margin: 0 auto;
            }

            .trial-remaining-days {
                color: $color-hilti;
            }
        }
    }
}

.loading-button {

    &.button-primary.show-loading .loading-container .loading {
        @include setLoading($width: 16px, $height: 4px, $color: $color-white);
    }

    &.show-loading {
        opacity: 1;
        position: relative;

        .text {
            visibility: hidden;
        }

        .loading-container {
            display: flex;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            justify-content: center;
            align-items: center;

            .loading {
                @include setLoading($width: 16px, $height: 4px, $color: $color-iron);
            }
        }
    }

    .loading-container {
        display: none;
    }
}

.title-strike {
    margin-bottom: 16px;
    font-weight: 700;
    color: $color-iron;
    position: relative;
    overflow: hidden;

    &:after {
        content: "";
        position: absolute;
        height: 1px;
        width: 100%;
        top: 50%;
        margin-left: 8px;
        background-color: $color-metal;
    }
}

.control-label {
    color: $color-iron;
    padding-bottom: 4px;
    text-overflow: ellipsis;
    margin-bottom: 0px;
    font-weight: 500;
}

// modal
.modal-grid-button {
    cursor: pointer;
    background-color: $color-white;
    color: $color-eggplant;

    &.disabled{
        opacity: 0.5;
    }

    &:not(.disabled){
        &:hover,
        &:active,
        &.selected {
            > .image-container,
            > .item-container {
                mix-blend-mode: multiply;
            }
        }

        &:hover {
            background-color: $color-light-gray;
        }

        &:active,
        &.selected {
            background-color: $color-light-yellowish-gray;
        }
    }
}

@keyframes application-loading-hide {
    0% {
        z-index: 100;
        opacity: 1;
    }

    99% {
        z-index: 100;
        opacity: 0;
    }

    100% {
        z-index: -100;
        opacity: 0;
    }
}
