@import '../../../src/styles/colors.scss';

@mixin setLoading($width: 32px, $height: 8px, $duration: 1s, $color: none) {
    .bar {
        width: $width;
        height: $height;

        $delay: (0.1 * $duration);

        .fill-1 {
            animation: fade $duration (0 * $delay) infinite linear;

            @if type-of($color) == color {
                background-color: $color;
                border: none;
            } @else {
                background-color: $color-white;
                border: 1px solid $color-silver;
            }
        }

        .fill-2 {
            animation: fade $duration (1 * $delay) infinite linear;

            @if type-of($color) == color {
                background-color: $color;
            } @else {
                background-color: $color-silver;
            }
        }

        .fill-3 {
            animation: fade $duration (2 * $delay) infinite linear;

            @if type-of($color) == color {
                background-color: $color;
            } @else {
                background-color: $color-cappuccino;
            }
        }

        .fill-4 {
            animation: fade $duration (3 * $delay) infinite linear;

            @if type-of($color) == color {
                background-color: $color;
            } @else {
                background-color: $color-iron;
            }
        }

        .fill-5 {
            animation: fade $duration (4 * $delay) infinite linear;

            @if type-of($color) == color {
                background-color: $color;
            } @else {
                background-color: $color-hilti;
            }
        }
    }
}
