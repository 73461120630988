﻿@import '../style.scss';
@import '../../../../src/styles/colors.scss';

.hilti-styled-control-button {
    .hilti-styled-control-container {
        .hilti-styled-button {
            max-width: 100%;

            &.hilti-styled-size-full {
                width: 100%;
            }
        }
    }
}

.hilti-styled-button {
    min-height: 45px;
    min-width: 96px;
    padding: 4px 10px;
    border-radius: 0;
    white-space: normal;
    margin: 0;
    outline: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-weight: 700;

    &[disabled] {
        cursor: not-allowed;
        opacity: $opacity-disabled;
    }

    .hilti-styled-image-container {
        .hilti-styled-image {
            margin: 0 auto;
        }
    }

    .hilti-styled-text {
        white-space: pre-line;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.hilti-styled-button-primary {
    background: $color-0xd2051e;
    border: 0;
    color: $color-0xffffff;
    transition: background-color ease 0.3s;

    &:hover:not([disabled]) {
        background: $color-0x671a3d;
    }

    &:active:not([disabled]) {
        transform: scale(0.97);
    }
}

.hilti-styled-button-secondary {
    background: none;
    border: 3px solid $color-0xd2051e;
    color: $color-0xd2051e;
    transition: border-color ease 0.3s;

    &:hover:not([disabled]) {
        border-color: $color-0x671a3d;
        color: $color-0x671a3d;
    }

    &:active:not([disabled]) {
        transform: scale(0.97);
    }
}

.hilti-styled-button-gray {
    background: $color-coal;
    border: 0;
    color: $color-white;
    transition: background-color ease 0.3s;

    &:hover:not([disabled]) {
        background: $color-iron;
    }

    &:active:not([disabled]) {
        transform: scale(0.97);
    }
}
