﻿@import '../../../src/styles/colors.scss';

.archive-modal.default-modal {
    .modal-content .modal-body {
        padding: 0;
        margin: 0;

        .table-archive {
            width: 100%;

            tr {
                border-bottom: 1px solid $color-0xddd;
            }

            .table-archive-row {
                height: 34px;

                td {
                    text-align: left;
                }

                td:nth-child(1) {
                    width: 25%;
                    padding-left: 16px;
                    padding-right: 16px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    min-width: 0;
                    max-width: 0;
                }

                td:nth-child(6) {
                    width: 15%;
                    height: 32px;
                    vertical-align: middle;

                    .action-buttons {
                        display: inline-table;
                        width: 100%;
                        height: 32px;
                        vertical-align: middle;
                        padding-right: 2px;

                        button {
                            background: none;
                            border: 0;
                            display: inline-block;
                            float: right;
                        }

                        .button-delete {
                            margin: 0 2px 0 1px;
                        }

                        .button-restore {
                            margin-right: -10px;
                        }
                    }
                }
            }

            .table-archive-row-header {
                color: $color-iron;
                background: $color-metal;
                height: 39px;
                margin-left: 20px;
                margin-right: 20px;

                th {
                    text-align: left;
                }

                th:nth-child(1) {
                    width: 25%;
                    padding-left: 16px;
                }

                th:nth-child(2) {
                    width: 15%;
                }

                th:nth-child(3) {
                    width: 15%;
                }

                th:nth-child(4) {
                    width: 15%;
                }

                th:nth-child(5) {
                    width: 15%;
                }

                th:nth-child(6) {
                    width: 15%;
                    padding-right: 16px;
                    text-align: right;
                }
            }
        }
    }
}
