@import '../../../src/styles/control-helpers.scss';

.radio-button-control {
    overflow-x: hidden;

    .control-container .radio .text {
        overflow-x: hidden;
        text-overflow: ellipsis;
    }
}
