﻿@import '../style.scss';

.trial-banner-modal.base-modal {
    .modal-full-screen,
    .modal-content {
        width: 100% !important;
        border: 0;
        margin: 24px 0 0 0;
        padding: 0;
    }

    .modal-content {
        .modal-body {
            padding: 0;
            background: $color-0xebe6de;
            height: 100%;

            .modal-body-header {
                background: $color-0xd7ceba;
                display: flex;

                .header-text {
                    flex: 1;
                    padding: 20px 40px 20px 80px;
                    font-size: 24px;
                    font-weight: bold;
                    color: $color-0xd2051e;

                    .red {
                        color: $color-0xd2051e;
                    }

                    .white {
                        color: $color-0xffffff;
                    }
                }

                &.grape {
                    background: $color-0x671a3d;

                    .close {
                        color: $color-0xffffff;
                    }
                }

                .close {
                    font-size: 36px;
                    color: $color-0xd2051e;
                    opacity: 1;
                }
            }

            .license-info {
                display: flex;
                flex-direction: column;
                padding: 30px 70px 40px 80px;

                .section-title {
                    font-size: 18px;
                    font-weight: bold;
                    padding-bottom: 20px;
                    position: relative;

                    .red {
                        color: $color-0xd2051e;
                    }

                    .days-left {
                        background: $color-0xd7ceba;
                        color: $color-0xd2051e;
                        padding: 10px;
                    }
                }

                .info {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;

                    .info-text {
                        width: 50%;
                        font-size: 16px;

                        .body-header-title {
                            margin-right: 70px;
                            margin-bottom: unset;
                        }

                        .click-here {
                            background: none;
                            border: none;
                            font-weight: bold;
                            color: $color-0xd2051e;
                        }

                        button {
                            padding: unset;
                        }
                    }

                    .info-button {
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        button {
                            display: block;
                            float: right;
                            font-size: 16px;
                            padding: 12px 30px;
                        }

                        button > div.hilti-styled-text {
                            overflow: unset;
                            text-overflow: unset;
                            white-space: nowrap;
                        }

                        button#purchase-button {
                            padding: 15px 30px;
                            margin-left: 50px;
                        }
                    }
                }

                .section-footer {
                    position: relative;
                    display: block;
                    float: left;
                    width: 100%;
                    clear: both;
                    padding-top: 60px;
                    font-weight: 700;

                    .section-logout {
                        font-size: 18px;
                        padding: 4px 4px 4px 0;
                        cursor: pointer;
                        border-bottom-style: solid;
                        border-bottom-width: 2px;
                        border-bottom-color: black;
                        // animations
                        -webkit-transition: color 0.3s, border-bottom-color 0.3s;
                        transition: color 0.3s, border-bottom-color 0.3s;
                    }

                    .section-logout:hover {
                        color: $color-0xd2051e;
                        border-bottom-color: $color-0xd2051e;
                    }
                }
            }
        }
    }
}
