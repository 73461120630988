﻿@import '../../../src/styles/colors.scss';

.create-new-bom-modal {
    .form.ng-submitted {
        .dropdown.ng-invalid .button, .new-project.ng-invalid {
            border-color: #df001b;
        }
    }

    .modal-content .modal-body {
        font-size: 12px;
        padding: 16px;

        .main-container {
            display: flex;
            flex-direction: column;
            margin-bottom: 32px;

            &:last-child {
                margin-bottom: 0;
            }

            .title {
                margin: 0 0 16px 0;
                font-weight: 700;
                border-bottom: 1px solid $color-iron;
                color: $color-iron;
            }

            .main-content {
                flex: 1 0 auto;
            }
        }

        .general {
            .main-content {
                display: flex;

                .name-text-box {
                    flex: 1;
                    margin-right: 16px;
                }

                .remarks-text-box {
                    flex: 2;
                }

                .input {
                    max-width: none;
                }
            }
        }
    }

    .modal-table {
        .table-header {
            display: flex;
            border-bottom: 1px solid $color-light-gambogeish-gray;
            border-top: 1px solid $color-light-gambogeish-gray;
            background-color: $color-light-gambogeish-gray;
            color: $color-iron;
            font-weight: 700;

            > div {
                padding: 8px 16px;
                border-right: 1px solid $color-light-gambogeish-gray;
                flex: 1;

                &:last-child {
                    border-right: none;
                }
            }
        }

        .table-body {
            .table-row {
                display: flex;
                border-bottom: 1px solid $color-light-gambogeish-gray;

                > div {
                    padding: 16px;
                    border-right: 1px solid $color-light-gambogeish-gray;
                    flex: 1;
                    display: flex;
                    align-items: center;

                    &:last-child {
                        border-right: none;
                        padding: 8px 16px;
                    }
                }

                &:last-child {
                    border-bottom: none;
                }
            }
        }
    }
}
