@import 'fonts.scss';
@import 'main.scss';
@import 'colors.scss';

/*
    BOOTSTRAP LOOK
*/

.nav, .pagination, .carousel, .panel-title a {
    cursor: pointer;
}

.btn {
    font-size: $font-size-main;
}

// btn-default
.btn-default {
    color: #444;
    background-color: white;
    border-color: #a6a6a6;
    outline: none;
}

.btn-grid {
    cursor: pointer;
}

.btn-dashed:after {
    content:"\a0";
    display:block;
    padding:2px 0;
    line-height:1px;
    border-top:1px dashed #000; 
}

.btn-default:focus {
    color: #444;
    background-color: white;
    border-color: #a6a6a6;
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.3);
    outline: none;
}

.btn-default:hover {
    color: #444;
    background-color: white;
    border-color: #a6a6a6;
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.3);
}

.btn-default:active,
.btn-default.active {
    color: #444;
    background-color: #d8d8d8;
    border-color: #a6a6a6;
    box-shadow: none;
    outline: none;
}

.btn-default:active:hover,
.btn-default.active:hover,
.btn-default:active:focus,
.btn-default.active:focus,
.btn-default:active.focus,
.btn-default.active.focus {
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.3);
    outline: none;
}

[disabled].btn-default:focus,
[disabled].btn-default:hover,
[disabled].btn-default:active:hover,
[disabled].btn-default:active:focus,
[disabled].btn-default:active.focus,
[disabled].btn-default:active {
    background-color: #fff;
    border-color: #ccc;
    box-shadow: none;
    color: #444;
}

[disabled].btn-default.active:hover,
[disabled].btn-default.active:focus,
[disabled].btn-default.active.focus,
[disabled].btn-default.active {
    color: #444;
    background-color: #d8d8d8;
    border-color: #a6a6a6;
}

// btn-danger
.btn-danger {
    color: #fff;
    background-color: #ea0707;
    border-color: #d43f3a;
}

// form-control
.form-control {
    transition: none;
    height: 32px;
    font-size: $font-size-main;
}

.form-control:focus {
    border-color: #a6a6a6;
    outline: 0;
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.3);
}

// tooltip
.tooltip {
    font-family: $font-hilti;

    .tooltip-inner {
        word-wrap: break-word;
        border-radius: 0;
        background: $color-licorice;
        padding: 4px 8px;
        color: $color-metal;
    }
}

// label
label {
    font-weight: 500;
    margin-bottom: 0;
}

// radio, checkbox
.radio, .checkbox {
    margin: 0;

    label {
        margin: 0;
        padding: 0;
        min-height: 0;
        cursor: default;
    }
}

.radio input[type="radio"], .radio-inline input[type="radio"], .checkbox input[type="checkbox"], .checkbox-inline input[type="checkbox"] {
    position: static;
    margin: 0;
}