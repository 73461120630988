﻿@import 'colors.scss';
@import 'control-helpers.scss';

.checkbox {
    display: inline-flex;
    align-items: center;

    &.disabled .checkbox-label {
        @include controlStyle($style-checkbox-text-disabled);
    }

    .checkbox-button {
        flex-shrink: 0;
    }

    .checkbox-label {
        @include controlStyle($style-checkbox-text);
        padding-left: 8px;
        cursor: default;
    }
}

.checkbox-button {
    width: 16px;
    height: 16px;
    position: relative;
    outline: none;

    &:focus span {
        border-color: $color-grape;
    }

    input {
        display: none;

        &:checked {
            & + span:after {
                visibility: visible;
            }

            &[disabled] + span:after {
                visibility: visible;
                opacity: 0.5;
            }
        }

        &[disabled] + span {
            cursor: not-allowed;
            opacity: 0.5;
        }
    }

    span {
        width: 16px;
        height: 16px;
        left: 0;
        top: 0;
        position: absolute;
        border: 1px solid $color-iron;
        background: $color-white;

        &:after {
            @include sprite($sprite-checkbox);
            content: '';
            position: absolute;
            visibility: hidden;
        }
    }
}
