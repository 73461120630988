﻿@import '../../../src/styles/colors.scss';

.connections-grid {
    display: flex;
    flex-direction: column;

    .connections-grid-header-cell, .connections-grid-cell {
        overflow: hidden;
        text-overflow: ellipsis;

        &.connections-grid-column-image {
            flex: 0 0 auto;
            width: 120px;
        }

        &.connections-grid-column-name {
            flex: 1;
            min-width: 0;
        }

        &.connections-grid-column-count {
            flex: 0 0 auto;
            width: 128px;
        }

        &.connections-grid-column-total {
            flex: 0 0 auto;
            width: 128px;
        }

        &.connections-grid-column-selection {
            flex: 0 0 auto;
            width: 64px;
        }
    }

    .connections-grid-header-container {
        background: $color-light-gambogeish-gray;

        .connections-grid-header {
            flex: 0 0 auto;
            display: flex;
            color: $color-iron;
            font-weight: 700;

            .connections-grid-header-cell {
                white-space: nowrap;
                padding: 8px 12px;
                border-right: 1px solid $color-light-gambogeish-gray;

                &.connections-grid-header-selection {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-right: none;

                    .selection-checkbox {
                        margin: 0;
                        padding: 0;
                    }
                }
            }
        }
    }

    .connections-grid-rows {
        flex: 1;
        overflow: auto;

        .connections-grid-row-content {
            display: flex;
            border-bottom: 1px solid $color-light-gambogeish-gray;

            &.invalid {
                .connections-grid-cell {
                    .design-image, .count-container, .total-mechanical, .total-chemical, .invalid-volume-container, .selection-container, .number-of-anchors {
                        opacity: 0.3;
                    }
                }

                .connections-grid-cell-name {
                    .cell-content.bottom {
                        margin-bottom: 8px;
                    }
                }
            }

            .connections-grid-cell {
                padding: 8px 12px;
                border-right: 1px solid $color-light-gambogeish-gray;

                &.connections-grid-cell-image {
                }

                &.connections-grid-cell-name {
                    display: flex;
                    padding-right: 0;

                    .name-container {
                        flex: 1;
                        min-width: 0;

                        .design-name-container {
                            display: flex;

                            .design-name {
                                margin: 0;
                                padding: 0;
                                border: none;
                                background: none;
                                color: $color-0x887f6e;
                                min-width: 0;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                max-width: 100%;

                                &:hover {
                                    text-decoration: underline;
                                }
                            }

                            .sprite-warning {
                                margin-top: -8px;
                            }
                        }

                        .anchor-name {
                            color: $color-eggplant;
                            font-weight: 700;
                            min-width: 0;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }

                        .anchor-description {
                            min-width: 0;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }

                        .anchor-description-container {
                            display: flex;
                            white-space: nowrap;

                            .anchor-number-not-found {
                                background-color: $color-light-amberish-gray;
                                border-style: solid;
                                border-width: 1px;
                                border-color: $color-cappuccino;
                                cursor: pointer;
                                padding-right: 4px;
                                display: flex;
                                margin-top: 5px;
                            }

                            .anchor-description {
                                color: $color-eggplant;
                            }

                            .anchor-edit {
                                padding: 0 16px;
                                border: none;
                                background: none;
                                flex: 0 0 auto;
                                margin: 0 2px;
                                color: $color-moderate-cornflower-blue;
                                font-weight: bold;

                                &:hover {
                                    background: $color-light-gambogeish-gray;
                                }

                                &.selected {
                                    background: $color-amberish-gray;
                                }
                            }
                        }

                        .design-container {
                            display: flex;
                            margin-right: 12px;
                            margin-top: 5px;

                            &.info {
                                background-color: $color-light-amberish-gray;
                                border: 1px solid $color-cappuccino;
                            }

                            &.invalid {
                                background-color: $color-yellow;
                                border: 1px solid $color-0xefb24e;
                            }

                            .design-invalid-description {
                                margin: 0;
                                padding: 0;
                                border: none;
                                background: none;
                                color: $color-eggplant;
                                min-width: 0;
                                overflow: hidden;
                                max-width: 100%;
                                padding: 8px 4px 4px 0;
                            }

                            .sprite-warning {
                            }
                        }
                    }

                    .number-of-anchors-container {
                        min-width: 0;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        flex: 0 0 auto;
                        width: 75px;

                        .number-of-anchors {
                            text-align: center;
                            color: $color-eggplant;
                            font-size: 18px;
                            min-width: 0;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            padding: 0 8px;
                        }

                        .line-container {
                            border-left: 1px solid $color-0xddd;
                        }
                    }
                }

                &.connections-grid-cell-count {
                }

                &.connections-grid-cell-total {
                    .cell-content.bottom {
                        flex-direction: row;
                        justify-content: flex-start;
                        align-items: center;

                        .invalid-volume-container {
                            margin-right: -12px;
                        }
                    }

                    .total-value {
                        color: $color-eggplant;
                        font-size: 18px;
                        font-weight: 700;
                        min-width: 0;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        min-height: 34px;
                    }
                }

                &.connections-grid-cell-selection {
                    border-right: none;

                    .middle {
                        align-items: center;

                        .selection-checkbox {
                            margin: 0;
                            padding: 0;
                        }
                    }
                }

                .cell-content {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;

                    &.top {
                        height: 16px;
                        margin-bottom: 8px;
                    }

                    &.middle {
                        margin-bottom: 16px;
                    }

                    &.bottom {
                        margin-bottom: 16px;
                    }
                }
            }
        }

        .connections-grid-row-loading {
            background: $color-light-amberish-gray;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $color-eggplant;
        }
    }
}
