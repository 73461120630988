﻿$color-hilti: #d2051e;
$color-chilli: #a80015;
$color-grape: #671c3e;
$color-cappuccino: #d7cebe;
$color-licorice: #111;
$color-eggplant: #261d24;
$color-gray: #999;
$color-coal: #413c41;
$color-iron: #524f53;
$color-metal: #ccc;
$color-silver: #fafafa; // CR: did this change on zeplin?
$color-white: #fff;
$color-yellow: #ffaf00;
$color-black: #000000;

// colors not defined on zeplin
$color-0xddd: #ddd;
$color-0x887f6e: #887f6e;
$color-0xe9e9e9: #e9e9e9;
$color-0xb2aba5: #b2aba5;
$color-0xeeb24e: #EEB24E;
$color-0x4a90e2: #4a90e2;
$color-0x474547: #474547;
$color-0xf6f1e8: #f6f1e8;
$color-0xeee: #eeeeee;
$color-0x524f53: #524f53;
$color-0xefb24e: #efb24e;

// Using http://www.perbang.dk/ for color names
$color-dark-blue-violetish-gray: #504e53;
$color-light-gray: #f3f2f0;
$color-light-gambogeish-gray: #e7e5e2;
$color-gambogeish-gray: #a0998b;
$color-medium-gray: #bab9ba;
$color-light-yellowish-gray: #d8d8d3;
$color-amberish-gray: #cfccc5;
$color-light-tangeloish-gray: #e4e1df;
$color-moderate-cornflower-blue: #5b96b8;
$color-light-amberish-gray: #f3f1ec;
$color-light-malachite-greenish-gray: #d8f4df;
$color-dark-malachite-green: #117a26;
