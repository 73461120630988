@import '../../../src/styles/control-helpers.scss';

.text-box {
    .text-box-container {
        flex: 1 0 auto;
        display: flex;

        .input-container {
            position: relative;
            flex: 1;

            .input {
                width: 100%;

                &.postfix {
                    position: absolute;
                    background: white;
                    box-shadow: none;
                    border-color: transparent;

                    &[disabled] {
                        background: white;
                        box-shadow: none;
                        border-color: transparent;
                    }
                }

                &.data {
                    position: relative;
                }
            }
        }
    }

    .custom-error {
        color: red;
    }
}
