@import '../../App/Controls/Loading/LoadingFunctions.scss';
@import 'control-helpers.scss';
@import 'sprites.scss';

.project-and-design-view {
    display: flex;
    flex-direction: column;
    flex: 1;
    font-size: 12px;

    .content {
        flex: 1 1 auto;
        display: flex;
        align-items: stretch;
        $width-right-navigation: 272px;
        $width-left-navigation: 48px;
        height: 100%;

        .left-navigation {
            flex: 0 0 auto;
            width: $width-left-navigation;
            min-width: 0;

            &-menu {
                width: 100%;
                display: flex;
                flex-grow: 1;
                height: 100%;

                .tab-menu {
                    height: 100%;
                }
            }
        }

        .content-control {
            flex: 1 0 auto;
            display: flex;
            align-items: stretch;

            .content-control-body {
                flex: 1 0 auto;
                display: flex;
                align-items: stretch;
                width: 0;

                .navigation {
                    display: flex;
                    flex-direction: column;
                    flex: 0 0 auto;
                    width: 272px;
                    min-width: 0;
                    min-height: 100%;

                    .navigation-container {
                        .hidden {
                            display: none;
                        }
                    }

                    .designs-container {
                        flex: 0 0 auto;

                        .container-content {
                            display: flex;
                            flex-direction: column;
                            padding: 0;

                            .design-view-button {
                                padding: 0;
                                background: none;
                                border: none;
                                text-align: left;
                                cursor: pointer;

                                &:hover {
                                    background: $color-light-gray;
                                }

                                &.selected .wrapper {
                                    background: $color-light-yellowish-gray;

                                    .selector {
                                        background: $color-light-yellowish-gray;
                                    }

                                    .text {
                                        color: $color-iron;
                                    }

                                    .project-options {
                                        visibility: visible;

                                        .design-loading {
                                            display: flex;
                                            align-items: center;
                                            flex: 0 0 32px;
                                            justify-content: center;

                                            .loading {
                                                @include setLoading($width: 16px, $height: 4px, $color: $color-iron);
                                            }
                                        }
                                    }
                                }

                                .wrapper {
                                    display: flex;
                                    align-items: center;
                                    padding-left: 8px;

                                    .selector {
                                        width: 12px;
                                        align-self: stretch;
                                        flex: 0 0 auto;
                                    }

                                    .sprite {
                                        margin: 0px 0px 0px 0;

                                        &.files {
                                            @include sprite($sprite-files);
                                        }

                                        &.draft {
                                            @include sprite($sprite-draft);
                                        }
                                    }

                                    .text {
                                        margin: 8px 0;
                                        color: $color-iron;
                                    }

                                    .space {
                                        flex: 1;
                                    }

                                    .project-options {
                                        display: flex;
                                        visibility: hidden;
                                        align-self: stretch;
                                        align-items: stretch;
                                        flex: 0 0 33px;
                                        border-left: 1px solid $color-silver;

                                        .project-options-button {
                                            margin: 0;
                                            width: 100%;
                                            border: none;
                                            background: none;
                                            padding: 0;

                                            &[disabled] {
                                                opacity: 0.25;
                                                cursor: not-allowed;
                                            }

                                            .dots {
                                                @include sprite($sprite-dots);
                                                margin: 0 auto;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .projects-container {
                        flex: 1;
                        display: flex;
                        flex-direction: column;

                        .container-content {
                            flex: 1;
                            display: flex;
                            flex-direction: column;
                            padding: 0;

                            .no-projects-or-designs {
                                margin: 16px;
                                color: $color-iron;

                                .title {
                                    color: $color-black;
                                    font-weight: 700;
                                    margin: 0 0 4px 0;
                                }

                                .message {
                                    margin: 0 0 16px 0;
                                }
                            }

                            .new-project {
                                margin: 16px;
                                position: relative;
                                flex: 0 0 auto;

                                &.edit-project {
                                    .new-project-button {
                                        visibility: hidden;
                                    }

                                    .edit-project-input {
                                        display: block;
                                    }

                                    .edit-project-button-container {
                                        display: flex;
                                    }
                                }

                                .new-project-button {
                                    width: 100%;

                                    &.disabled {
                                        cursor: not-allowed;
                                        opacity: 0.5;
                                    }
                                }

                                .edit-project-input {
                                    display: none;
                                    position: absolute;
                                    top: 0;
                                    bottom: 0;
                                    left: 0;
                                    right: 0;
                                    width: 160px;
                                    height: 100%;
                                }

                                .edit-project-button-container {
                                    display: none;
                                    position: absolute;
                                    top: 0;
                                    bottom: 0;
                                    right: 0;
                                    width: 64px;

                                    .project-and-design-navigation-button.button {
                                        min-width: 0px;
                                        width: 32px;
                                        padding: 0;

                                        .sprite {
                                            margin: 0 auto;
                                        }
                                    }

                                    &.show-loading {
                                        .edit-project-cancel-button {
                                            display: none;
                                        }

                                        .edit-project-ok-button {
                                            display: none;
                                        }

                                        .loading-container {
                                            display: flex;
                                        }
                                    }

                                    .edit-project-cancel-button {
                                        &.button {
                                            border-top-left-radius: 0px;
                                            border-bottom-left-radius: 0px;

                                            &:focus {
                                                z-index: 2;
                                            }

                                            &:hover {
                                                z-index: 1;
                                            }
                                        }
                                    }

                                    .edit-project-ok-button {
                                        &.button {
                                            margin-right: -1px;
                                            border-top-right-radius: 0px;
                                            border-bottom-right-radius: 0px;

                                            &:focus {
                                                z-index: 2;
                                            }

                                            &:hover {
                                                z-index: 1;
                                            }
                                        }
                                    }

                                    .loading-container {
                                        display: none;
                                        position: absolute;
                                        top: 0;
                                        bottom: 0;
                                        left: 0;
                                        right: 0;
                                        justify-content: center;
                                        align-items: center;

                                        .loading {
                                            @include setLoading($width: 16px, $height: 4px, $color: $color-iron);
                                        }
                                    }
                                }
                            }

                            .search-project {
                                margin: 0px 16px 16px 16px;
                                position: relative;

                                .search-container {
                                    position: relative;
                                    align-self: stretch;
                                    display: flex;
                                    flex: 1;

                                    .search-image-container {
                                        position: absolute;
                                        top: 0;
                                        bottom: 0;
                                        left: 0;
                                        right: 0;
                                        display: flex;
                                        align-items: center;
                                        background-color: white;
                                    }

                                    .search-project-input {
                                        position: relative;
                                        background: none;
                                        width: 100%;
                                        padding-left: 32px;
                                        padding-right: 30px;
                                        border: 1px solid $color-medium-gray;
                                    }

                                    .clear-search-button {
                                        position: absolute;
                                        top: 6px;
                                        right: 6px;
                                        border: none;
                                        background: none;
                                        margin: 0;
                                        padding: 0;
                                        opacity: 0.5;

                                        &, &:hover, &:active, &:focus {
                                            outline: none;
                                        }

                                        &:hover, &:active, &:focus {
                                            opacity: 1;
                                        }

                                        &[disabled] {
                                            opacity: 0.5;
                                            cursor: not-allowed;
                                        }
                                    }
                                }
                            }

                            .projects {
                                display: flex;
                                flex-direction: column;
                                position: relative;
                                flex: 1;

                                .projects-scroll {
                                    border-top: 1px solid $color-0xddd;
                                    position: absolute;
                                    top: 0px;
                                    bottom: 0px;
                                    right: 0px;
                                    left: 0px;
                                    overflow-y: auto;
                                    overflow-x: hidden;
                                    display: flex;
                                    flex-direction: column;

                                    .project-container {
                                        flex: 0 0 auto;

                                        .edit-project {
                                            margin: 4px 16px 0 32px;
                                            height: 32px;

                                            .edit-project-input {
                                                width: 144px;
                                            }
                                        }

                                        .project {
                                            display: flex;
                                            align-items: center;
                                            cursor: pointer;
                                            height: 36px;

                                            &.selected {
                                                background: $color-light-yellowish-gray;

                                                .selector {
                                                    background: $color-light-yellowish-gray;
                                                }

                                                .folder {
                                                    @include sprite($sprite-folder);
                                                }

                                                .shared-folder {
                                                    @include sprite($sprite-shared-folder);
                                                }


                                                .project-options {
                                                    visibility: visible;

                                                    .design-loading {
                                                        display: flex;
                                                        align-items: center;
                                                        flex: 0 0 32px;
                                                        justify-content: center;

                                                        .loading {
                                                            @include setLoading($width: 16px, $height: 4px, $color: $color-iron);
                                                        }
                                                    }
                                                }
                                            }

                                            &.disabled {
                                                cursor: not-allowed;
                                                opacity: 0.35;
                                            }

                                            &.sub-project .selector {
                                                margin-right: 8px;
                                                width: 16px;
                                            }

                                            .selector {
                                                width: 12px;
                                                align-self: stretch;
                                                flex: 0 0 auto;
                                            }

                                            .folder {
                                                @include sprite($sprite-folder);
                                            }

                                            .shared-folder {
                                                @include sprite($sprite-shared-folder);
                                            }


                                            .text {
                                                max-height: 2.85714286em;
                                                overflow: hidden;
                                                text-overflow: ellipsis;
                                                padding: 0 0 0 8px;
                                                min-width: 0;
                                                color: $color-iron;
                                            }

                                            .counter {
                                                flex: 0 0 auto;
                                                padding: 0 8px 0 3px;
                                            }

                                            .space {
                                                flex: 1;
                                            }

                                            .project-options {
                                                display: flex;
                                                visibility: hidden;
                                                align-self: stretch;
                                                align-items: stretch;
                                                flex: 0 0 33px;
                                                border-left: 1px solid $color-silver;

                                                .project-options-button {
                                                    margin: 0;
                                                    width: 100%;
                                                    border: none;
                                                    background: none;
                                                    padding: 0;

                                                    &[disabled] {
                                                        opacity: 0.25;
                                                        cursor: not-allowed;
                                                    }

                                                    .dots {
                                                        @include sprite($sprite-dots);
                                                        margin: 0 auto;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            .project-pagination {
                                padding: 8px 16px;
                                margin: 0;
                                border-top: 1px solid $color-0xddd;
                                display: flex;

                                .hide-pagination {
                                    visibility: hidden;
                                }

                                .button {
                                    width: 48px;
                                    height: 32px;

                                    .sprite {
                                        margin: 0 auto;
                                    }

                                    &:hover {
                                        z-index: 1;
                                    }

                                    &:focus {
                                        z-index: 2;
                                    }
                                }

                                .project-pagination-down {
                                    border-top-right-radius: 0px;
                                    border-bottom-right-radius: 0px;

                                    .sprite {
                                        @include sprite($sprite-arrow-down-medium);
                                    }

                                    &[disabled] .sprite {
                                        @include sprite($sprite-arrow-down-gray);
                                    }
                                }

                                .project-pagination-up {
                                    border-top-left-radius: 0px;
                                    border-bottom-left-radius: 0px;
                                    margin-left: -1px;

                                    .sprite {
                                        @include sprite($sprite-arrow-up-medium);
                                    }

                                    &[disabled] .sprite {
                                        @include sprite($sprite-arrow-up-gray);
                                    }
                                }

                                .button-archive {
                                    width: 100%;
                                }
                            }

                            .archive-container {
                                margin: 16px 0;

                                .border {
                                    margin: 0 16px;
                                    border-top: 1px solid $color-white;
                                }

                                .archive {
                                    display: flex;
                                    align-items: center;
                                    color: $color-cappuccino;
                                    padding: 8px 16px 8px 24px;
                                    margin: 16px 0;

                                    .sprite {
                                        margin-right: 16px;
                                    }
                                }
                            }
                        }
                    }
                }

                .main {
                    flex: 1;
                    border: 1px solid $color-medium-gray;
                    border-top: 0;
                    border-bottom: 0;
                    min-width: 0;
                    display: flex;

                    .main-container {
                        flex: 1;
                        display: flex;
                        flex-direction: column;

                        .buttons-container {
                            height: 60px;
                            display: flex;
                            align-items: center;
                            padding: 14px 16px;
                            background: $color-light-gambogeish-gray;
                            border-bottom: 1px solid $color-white;
                            z-index: 1;

                            .working-condition-container {
                                display: inline-block;

                                .working-condition.dropdown {
                                    display: flex;

                                    .control-label {
                                        white-space: nowrap;
                                        color: $color-eggplant;
                                        padding: 0;
                                    }

                                    .control-container {
                                        margin-left: 16px;
                                        min-width: 112px;
                                        width: auto;
                                    }
                                }
                            }

                            .space {
                                flex: 1;
                            }

                            .import-design {
                                position: relative;
                                display: inline-block;
                                margin-left: 16px;

                                .import-design-button {
                                    &:hover:enabled {
                                        border-color: $color-medium-gray;
                                    }

                                    &.show-loading {
                                        opacity: 1;
                                        position: relative;

                                        .text {
                                            visibility: hidden;
                                        }

                                        .loading-container {
                                            display: flex;
                                            position: absolute;
                                            top: 0;
                                            bottom: 0;
                                            left: 0;
                                            right: 0;
                                            justify-content: center;
                                            align-items: center;

                                            .loading {
                                                @include setLoading($width: 16px, $height: 4px, $color: $color-iron);
                                            }
                                        }
                                    }

                                    .loading-container {
                                        display: none;
                                    }
                                }

                                .import-design-input {
                                    display: none;
                                }
                            }

                            .add-new-anchor-button {
                                display: inline-block;
                                margin-left: 16px;
                            }
                        }

                        .connections-container {
                            position: relative;
                            width: 100%;
                            flex: 1;

                            .connections-grid {
                                position: absolute;
                                left: 0;
                                right: 0;
                                top: 0;
                                bottom: 0;
                            }
                        }
                    }

                    .main-container-right {
                        flex: 0 0 auto;
                        width: 272px;
                        border-left: 1px solid $color-medium-gray;

                        .add-new-bom-button {
                            font-size: 14px;
                            height: 60px;
                            width: 100%;
                            border: none;
                            border-radius: 0;
                            flex: 0 0 auto;
                            padding: 0;

                            &[disabled] .sprite {
                                opacity: 0.5;
                            }

                            .add-new-bom-button-content {
                                display: flex;
                                align-items: center;
                                justify-content: center;

                                .text {
                                    margin-left: 36px;
                                }

                                .sprite-long-arrow-right-white {
                                    @include sprite($sprite-long-arrow-right-white);
                                }
                            }
                        }

                        .boxes {
                            overflow-y: auto;

                            .connections-grid-summary.box-section {
                                .box-section-header {
                                    border-top: none;
                                }

                                .box-section-content {
                                    padding-right: 0;
                                    overflow-x: hidden;
                                }

                                .connections-grid-summary-row {
                                    margin: 16px 0;
                                    color: $color-eggplant;
                                    display: flex;

                                    &:first-child {
                                        margin-top: 0;
                                    }

                                    &:last-child {
                                        margin-bottom: 0;
                                    }

                                    .left {
                                        flex: 1;
                                        min-width: 0;

                                        .anchor-number-not-found {
                                            width: 65%;
                                        }

                                        .name {
                                            font-weight: 700;
                                        }
                                    }

                                    .right {
                                        flex: 0 0 auto;
                                        width: 80px;
                                        border-left: 1px solid $color-0xddd;

                                        .total {
                                            font-weight: 700;
                                            margin-left: 8px;
                                            white-space: nowrap;
                                            text-overflow: ellipsis;
                                            overflow: hidden;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
