@import '../../../src/styles/colors.scss';

.alert-modal.default-modal {
    .modal-content{
        .modal-header {
            background-color: $color-hilti;

            .modal-title {
                color: $color-white;
                text-transform: uppercase;
            }

            .close{
                color: $color-white;

                &:not([disabled]):hover {
                    color: $color-silver;
                }
            }
        }

        .modal-body {
            white-space: pre-line;
            overflow: hidden;
            word-wrap: break-word;
        }
    }
}
