@import '../Loading/LoadingFunctions.scss';
@import '../../../src/styles/fonts.scss';
@import '../../../src/styles/colors.scss';

.default-modal {
    .modal-content {
        border-radius: 0;
        box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.5);

        .modal-header {
            height: 48px;
            display: flex;
            align-items: center;
            background: $color-white;
            padding: 0 16px;
            border-bottom: 1px solid $color-medium-gray;
            z-index: 1;
            position: relative;
            flex-direction: row;

            .modal-title {
                //flex-grow: 1;
                flex: 1 1 auto;
                margin-right: 16px;
                font-size: 12px;
                color: $color-iron;
                font-weight: 700;
                text-transform: uppercase;
                text-overflow: ellipsis;
                overflow-x: hidden;
                white-space: nowrap;
            }

            .close {
                margin-top: -8px;
                font-size: 26px;
                color: $color-iron;
                text-shadow: none;
                opacity: 1;
                font-family: $font-hilti-wide;

                &:not([disabled]):hover {
                    opacity: 1;
                    color: $color-coal;
                }

                &[disabled] {
                    cursor: not-allowed;
                }
            }
        }

        .modal-footer {
            height: 48px;
            padding: 0 16px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            text-align: left;
            background: $color-white;
            border-top: 1px solid $color-medium-gray;
            z-index: 1;
            position: relative;

            .modal-button {
                &:not(:first-child) {
                    margin-left: 8px;
                }
            }
        }

        .modal-body {
            font-size: 12px;
            padding: 16px;
        }

        .modal-body .section, .modal-body.section {
            margin-bottom: 32px;

            &:last-child {
                margin-bottom: 0;
            }

            .control-row {
                margin-bottom: 16px;
                display: flex;
                flex-direction: column;

                &:last-child {
                    margin-bottom: 0;
                }

                .input {
                    width: 100%;
                }

                &.multiple {
                    flex-direction: row;

                    .control-column {
                        flex: 1;
                        min-width: 0;
                        margin-right: 16px;
                        display: flex;
                        flex-direction: column;

                        &:last-child {
                            margin-right: 0;
                        }
                    }
                }
            }

            .section-row {
                display: flex;

                .section-column {
                    flex: 1;
                    min-width: 0;
                    padding: 0 16px;

                    &:first-child {
                        padding-left: 0;
                    }

                    &:last-child {
                        padding-right: 0;

                        &.separator {
                            border-right: none;
                        }
                    }

                    &.separator {
                        margin-top: -16px;
                        padding-top: 16px;
                        margin-bottom: -16px;
                        padding-bottom: 16px;
                        border-right: 1px solid $color-metal;
                    }
                }

                .size2 {
                    flex: 2 !important;
                }

                .size3 {
                    flex: 3 !important;
                }
            }
        }
    }
}

.modal-open .modal {
    overflow: auto;
    text-align: center;

    .modal-dialog {
        margin: 30px 0;
        display: inline-block;
        text-align: left;
        width: 480px;
    }

    .modal-xl {
        width: 1280px;
        max-width: calc(100vw - 60px);
        min-width: 880px;
    }

    .modal-lg {
        width: 800px;
    }

    .modal-sm {
        width: 300px;
    }

    .modal-auto {
        width: auto;
    }
}
