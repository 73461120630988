﻿.user-settings-modal.default-modal {
    .modal-content .modal-body {
        display: flex;
        padding: 16px;
        flex-direction: column;

        .settings-data {
            display: flex;
            font-size: 12px;

            .content {
                max-width: 300px;
                min-width: 0;
                margin: 0 16px;
                flex: 1;

                #user-settings-my-subscription-button {
                    margin-top: 36px;
                    width: 100%;
                }

                &:first-child {
                    margin-left: 0;
                }

                &:last-child {
                    margin-right: 0;
                }

                .content-title {
                    margin: 0 0 16px 0;
                    color: $color-iron;
                    font-weight: 700;
                    border-bottom: 1px solid #999;
                }

                .control-row {
                    margin-bottom: 16px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }

        .sap-account-data-disclaimer {
            margin: 16px 0px 0px 0px;
        }
    }
}
