﻿.add-new-anchor-modal {
    .modal-content .modal-body {
        font-size: 12px;
        padding: 16px;

        .main-container {
            display: flex;
            flex-direction: column;

            .title {
                margin: 0 0 16px 0;
                font-weight: 700;
                border-bottom: 1px solid $color-iron;
                color: $color-iron;
            }

            .main-content {
                flex: 1 0 auto;
                margin-bottom: -16px;
            }
        }

        .general {
            .main-content {
                .control-row {
                    margin-bottom: 16px;
                }
            }
        }
    }
}
