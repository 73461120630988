@import '../../../src/styles/control-helpers.scss';
@import '../../../src/styles/fonts.scss';

.region {
    > .title-container {
        display: flex;
        align-items: center;
        padding: 0 0 0 8px;
        height: auto;

        .drag-handle {
            flex: 1;
            height: 100%;
            display: flex;
            align-items: center;
            margin: 7px 0;

            .title {
                overflow-x: hidden;
                text-overflow: ellipsis;
                margin: 0 4px 0 0;
                background: none;
            }
        }

        .favorite {
            border: none;
            background: none;
            padding: 0;
        }

        .region-collapse {
            border: none;
            background: none;
            padding: 0;
        }
    }

    > .controls {
        display: flex;
        flex-wrap: wrap;
        margin-left: -16px;
        padding: 4px 16px;

        > .control {
            width: 50%;
            padding: 4px 0 4px 16px;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;

            &.react-label-control {
                justify-content: flex-start;
            }

            &.size-100 {
                width: 100%;
            }
        }
    }
}
