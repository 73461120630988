﻿/* INPUT */
$style-input-color: null;
$style-input-size: null;
$style-input-weight: null;
$style-input-border-width: null;
$style-input-border-color: null;
$style-input-border-radius: null;
$style-input-background: null;
$style-input-opacity: null;
$style-input-box-shadow: null;
$style-input-placeholder-color: null;

// hover
$style-input-hover-color: null;
$style-input-hover-size: null;
$style-input-hover-weight: null;
$style-input-hover-border-width: null;
$style-input-hover-border-color: null;
$style-input-hover-border-radius: null;
$style-input-hover-background: null;
$style-input-hover-opacity: null;
$style-input-hover-box-shadow: null;

// focus
$style-input-focus-color: null;
$style-input-focus-size: null;
$style-input-focus-weight: null;
$style-input-focus-border-width: null;
$style-input-focus-border-color: null;
$style-input-focus-border-radius: null;
$style-input-focus-background: null;
$style-input-focus-opacity: null;
$style-input-focus-box-shadow: null;

// active
$style-input-active-color: null;
$style-input-active-size: null;
$style-input-active-weight: null;
$style-input-active-border-width: null;
$style-input-active-border-color: null;
$style-input-active-border-radius: null;
$style-input-active-background: null;
$style-input-active-opacity: null;
$style-input-active-box-shadow: null;

// disabled
$style-input-disabled-color: null;
$style-input-disabled-size: null;
$style-input-disabled-weight: null;
$style-input-disabled-border-width: null;
$style-input-disabled-border-color: null;
$style-input-disabled-border-radius: null;
$style-input-disabled-background: null;
$style-input-disabled-opacity: null;
$style-input-disabled-box-shadow: null;


/* RADIO BUTTON TEXT */
$style-radio-button-text-color: null;
$style-radio-button-text-size: null;
$style-radio-button-text-weight: null;
$style-radio-button-text-border-width: null;
$style-radio-button-text-border-color: null;
$style-radio-button-text-border-radius: null;
$style-radio-button-text-background: null;
$style-radio-button-text-opacity: null;
$style-radio-button-text-box-shadow: null;

// hover
$style-radio-button-text-hover-color: null;
$style-radio-button-text-hover-size: null;
$style-radio-button-text-hover-weight: null;
$style-radio-button-text-hover-border-width: null;
$style-radio-button-text-hover-border-color: null;
$style-radio-button-text-hover-border-radius: null;
$style-radio-button-text-hover-background: null;
$style-radio-button-text-hover-opacity: null;
$style-radio-button-text-hover-box-shadow: null;

// focus
$style-radio-button-text-focus-color: null;
$style-radio-button-text-focus-size: null;
$style-radio-button-text-focus-weight: null;
$style-radio-button-text-focus-border-width: null;
$style-radio-button-text-focus-border-color: null;
$style-radio-button-text-focus-border-radius: null;
$style-radio-button-text-focus-background: null;
$style-radio-button-text-focus-opacity: null;
$style-radio-button-text-focus-box-shadow: null;

// active
$style-radio-button-text-active-color: null;
$style-radio-button-text-active-size: null;
$style-radio-button-text-active-weight: null;
$style-radio-button-text-active-border-width: null;
$style-radio-button-text-active-border-color: null;
$style-radio-button-text-active-border-radius: null;
$style-radio-button-text-active-background: null;
$style-radio-button-text-active-opacity: null;
$style-radio-button-text-active-box-shadow: null;

// disabled
$style-radio-button-text-disabled-color: null;
$style-radio-button-text-disabled-size: null;
$style-radio-button-text-disabled-weight: null;
$style-radio-button-text-disabled-border-width: null;
$style-radio-button-text-disabled-border-color: null;
$style-radio-button-text-disabled-border-radius: null;
$style-radio-button-text-disabled-background: null;
$style-radio-button-text-disabled-opacity: null;
$style-radio-button-text-disabled-box-shadow: null;


/* CHECKBOX TEXT */
$style-checkbox-text-color: null;
$style-checkbox-text-size: null;
$style-checkbox-text-weight: null;
$style-checkbox-text-border-width: null;
$style-checkbox-text-border-color: null;
$style-checkbox-text-border-radius: null;
$style-checkbox-text-background: null;
$style-checkbox-text-opacity: null;
$style-checkbox-text-box-shadow: null;

// hover
$style-checkbox-text-hover-color: null;
$style-checkbox-text-hover-size: null;
$style-checkbox-text-hover-weight: null;
$style-checkbox-text-hover-border-width: null;
$style-checkbox-text-hover-border-color: null;
$style-checkbox-text-hover-border-radius: null;
$style-checkbox-text-hover-background: null;
$style-checkbox-text-hover-opacity: null;
$style-checkbox-text-hover-box-shadow: null;

// focus
$style-checkbox-text-focus-color: null;
$style-checkbox-text-focus-size: null;
$style-checkbox-text-focus-weight: null;
$style-checkbox-text-focus-border-width: null;
$style-checkbox-text-focus-border-color: null;
$style-checkbox-text-focus-border-radius: null;
$style-checkbox-text-focus-background: null;
$style-checkbox-text-focus-opacity: null;
$style-checkbox-text-focus-box-shadow: null;

// active
$style-checkbox-text-active-color: null;
$style-checkbox-text-active-size: null;
$style-checkbox-text-active-weight: null;
$style-checkbox-text-active-border-width: null;
$style-checkbox-text-active-border-color: null;
$style-checkbox-text-active-border-radius: null;
$style-checkbox-text-active-background: null;
$style-checkbox-text-active-opacity: null;
$style-checkbox-text-active-box-shadow: null;

// disabled
$style-checkbox-text-disabled-color: null;
$style-checkbox-text-disabled-size: null;
$style-checkbox-text-disabled-weight: null;
$style-checkbox-text-disabled-border-width: null;
$style-checkbox-text-disabled-border-color: null;
$style-checkbox-text-disabled-border-radius: null;
$style-checkbox-text-disabled-background: null;
$style-checkbox-text-disabled-opacity: null;
$style-checkbox-text-disabled-box-shadow: null;


/* CONTROL TITLE */
$style-control-title-color: null;
$style-control-title-size: null;
$style-control-title-weight: null;
$style-control-title-border-width: null;
$style-control-title-border-color: null;
$style-control-title-border-radius: null;
$style-control-title-background: null;
$style-control-title-opacity: null;
$style-control-title-box-shadow: null;

// hover
$style-control-title-hover-color: null;
$style-control-title-hover-size: null;
$style-control-title-hover-weight: null;
$style-control-title-hover-border-width: null;
$style-control-title-hover-border-color: null;
$style-control-title-hover-border-radius: null;
$style-control-title-hover-background: null;
$style-control-title-hover-opacity: null;
$style-control-title-hover-box-shadow: null;

// focus
$style-control-title-focus-color: null;
$style-control-title-focus-size: null;
$style-control-title-focus-weight: null;
$style-control-title-focus-border-width: null;
$style-control-title-focus-border-color: null;
$style-control-title-focus-border-radius: null;
$style-control-title-focus-background: null;
$style-control-title-focus-opacity: null;
$style-control-title-focus-box-shadow: null;

// active
$style-control-title-active-color: null;
$style-control-title-active-size: null;
$style-control-title-active-weight: null;
$style-control-title-active-border-width: null;
$style-control-title-active-border-color: null;
$style-control-title-active-border-radius: null;
$style-control-title-active-background: null;
$style-control-title-active-opacity: null;
$style-control-title-active-box-shadow: null;

// disabled
$style-control-title-disabled-color: null;
$style-control-title-disabled-size: null;
$style-control-title-disabled-weight: null;
$style-control-title-disabled-border-width: null;
$style-control-title-disabled-border-color: null;
$style-control-title-disabled-border-radius: null;
$style-control-title-disabled-background: null;
$style-control-title-disabled-opacity: null;
$style-control-title-disabled-box-shadow: null;


/* REGION HEADER */
$style-region-header-color: null;
$style-region-header-size: null;
$style-region-header-weight: null;
$style-region-header-border-width: null;
$style-region-header-border-color: null;
$style-region-header-border-radius: null;
$style-region-header-background: null;
$style-region-header-opacity: null;
$style-region-header-box-shadow: null;

// hover
$style-region-header-hover-color: null;
$style-region-header-hover-size: null;
$style-region-header-hover-weight: null;
$style-region-header-hover-border-width: null;
$style-region-header-hover-border-color: null;
$style-region-header-hover-border-radius: null;
$style-region-header-hover-background: null;
$style-region-header-hover-opacity: null;
$style-region-header-hover-box-shadow: null;

// focus
$style-region-header-focus-color: null;
$style-region-header-focus-size: null;
$style-region-header-focus-weight: null;
$style-region-header-focus-border-width: null;
$style-region-header-focus-border-color: null;
$style-region-header-focus-border-radius: null;
$style-region-header-focus-background: null;
$style-region-header-focus-opacity: null;
$style-region-header-focus-box-shadow: null;

// active
$style-region-header-active-color: null;
$style-region-header-active-size: null;
$style-region-header-active-weight: null;
$style-region-header-active-border-width: null;
$style-region-header-active-border-color: null;
$style-region-header-active-border-radius: null;
$style-region-header-active-background: null;
$style-region-header-active-opacity: null;
$style-region-header-active-box-shadow: null;

// disabled
$style-region-header-disabled-color: null;
$style-region-header-disabled-size: null;
$style-region-header-disabled-weight: null;
$style-region-header-disabled-border-width: null;
$style-region-header-disabled-border-color: null;
$style-region-header-disabled-border-radius: null;
$style-region-header-disabled-background: null;
$style-region-header-disabled-opacity: null;
$style-region-header-disabled-box-shadow: null;


/* LABEL TEXT */
$style-label-text-color: null;
$style-label-text-size: null;
$style-label-text-weight: null;
$style-label-text-border-width: null;
$style-label-text-border-color: null;
$style-label-text-border-radius: null;
$style-label-text-background: null;
$style-label-text-opacity: null;
$style-label-text-box-shadow: null;

// hover
$style-label-text-hover-color: null;
$style-label-text-hover-size: null;
$style-label-text-hover-weight: null;
$style-label-text-hover-border-width: null;
$style-label-text-hover-border-color: null;
$style-label-text-hover-border-radius: null;
$style-label-text-hover-background: null;
$style-label-text-hover-opacity: null;
$style-label-text-hover-box-shadow: null;

// focus
$style-label-text-focus-color: null;
$style-label-text-focus-size: null;
$style-label-text-focus-weight: null;
$style-label-text-focus-border-width: null;
$style-label-text-focus-border-color: null;
$style-label-text-focus-border-radius: null;
$style-label-text-focus-background: null;
$style-label-text-focus-opacity: null;
$style-label-text-focus-box-shadow: null;

// active
$style-label-text-active-color: null;
$style-label-text-active-size: null;
$style-label-text-active-weight: null;
$style-label-text-active-border-width: null;
$style-label-text-active-border-color: null;
$style-label-text-active-border-radius: null;
$style-label-text-active-background: null;
$style-label-text-active-opacity: null;
$style-label-text-active-box-shadow: null;

// disabled
$style-label-text-disabled-color: null;
$style-label-text-disabled-size: null;
$style-label-text-disabled-weight: null;
$style-label-text-disabled-border-width: null;
$style-label-text-disabled-border-color: null;
$style-label-text-disabled-border-radius: null;
$style-label-text-disabled-background: null;
$style-label-text-disabled-opacity: null;
$style-label-text-disabled-box-shadow: null;


/* REGION */
$style-region-color: null;
$style-region-size: null;
$style-region-weight: null;
$style-region-border-width: null;
$style-region-border-color: null;
$style-region-border-radius: null;
$style-region-background: null;
$style-region-opacity: null;
$style-region-box-shadow: null;

// hover
$style-region-hover-color: null;
$style-region-hover-size: null;
$style-region-hover-weight: null;
$style-region-hover-border-width: null;
$style-region-hover-border-color: null;
$style-region-hover-border-radius: null;
$style-region-hover-background: null;
$style-region-hover-opacity: null;
$style-region-hover-box-shadow: null;

// focus
$style-region-focus-color: null;
$style-region-focus-size: null;
$style-region-focus-weight: null;
$style-region-focus-border-width: null;
$style-region-focus-border-color: null;
$style-region-focus-border-radius: null;
$style-region-focus-background: null;
$style-region-focus-opacity: null;
$style-region-focus-box-shadow: null;

// active
$style-region-active-color: null;
$style-region-active-size: null;
$style-region-active-weight: null;
$style-region-active-border-width: null;
$style-region-active-border-color: null;
$style-region-active-border-radius: null;
$style-region-active-background: null;
$style-region-active-opacity: null;
$style-region-active-box-shadow: null;

// disabled
$style-region-disabled-color: null;
$style-region-disabled-size: null;
$style-region-disabled-weight: null;
$style-region-disabled-border-width: null;
$style-region-disabled-border-color: null;
$style-region-disabled-border-radius: null;
$style-region-disabled-background: null;
$style-region-disabled-opacity: null;
$style-region-disabled-box-shadow: null;


/* MENU */
$style-menu-color: null;
$style-menu-size: null;
$style-menu-weight: null;
$style-menu-border-width: null;
$style-menu-border-color: null;
$style-menu-border-radius: null;
$style-menu-background: null;
$style-menu-opacity: null;
$style-menu-box-shadow: null;

// hover
$style-menu-hover-color: null;
$style-menu-hover-size: null;
$style-menu-hover-weight: null;
$style-menu-hover-border-width: null;
$style-menu-hover-border-color: null;
$style-menu-hover-border-radius: null;
$style-menu-hover-background: null;
$style-menu-hover-opacity: null;
$style-menu-hover-box-shadow: null;

// focus
$style-menu-focus-color: null;
$style-menu-focus-size: null;
$style-menu-focus-weight: null;
$style-menu-focus-border-width: null;
$style-menu-focus-border-color: null;
$style-menu-focus-border-radius: null;
$style-menu-focus-background: null;
$style-menu-focus-opacity: null;
$style-menu-focus-box-shadow: null;

// active
$style-menu-active-color: null;
$style-menu-active-size: null;
$style-menu-active-weight: null;
$style-menu-active-border-width: null;
$style-menu-active-border-color: null;
$style-menu-active-border-radius: null;
$style-menu-active-background: null;
$style-menu-active-opacity: null;
$style-menu-active-box-shadow: null;

// disabled
$style-menu-disabled-color: null;
$style-menu-disabled-size: null;
$style-menu-disabled-weight: null;
$style-menu-disabled-border-width: null;
$style-menu-disabled-border-color: null;
$style-menu-disabled-border-radius: null;
$style-menu-disabled-background: null;
$style-menu-disabled-opacity: null;
$style-menu-disabled-box-shadow: null;


/* DROPDOWN */
$style-dropdown-color: null;
$style-dropdown-size: null;
$style-dropdown-weight: null;
$style-dropdown-border-width: null;
$style-dropdown-border-color: null;
$style-dropdown-border-radius: null;
$style-dropdown-background: null;
$style-dropdown-opacity: null;
$style-dropdown-box-shadow: null;

// hover
$style-dropdown-hover-color: null;
$style-dropdown-hover-size: null;
$style-dropdown-hover-weight: null;
$style-dropdown-hover-border-width: null;
$style-dropdown-hover-border-color: null;
$style-dropdown-hover-border-radius: null;
$style-dropdown-hover-background: null;
$style-dropdown-hover-opacity: null;
$style-dropdown-hover-box-shadow: null;

// focus
$style-dropdown-focus-color: null;
$style-dropdown-focus-size: null;
$style-dropdown-focus-weight: null;
$style-dropdown-focus-border-width: null;
$style-dropdown-focus-border-color: null;
$style-dropdown-focus-border-radius: null;
$style-dropdown-focus-background: null;
$style-dropdown-focus-opacity: null;
$style-dropdown-focus-box-shadow: null;

// active
$style-dropdown-active-color: null;
$style-dropdown-active-size: null;
$style-dropdown-active-weight: null;
$style-dropdown-active-border-width: null;
$style-dropdown-active-border-color: null;
$style-dropdown-active-border-radius: null;
$style-dropdown-active-background: null;
$style-dropdown-active-opacity: null;
$style-dropdown-active-box-shadow: null;

// disabled
$style-dropdown-disabled-color: null;
$style-dropdown-disabled-size: null;
$style-dropdown-disabled-weight: null;
$style-dropdown-disabled-border-width: null;
$style-dropdown-disabled-border-color: null;
$style-dropdown-disabled-border-radius: null;
$style-dropdown-disabled-background: null;
$style-dropdown-disabled-opacity: null;
$style-dropdown-disabled-box-shadow: null;

// selected
$style-dropdown-selected-color: null;
$style-dropdown-selected-size: null;
$style-dropdown-selected-weight: null;
$style-dropdown-selected-border-width: null;
$style-dropdown-selected-border-color: null;
$style-dropdown-selected-border-radius: null;
$style-dropdown-selected-background: null;
$style-dropdown-selected-opacity: null;
$style-dropdown-selected-box-shadow: null;

/* DROPDOWN-ITEM */
$style-dropdown-item-color: null;
$style-dropdown-item-size: null;
$style-dropdown-item-weight: null;
$style-dropdown-item-border-width: null;
$style-dropdown-item-border-color: null;
$style-dropdown-item-border-radius: null;
$style-dropdown-item-background: null;
$style-dropdown-item-opacity: null;
$style-dropdown-item-box-shadow: null;

// hover
$style-dropdown-item-hover-color: null;
$style-dropdown-item-hover-size: null;
$style-dropdown-item-hover-weight: null;
$style-dropdown-item-hover-border-width: null;
$style-dropdown-item-hover-border-color: null;
$style-dropdown-item-hover-border-radius: null;
$style-dropdown-item-hover-background: null;
$style-dropdown-item-hover-opacity: null;
$style-dropdown-item-hover-box-shadow: null;

// focus
$style-dropdown-item-focus-color: null;
$style-dropdown-item-focus-size: null;
$style-dropdown-item-focus-weight: null;
$style-dropdown-item-focus-border-width: null;
$style-dropdown-item-focus-border-color: null;
$style-dropdown-item-focus-border-radius: null;
$style-dropdown-item-focus-background: null;
$style-dropdown-item-focus-opacity: null;
$style-dropdown-item-focus-box-shadow: null;

// active
$style-dropdown-item-active-color: null;
$style-dropdown-item-active-size: null;
$style-dropdown-item-active-weight: null;
$style-dropdown-item-active-border-width: null;
$style-dropdown-item-active-border-color: null;
$style-dropdown-item-active-border-radius: null;
$style-dropdown-item-active-background: null;
$style-dropdown-item-active-opacity: null;
$style-dropdown-item-active-box-shadow: null;

// disabled
$style-dropdown-item-disabled-color: null;
$style-dropdown-item-disabled-size: null;
$style-dropdown-item-disabled-weight: null;
$style-dropdown-item-disabled-border-width: null;
$style-dropdown-item-disabled-border-color: null;
$style-dropdown-item-disabled-border-radius: null;
$style-dropdown-item-disabled-background: null;
$style-dropdown-item-disabled-opacity: null;
$style-dropdown-item-disabled-box-shadow: null;

// selected
$style-dropdown-item-selected-color: null;
$style-dropdown-item-selected-size: null;
$style-dropdown-item-selected-weight: null;
$style-dropdown-item-selected-border-width: null;
$style-dropdown-item-selected-border-color: null;
$style-dropdown-item-selected-border-radius: null;
$style-dropdown-item-selected-background: null;
$style-dropdown-item-selected-opacity: null;
$style-dropdown-item-selected-box-shadow: null;

/* ROTATE INPUT */
$style-rotate-input-color: null;
$style-rotate-input-size: null;
$style-rotate-input-weight: null;
$style-rotate-input-border-width: null;
$style-rotate-input-border-color: null;
$style-rotate-input-border-radius: null;
$style-rotate-input-background: null;
$style-rotate-input-opacity: null;
$style-rotate-input-box-shadow: null;

// hover
$style-rotate-input-hover-color: null;
$style-rotate-input-hover-size: null;
$style-rotate-input-hover-weight: null;
$style-rotate-input-hover-border-width: null;
$style-rotate-input-hover-border-color: null;
$style-rotate-input-hover-border-radius: null;
$style-rotate-input-hover-background: null;
$style-rotate-input-hover-opacity: null;
$style-rotate-input-hover-box-shadow: null;

// focus
$style-rotate-input-focus-color: null;
$style-rotate-input-focus-size: null;
$style-rotate-input-focus-weight: null;
$style-rotate-input-focus-border-width: null;
$style-rotate-input-focus-border-color: null;
$style-rotate-input-focus-border-radius: null;
$style-rotate-input-focus-background: null;
$style-rotate-input-focus-opacity: null;
$style-rotate-input-focus-box-shadow: null;

// active
$style-rotate-input-active-color: null;
$style-rotate-input-active-size: null;
$style-rotate-input-active-weight: null;
$style-rotate-input-active-border-width: null;
$style-rotate-input-active-border-color: null;
$style-rotate-input-active-border-radius: null;
$style-rotate-input-active-background: null;
$style-rotate-input-active-opacity: null;
$style-rotate-input-active-box-shadow: null;

// disabled
$style-rotate-input-disabled-color: null;
$style-rotate-input-disabled-size: null;
$style-rotate-input-disabled-weight: null;
$style-rotate-input-disabled-border-width: null;
$style-rotate-input-disabled-border-color: null;
$style-rotate-input-disabled-border-radius: null;
$style-rotate-input-disabled-background: null;
$style-rotate-input-disabled-opacity: null;
$style-rotate-input-disabled-box-shadow: null;


/* ROTATE BUTTON */
$style-rotate-button-color: null;
$style-rotate-button-size: null;
$style-rotate-button-weight: null;
$style-rotate-button-border-width: null;
$style-rotate-button-border-color: null;
$style-rotate-button-border-radius: null;
$style-rotate-button-background: null;
$style-rotate-button-opacity: null;
$style-rotate-button-box-shadow: null;

// hover
$style-rotate-button-hover-color: null;
$style-rotate-button-hover-size: null;
$style-rotate-button-hover-weight: null;
$style-rotate-button-hover-border-width: null;
$style-rotate-button-hover-border-color: null;
$style-rotate-button-hover-border-radius: null;
$style-rotate-button-hover-background: null;
$style-rotate-button-hover-opacity: null;
$style-rotate-button-hover-box-shadow: null;

// focus
$style-rotate-button-focus-color: null;
$style-rotate-button-focus-size: null;
$style-rotate-button-focus-weight: null;
$style-rotate-button-focus-border-width: null;
$style-rotate-button-focus-border-color: null;
$style-rotate-button-focus-border-radius: null;
$style-rotate-button-focus-background: null;
$style-rotate-button-focus-opacity: null;
$style-rotate-button-focus-box-shadow: null;

// active
$style-rotate-button-active-color: null;
$style-rotate-button-active-size: null;
$style-rotate-button-active-weight: null;
$style-rotate-button-active-border-width: null;
$style-rotate-button-active-border-color: null;
$style-rotate-button-active-border-radius: null;
$style-rotate-button-active-background: null;
$style-rotate-button-active-opacity: null;
$style-rotate-button-active-box-shadow: null;

// disabled
$style-rotate-button-disabled-color: null;
$style-rotate-button-disabled-size: null;
$style-rotate-button-disabled-weight: null;
$style-rotate-button-disabled-border-width: null;
$style-rotate-button-disabled-border-color: null;
$style-rotate-button-disabled-border-radius: null;
$style-rotate-button-disabled-background: null;
$style-rotate-button-disabled-opacity: null;
$style-rotate-button-disabled-box-shadow: null;


/* BUTTON */
$style-button-color: null;
$style-button-size: null;
$style-button-weight: null;
$style-button-border-width: null;
$style-button-border-color: null;
$style-button-border-radius: null;
$style-button-background: null;
$style-button-opacity: null;
$style-button-box-shadow: null;

// hover
$style-button-hover-color: null;
$style-button-hover-size: null;
$style-button-hover-weight: null;
$style-button-hover-border-width: null;
$style-button-hover-border-color: null;
$style-button-hover-border-radius: null;
$style-button-hover-background: null;
$style-button-hover-opacity: null;
$style-button-hover-box-shadow: null;

// focus
$style-button-focus-color: null;
$style-button-focus-size: null;
$style-button-focus-weight: null;
$style-button-focus-border-width: null;
$style-button-focus-border-color: null;
$style-button-focus-border-radius: null;
$style-button-focus-background: null;
$style-button-focus-opacity: null;
$style-button-focus-box-shadow: null;

// active
$style-button-active-color: null;
$style-button-active-size: null;
$style-button-active-weight: null;
$style-button-active-border-width: null;
$style-button-active-border-color: null;
$style-button-active-border-radius: null;
$style-button-active-background: null;
$style-button-active-opacity: null;
$style-button-active-box-shadow: null;

// disabled
$style-button-disabled-color: null;
$style-button-disabled-size: null;
$style-button-disabled-weight: null;
$style-button-disabled-border-width: null;
$style-button-disabled-border-color: null;
$style-button-disabled-border-radius: null;
$style-button-disabled-background: null;
$style-button-disabled-opacity: null;
$style-button-disabled-box-shadow: null;

// selected
$style-button-selected-color: null;
$style-button-selected-size: null;
$style-button-selected-weight: null;
$style-button-selected-border-width: null;
$style-button-selected-border-color: null;
$style-button-selected-border-radius: null;
$style-button-selected-background: null;
$style-button-selected-opacity: null;
$style-button-selected-box-shadow: null;

// selected disabled
$style-button-selected-disabled-color: null;
$style-button-selected-disabled-size: null;
$style-button-selected-disabled-weight: null;
$style-button-selected-disabled-border-width: null;
$style-button-selected-disabled-border-color: null;
$style-button-selected-disabled-border-radius: null;
$style-button-selected-disabled-background: null;
$style-button-selected-disabled-opacity: null;
$style-button-selected-disabled-box-shadow: null;


/* BUTTON DEFAULT */
$style-button-default-color: null;
$style-button-default-size: null;
$style-button-default-weight: null;
$style-button-default-border-width: null;
$style-button-default-border-color: null;
$style-button-default-border-radius: null;
$style-button-default-background: null;
$style-button-default-opacity: null;
$style-button-default-box-shadow: null;

// hover
$style-button-default-hover-color: null;
$style-button-default-hover-size: null;
$style-button-default-hover-weight: null;
$style-button-default-hover-border-width: null;
$style-button-default-hover-border-color: null;
$style-button-default-hover-border-radius: null;
$style-button-default-hover-background: null;
$style-button-default-hover-opacity: null;
$style-button-default-hover-box-shadow: null;

// focus
$style-button-default-focus-color: null;
$style-button-default-focus-size: null;
$style-button-default-focus-weight: null;
$style-button-default-focus-border-width: null;
$style-button-default-focus-border-color: null;
$style-button-default-focus-border-radius: null;
$style-button-default-focus-background: null;
$style-button-default-focus-opacity: null;
$style-button-default-focus-box-shadow: null;

// active
$style-button-default-active-color: null;
$style-button-default-active-size: null;
$style-button-default-active-weight: null;
$style-button-default-active-border-width: null;
$style-button-default-active-border-color: null;
$style-button-default-active-border-radius: null;
$style-button-default-active-background: null;
$style-button-default-active-opacity: null;
$style-button-default-active-box-shadow: null;

// disabled
$style-button-default-disabled-color: null;
$style-button-default-disabled-size: null;
$style-button-default-disabled-weight: null;
$style-button-default-disabled-border-width: null;
$style-button-default-disabled-border-color: null;
$style-button-default-disabled-border-radius: null;
$style-button-default-disabled-background: null;
$style-button-default-disabled-opacity: null;
$style-button-default-disabled-box-shadow: null;

// selected
$style-button-default-selected-color: null;
$style-button-default-selected-size: null;
$style-button-default-selected-weight: null;
$style-button-default-selected-border-width: null;
$style-button-default-selected-border-color: null;
$style-button-default-selected-border-radius: null;
$style-button-default-selected-background: null;
$style-button-default-selected-opacity: null;
$style-button-default-selected-box-shadow: null;

// selected disabled
$style-button-default-selected-disabled-color: null;
$style-button-default-selected-disabled-size: null;
$style-button-default-selected-disabled-weight: null;
$style-button-default-selected-disabled-border-width: null;
$style-button-default-selected-disabled-border-color: null;
$style-button-default-selected-disabled-border-radius: null;
$style-button-default-selected-disabled-background: null;
$style-button-default-selected-disabled-opacity: null;
$style-button-default-selected-disabled-box-shadow: null;


/* BUTTON PRIMARY */
$style-button-primary-color: null;
$style-button-primary-size: null;
$style-button-primary-weight: null;
$style-button-primary-border-width: null;
$style-button-primary-border-color: null;
$style-button-primary-border-radius: null;
$style-button-primary-background: null;
$style-button-primary-opacity: null;
$style-button-primary-box-shadow: null;

// hover
$style-button-primary-hover-color: null;
$style-button-primary-hover-size: null;
$style-button-primary-hover-weight: null;
$style-button-primary-hover-border-width: null;
$style-button-primary-hover-border-color: null;
$style-button-primary-hover-border-radius: null;
$style-button-primary-hover-background: null;
$style-button-primary-hover-opacity: null;
$style-button-primary-hover-box-shadow: null;

// focus
$style-button-primary-focus-color: null;
$style-button-primary-focus-size: null;
$style-button-primary-focus-weight: null;
$style-button-primary-focus-border-width: null;
$style-button-primary-focus-border-color: null;
$style-button-primary-focus-border-radius: null;
$style-button-primary-focus-background: null;
$style-button-primary-focus-opacity: null;
$style-button-primary-focus-box-shadow: null;

// active
$style-button-primary-active-color: null;
$style-button-primary-active-size: null;
$style-button-primary-active-weight: null;
$style-button-primary-active-border-width: null;
$style-button-primary-active-border-color: null;
$style-button-primary-active-border-radius: null;
$style-button-primary-active-background: null;
$style-button-primary-active-opacity: null;
$style-button-primary-active-box-shadow: null;

// disabled
$style-button-primary-disabled-color: null;
$style-button-primary-disabled-size: null;
$style-button-primary-disabled-weight: null;
$style-button-primary-disabled-border-width: null;
$style-button-primary-disabled-border-color: null;
$style-button-primary-disabled-border-radius: null;
$style-button-primary-disabled-background: null;
$style-button-primary-disabled-opacity: null;
$style-button-primary-disabled-box-shadow: null;

// selected
$style-button-primary-selected-color: null;
$style-button-primary-selected-size: null;
$style-button-primary-selected-weight: null;
$style-button-primary-selected-border-width: null;
$style-button-primary-selected-border-color: null;
$style-button-primary-selected-border-radius: null;
$style-button-primary-selected-background: null;
$style-button-primary-selected-opacity: null;
$style-button-primary-selected-box-shadow: null;

// selected disabled
$style-button-primary-selected-disabled-color: null;
$style-button-primary-selected-disabled-size: null;
$style-button-primary-selected-disabled-weight: null;
$style-button-primary-selected-disabled-border-width: null;
$style-button-primary-selected-disabled-border-color: null;
$style-button-primary-selected-disabled-border-radius: null;
$style-button-primary-selected-disabled-background: null;
$style-button-primary-selected-disabled-opacity: null;
$style-button-primary-selected-disabled-box-shadow: null;

/* BUTTON CTA */
$style-button-CTA-color: null;
$style-button-CTA-size: null;
$style-button-CTA-weight: null;
$style-button-CTA-border-width: null;
$style-button-CTA-border-color: null;
$style-button-CTA-border-radius: null;
$style-button-CTA-background: null;
$style-button-CTA-opacity: null;
$style-button-CTA-box-shadow: null;

// hover
$style-button-CTA-hover-color: null;
$style-button-CTA-hover-size: null;
$style-button-CTA-hover-weight: null;
$style-button-CTA-hover-border-width: null;
$style-button-CTA-hover-border-color: null;
$style-button-CTA-hover-border-radius: null;
$style-button-CTA-hover-background: null;
$style-button-CTA-hover-opacity: null;
$style-button-CTA-hover-box-shadow: null;

// focus
$style-button-CTA-focus-color: null;
$style-button-CTA-focus-size: null;
$style-button-CTA-focus-weight: null;
$style-button-CTA-focus-border-width: null;
$style-button-CTA-focus-border-color: null;
$style-button-CTA-focus-border-radius: null;
$style-button-CTA-focus-background: null;
$style-button-CTA-focus-opacity: null;
$style-button-CTA-focus-box-shadow: null;

// active
$style-button-CTA-active-color: null;
$style-button-CTA-active-size: null;
$style-button-CTA-active-weight: null;
$style-button-CTA-active-border-width: null;
$style-button-CTA-active-border-color: null;
$style-button-CTA-active-border-radius: null;
$style-button-CTA-active-background: null;
$style-button-CTA-active-opacity: null;
$style-button-CTA-active-box-shadow: null;

// disabled
$style-button-CTA-disabled-color: null;
$style-button-CTA-disabled-size: null;
$style-button-CTA-disabled-weight: null;
$style-button-CTA-disabled-border-width: null;
$style-button-CTA-disabled-border-color: null;
$style-button-CTA-disabled-border-radius: null;
$style-button-CTA-disabled-background: null;
$style-button-CTA-disabled-opacity: null;
$style-button-CTA-disabled-box-shadow: null;

// selected
$style-button-CTA-selected-color: null;
$style-button-CTA-selected-size: null;
$style-button-CTA-selected-weight: null;
$style-button-CTA-selected-border-width: null;
$style-button-CTA-selected-border-color: null;
$style-button-CTA-selected-border-radius: null;
$style-button-CTA-selected-background: null;
$style-button-CTA-selected-opacity: null;
$style-button-CTA-selected-box-shadow: null;

// selected disabled
$style-button-CTA-selected-disabled-color: null;
$style-button-CTA-selected-disabled-size: null;
$style-button-CTA-selected-disabled-weight: null;
$style-button-CTA-selected-disabled-border-width: null;
$style-button-CTA-selected-disabled-border-color: null;
$style-button-CTA-selected-disabled-border-radius: null;
$style-button-CTA-selected-disabled-background: null;
$style-button-CTA-selected-disabled-opacity: null;
$style-button-CTA-selected-disabled-box-shadow: null;

/* BUTTON GHOST */
$style-button-ghost-color: null;
$style-button-ghost-size: null;
$style-button-ghost-weight: null;
$style-button-ghost-border-width: null;
$style-button-ghost-border-color: null;
$style-button-ghost-border-radius: null;
$style-button-ghost-background: null;
$style-button-ghost-opacity: null;
$style-button-ghost-box-shadow: null;

// hover
$style-button-ghost-hover-color: null;
$style-button-ghost-hover-size: null;
$style-button-ghost-hover-weight: null;
$style-button-ghost-hover-border-width: null;
$style-button-ghost-hover-border-color: null;
$style-button-ghost-hover-border-radius: null;
$style-button-ghost-hover-background: null;
$style-button-ghost-hover-opacity: null;
$style-button-ghost-hover-box-shadow: null;

// focus
$style-button-ghost-focus-color: null;
$style-button-ghost-focus-size: null;
$style-button-ghost-focus-weight: null;
$style-button-ghost-focus-border-width: null;
$style-button-ghost-focus-border-color: null;
$style-button-ghost-focus-border-radius: null;
$style-button-ghost-focus-background: null;
$style-button-ghost-focus-opacity: null;
$style-button-ghost-focus-box-shadow: null;

// active
$style-button-ghost-active-color: null;
$style-button-ghost-active-size: null;
$style-button-ghost-active-weight: null;
$style-button-ghost-active-border-width: null;
$style-button-ghost-active-border-color: null;
$style-button-ghost-active-border-radius: null;
$style-button-ghost-active-background: null;
$style-button-ghost-active-opacity: null;
$style-button-ghost-active-box-shadow: null;

// disabled
$style-button-ghost-disabled-color: null;
$style-button-ghost-disabled-size: null;
$style-button-ghost-disabled-weight: null;
$style-button-ghost-disabled-border-width: null;
$style-button-ghost-disabled-border-color: null;
$style-button-ghost-disabled-border-radius: null;
$style-button-ghost-disabled-background: null;
$style-button-ghost-disabled-opacity: null;
$style-button-ghost-disabled-box-shadow: null;

// selected
$style-button-ghost-selected-color: null;
$style-button-ghost-selected-size: null;
$style-button-ghost-selected-weight: null;
$style-button-ghost-selected-border-width: null;
$style-button-ghost-selected-border-color: null;
$style-button-ghost-selected-border-radius: null;
$style-button-ghost-selected-background: null;
$style-button-ghost-selected-opacity: null;
$style-button-ghost-selected-box-shadow: null;

// selected disabled
$style-button-ghost-selected-disabled-color: null;
$style-button-ghost-selected-disabled-size: null;
$style-button-ghost-selected-disabled-weight: null;
$style-button-ghost-selected-disabled-border-width: null;
$style-button-ghost-selected-disabled-border-color: null;
$style-button-ghost-selected-disabled-border-radius: null;
$style-button-ghost-selected-disabled-background: null;
$style-button-ghost-selected-disabled-opacity: null;
$style-button-ghost-selected-disabled-box-shadow: null;


/* BUTTON LINK */
$style-button-link-color: null;
$style-button-link-size: null;
$style-button-link-weight: null;
$style-button-link-border-width: null;
$style-button-link-border-color: null;
$style-button-link-border-radius: null;
$style-button-link-background: null;
$style-button-link-opacity: null;
$style-button-link-box-shadow: null;

// hover
$style-button-link-hover-color: null;
$style-button-link-hover-size: null;
$style-button-link-hover-weight: null;
$style-button-link-hover-border-width: null;
$style-button-link-hover-border-color: null;
$style-button-link-hover-border-radius: null;
$style-button-link-hover-background: null;
$style-button-link-hover-opacity: null;
$style-button-link-hover-box-shadow: null;

// focus
$style-button-link-focus-color: null;
$style-button-link-focus-size: null;
$style-button-link-focus-weight: null;
$style-button-link-focus-border-width: null;
$style-button-link-focus-border-color: null;
$style-button-link-focus-border-radius: null;
$style-button-link-focus-background: null;
$style-button-link-focus-opacity: null;
$style-button-link-focus-box-shadow: null;

// active
$style-button-link-active-color: null;
$style-button-link-active-size: null;
$style-button-link-active-weight: null;
$style-button-link-active-border-width: null;
$style-button-link-active-border-color: null;
$style-button-link-active-border-radius: null;
$style-button-link-active-background: null;
$style-button-link-active-opacity: null;
$style-button-link-active-box-shadow: null;

// disabled
$style-button-link-disabled-color: null;
$style-button-link-disabled-size: null;
$style-button-link-disabled-weight: null;
$style-button-link-disabled-border-width: null;
$style-button-link-disabled-border-color: null;
$style-button-link-disabled-border-radius: null;
$style-button-link-disabled-background: null;
$style-button-link-disabled-opacity: null;
$style-button-link-disabled-box-shadow: null;

// selected
$style-button-link-selected-color: null;
$style-button-link-selected-size: null;
$style-button-link-selected-weight: null;
$style-button-link-selected-border-width: null;
$style-button-link-selected-border-color: null;
$style-button-link-selected-border-radius: null;
$style-button-link-selected-background: null;
$style-button-link-selected-opacity: null;
$style-button-link-selected-box-shadow: null;

// selected disabled
$style-button-link-selected-disabled-color: null;
$style-button-link-selected-disabled-size: null;
$style-button-link-selected-disabled-weight: null;
$style-button-link-selected-disabled-border-width: null;
$style-button-link-selected-disabled-border-color: null;
$style-button-link-selected-disabled-border-radius: null;
$style-button-link-selected-disabled-background: null;
$style-button-link-selected-disabled-opacity: null;
$style-button-link-selected-disabled-box-shadow: null;


/* MENU TABS */
$style-menu-tabs-color: null;
$style-menu-tabs-size: null;
$style-menu-tabs-weight: null;
$style-menu-tabs-border-width: null;
$style-menu-tabs-border-color: null;
$style-menu-tabs-border-radius: null;
$style-menu-tabs-background: null;
$style-menu-tabs-opacity: null;
$style-menu-tabs-box-shadow: null;