/* INPUT */
/* RADIO BUTTON TEXT */
/* CHECKBOX TEXT */
/* CONTROL TITLE */
/* REGION HEADER */
/* LABEL TEXT */
/* REGION */
/* MENU */
/* DROPDOWN */
/* DROPDOWN-ITEM */
/* ROTATE INPUT */
/* ROTATE BUTTON */
/* BUTTON */
/* BUTTON DEFAULT */
/* BUTTON PRIMARY */
/* BUTTON CTA */
/* BUTTON GHOST */
/* BUTTON LINK */
/* MENU TABS */
.control .control-header {
  display: flex;
  align-items: center;
}
.control .control-header .control-label:not(.ng-hide) + .tooltip-control-wrapper {
  margin-left: 4px;
}
.control .control-header .tooltip-control-wrapper {
  margin-bottom: 4px;
}

form.ng-submitted .text-box.ng-invalid .text-box-container .input.data,
form.ng-submitted .input.ng-invalid,
form.ng-submitted .dropdown.ng-invalid .control-container .dropdown-button,
form.ng-submitted textarea.ng-invalid,
textarea.ng-touched.ng-invalid {
  background: #ffaf00;
}

.add-new-anchor-modal .modal-content .modal-body {
  font-size: 12px;
  padding: 16px;
}
.add-new-anchor-modal .modal-content .modal-body .main-container {
  display: flex;
  flex-direction: column;
}
.add-new-anchor-modal .modal-content .modal-body .main-container .title {
  margin: 0 0 16px 0;
  font-weight: 700;
  border-bottom: 1px solid #524f53;
  color: #524f53;
}
.add-new-anchor-modal .modal-content .modal-body .main-container .main-content {
  flex: 1 0 auto;
  margin-bottom: -16px;
}
.add-new-anchor-modal .modal-content .modal-body .general .main-content .control-row {
  margin-bottom: 16px;
}

.alert-modal.default-modal .modal-content .modal-header {
  background-color: #d2051e;
}
.alert-modal.default-modal .modal-content .modal-header .modal-title {
  color: #fff;
  text-transform: uppercase;
}
.alert-modal.default-modal .modal-content .modal-header .close {
  color: #fff;
}
.alert-modal.default-modal .modal-content .modal-header .close:not([disabled]):hover {
  color: #fafafa;
}
.alert-modal.default-modal .modal-content .modal-body {
  white-space: pre-line;
  overflow: hidden;
  word-wrap: break-word;
}

.application-settings-modal.default-modal .modal-content .modal-body {
  font-size: 12px;
  padding: 16px;
}
.application-settings-modal.default-modal .modal-content .modal-body .main-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
}
.application-settings-modal.default-modal .modal-content .modal-body .main-container:last-child {
  margin-bottom: 0;
}
.application-settings-modal.default-modal .modal-content .modal-body .main-container .title {
  margin: 0 0 16px 0;
  font-weight: 700;
  border-bottom: 1px solid #524f53;
  color: #524f53;
}
.application-settings-modal.default-modal .modal-content .modal-body .main-container .main-content {
  flex: 1 0 auto;
  margin-bottom: 16px;
}
.application-settings-modal.default-modal .modal-content .modal-body .general .main-content {
  display: flex;
  margin: 0 -16px;
}
.application-settings-modal.default-modal .modal-content .modal-body .general .main-content .data-column {
  flex: 1;
  min-width: 0;
  padding: 0px 16px;
}
.application-settings-modal.default-modal .modal-content .modal-body .general .main-content .data-column .control-row {
  margin-bottom: 16px;
}
.application-settings-modal.default-modal .modal-content .modal-body .general .main-content .data-column .control-row:last-child {
  margin-bottom: 0;
}
.application-settings-modal.default-modal .modal-content .modal-body .units .main-content {
  display: flex;
  margin-left: -16px;
  margin-right: -16px;
}
.application-settings-modal.default-modal .modal-content .modal-body .units .main-content .unit {
  flex: 1;
  min-width: 0;
  margin: 0 16px;
}

.archive-modal.default-modal .modal-content .modal-body {
  padding: 0;
  margin: 0;
}
.archive-modal.default-modal .modal-content .modal-body .table-archive {
  width: 100%;
}
.archive-modal.default-modal .modal-content .modal-body .table-archive tr {
  border-bottom: 1px solid #ddd;
}
.archive-modal.default-modal .modal-content .modal-body .table-archive .table-archive-row {
  height: 34px;
}
.archive-modal.default-modal .modal-content .modal-body .table-archive .table-archive-row td {
  text-align: left;
}
.archive-modal.default-modal .modal-content .modal-body .table-archive .table-archive-row td:nth-child(1) {
  width: 25%;
  padding-left: 16px;
  padding-right: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;
  max-width: 0;
}
.archive-modal.default-modal .modal-content .modal-body .table-archive .table-archive-row td:nth-child(6) {
  width: 15%;
  height: 32px;
  vertical-align: middle;
}
.archive-modal.default-modal .modal-content .modal-body .table-archive .table-archive-row td:nth-child(6) .action-buttons {
  display: inline-table;
  width: 100%;
  height: 32px;
  vertical-align: middle;
  padding-right: 2px;
}
.archive-modal.default-modal .modal-content .modal-body .table-archive .table-archive-row td:nth-child(6) .action-buttons button {
  background: none;
  border: 0;
  display: inline-block;
  float: right;
}
.archive-modal.default-modal .modal-content .modal-body .table-archive .table-archive-row td:nth-child(6) .action-buttons .button-delete {
  margin: 0 2px 0 1px;
}
.archive-modal.default-modal .modal-content .modal-body .table-archive .table-archive-row td:nth-child(6) .action-buttons .button-restore {
  margin-right: -10px;
}
.archive-modal.default-modal .modal-content .modal-body .table-archive .table-archive-row-header {
  color: #524f53;
  background: #ccc;
  height: 39px;
  margin-left: 20px;
  margin-right: 20px;
}
.archive-modal.default-modal .modal-content .modal-body .table-archive .table-archive-row-header th {
  text-align: left;
}
.archive-modal.default-modal .modal-content .modal-body .table-archive .table-archive-row-header th:nth-child(1) {
  width: 25%;
  padding-left: 16px;
}
.archive-modal.default-modal .modal-content .modal-body .table-archive .table-archive-row-header th:nth-child(2) {
  width: 15%;
}
.archive-modal.default-modal .modal-content .modal-body .table-archive .table-archive-row-header th:nth-child(3) {
  width: 15%;
}
.archive-modal.default-modal .modal-content .modal-body .table-archive .table-archive-row-header th:nth-child(4) {
  width: 15%;
}
.archive-modal.default-modal .modal-content .modal-body .table-archive .table-archive-row-header th:nth-child(5) {
  width: 15%;
}
.archive-modal.default-modal .modal-content .modal-body .table-archive .table-archive-row-header th:nth-child(6) {
  width: 15%;
  padding-right: 16px;
  text-align: right;
}

.bom-details-modal.default-modal .modal-content .modal-body {
  font-size: 12px;
  padding: 16px;
}
.bom-details-modal.default-modal .modal-content .modal-body .grid-wrapper {
  position: relative;
  height: 590px;
}
.bom-details-modal.default-modal .modal-content .modal-body .title {
  margin: 0 0 16px 0;
  font-weight: 700;
  border-bottom: 1px solid #524f53;
  color: #524f53;
}
.bom-details-modal.default-modal .modal-content .modal-body .main-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
}
.bom-details-modal.default-modal .modal-content .modal-body .main-container:last-child {
  margin-bottom: 0;
}
.bom-details-modal.default-modal .modal-content .modal-body .main-container .main-content {
  flex: 1 0 auto;
  margin-bottom: 16px;
}
.bom-details-modal.default-modal .modal-content .modal-body .details .main-content {
  display: flex;
  margin: 0 -16px;
}
.bom-details-modal.default-modal .modal-content .modal-body .details .main-content .grid-wrapper {
  position: relative;
  height: 590px;
}
.bom-details-modal.default-modal .modal-content .modal-body .details .main-content .data-column {
  flex: 1;
  min-width: 0;
  padding: 0px 16px;
}
.bom-details-modal.default-modal .modal-content .modal-body .details .main-content .data-column .control-row {
  margin-bottom: 16px;
}
.bom-details-modal.default-modal .modal-content .modal-body .details .main-content .data-column .control-row:last-child {
  margin-bottom: 0;
}
.bom-details-modal.default-modal .modal-content .modal-body .export-buttons {
  display: flex;
  margin: 16px 0 0 0;
}
.bom-details-modal.default-modal .modal-content .modal-body .export-buttons .excel-export-button,
.bom-details-modal.default-modal .modal-content .modal-body .export-buttons .hol-export-button {
  margin: 0 16px 0 0;
}

.control-button .button-element {
  white-space: normal;
  max-width: 100%;
}
.control-button .button-element.size-full {
  width: 100%;
}
.control-button .button-element .image-container .image {
  margin: 0 auto;
}
.control-button .button-element .text {
  white-space: pre-line;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* INPUT */
/* RADIO BUTTON TEXT */
/* CHECKBOX TEXT */
/* CONTROL TITLE */
/* REGION HEADER */
/* LABEL TEXT */
/* REGION */
/* MENU */
/* DROPDOWN */
/* DROPDOWN-ITEM */
/* ROTATE INPUT */
/* ROTATE BUTTON */
/* BUTTON */
/* BUTTON DEFAULT */
/* BUTTON PRIMARY */
/* BUTTON CTA */
/* BUTTON GHOST */
/* BUTTON LINK */
/* MENU TABS */
.checkbox-control {
  overflow-x: hidden;
}
.checkbox-control .control-container .checkbox .checkbox-label {
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.collapse-button .button-collapse {
  border: none;
  background: none;
  padding: 0;
  margin: 0;
}

.conceptual-picture-modal .modal-content .modal-body {
  font-size: 12px;
  padding: 16px;
}
.conceptual-picture-modal .modal-content .modal-body .sprite {
  margin-left: 200px;
}
.conceptual-picture-modal .modal-content .modal-body .main-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
}
.conceptual-picture-modal .modal-content .modal-body .main-container:last-child {
  margin-bottom: 0;
}
.conceptual-picture-modal .modal-content .modal-body .main-container .title {
  margin: 0 0 16px 0;
  font-weight: 700;
  border-bottom: 1px solid #524f53;
  color: #524f53;
}
.conceptual-picture-modal .modal-content .modal-body .main-container .main-content {
  flex: 1 0 auto;
  margin-bottom: 16px;
}
.conceptual-picture-modal .modal-content .modal-body .details .main-content {
  display: flex;
  margin: 0 -16px;
}
.conceptual-picture-modal .modal-content .modal-body .details .main-content .data-column {
  flex: 1;
  min-width: 0;
  padding: 0px 16px;
}
.conceptual-picture-modal .modal-content .modal-body .details .main-content .data-column .control-row {
  margin-bottom: 16px;
}
.conceptual-picture-modal .modal-content .modal-body .details .main-content .data-column .control-row:last-child {
  margin-bottom: 0;
}

@media screen and (min-width: 992px) {
  .conceptual-picture-modal .modal-content .modal-body .sprite {
    margin-left: 200px;
  }
}
.confirm-change-modal .modal-content .modal-body {
  white-space: pre-line;
  overflow: hidden;
  word-wrap: break-word;
}

.connections-grid {
  display: flex;
  flex-direction: column;
}
.connections-grid .connections-grid-header-cell, .connections-grid .connections-grid-cell {
  overflow: hidden;
  text-overflow: ellipsis;
}
.connections-grid .connections-grid-header-cell.connections-grid-column-image, .connections-grid .connections-grid-cell.connections-grid-column-image {
  flex: 0 0 auto;
  width: 120px;
}
.connections-grid .connections-grid-header-cell.connections-grid-column-name, .connections-grid .connections-grid-cell.connections-grid-column-name {
  flex: 1;
  min-width: 0;
}
.connections-grid .connections-grid-header-cell.connections-grid-column-count, .connections-grid .connections-grid-cell.connections-grid-column-count {
  flex: 0 0 auto;
  width: 128px;
}
.connections-grid .connections-grid-header-cell.connections-grid-column-total, .connections-grid .connections-grid-cell.connections-grid-column-total {
  flex: 0 0 auto;
  width: 128px;
}
.connections-grid .connections-grid-header-cell.connections-grid-column-selection, .connections-grid .connections-grid-cell.connections-grid-column-selection {
  flex: 0 0 auto;
  width: 64px;
}
.connections-grid .connections-grid-header-container {
  background: #e7e5e2;
}
.connections-grid .connections-grid-header-container .connections-grid-header {
  flex: 0 0 auto;
  display: flex;
  color: #524f53;
  font-weight: 700;
}
.connections-grid .connections-grid-header-container .connections-grid-header .connections-grid-header-cell {
  white-space: nowrap;
  padding: 8px 12px;
  border-right: 1px solid #e7e5e2;
}
.connections-grid .connections-grid-header-container .connections-grid-header .connections-grid-header-cell.connections-grid-header-selection {
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: none;
}
.connections-grid .connections-grid-header-container .connections-grid-header .connections-grid-header-cell.connections-grid-header-selection .selection-checkbox {
  margin: 0;
  padding: 0;
}
.connections-grid .connections-grid-rows {
  flex: 1;
  overflow: auto;
}
.connections-grid .connections-grid-rows .connections-grid-row-content {
  display: flex;
  border-bottom: 1px solid #e7e5e2;
}
.connections-grid .connections-grid-rows .connections-grid-row-content.invalid .connections-grid-cell .design-image, .connections-grid .connections-grid-rows .connections-grid-row-content.invalid .connections-grid-cell .count-container, .connections-grid .connections-grid-rows .connections-grid-row-content.invalid .connections-grid-cell .total-mechanical, .connections-grid .connections-grid-rows .connections-grid-row-content.invalid .connections-grid-cell .total-chemical, .connections-grid .connections-grid-rows .connections-grid-row-content.invalid .connections-grid-cell .invalid-volume-container, .connections-grid .connections-grid-rows .connections-grid-row-content.invalid .connections-grid-cell .selection-container, .connections-grid .connections-grid-rows .connections-grid-row-content.invalid .connections-grid-cell .number-of-anchors {
  opacity: 0.3;
}
.connections-grid .connections-grid-rows .connections-grid-row-content.invalid .connections-grid-cell-name .cell-content.bottom {
  margin-bottom: 8px;
}
.connections-grid .connections-grid-rows .connections-grid-row-content .connections-grid-cell {
  padding: 8px 12px;
  border-right: 1px solid #e7e5e2;
}
.connections-grid .connections-grid-rows .connections-grid-row-content .connections-grid-cell.connections-grid-cell-name {
  display: flex;
  padding-right: 0;
}
.connections-grid .connections-grid-rows .connections-grid-row-content .connections-grid-cell.connections-grid-cell-name .name-container {
  flex: 1;
  min-width: 0;
}
.connections-grid .connections-grid-rows .connections-grid-row-content .connections-grid-cell.connections-grid-cell-name .name-container .design-name-container {
  display: flex;
}
.connections-grid .connections-grid-rows .connections-grid-row-content .connections-grid-cell.connections-grid-cell-name .name-container .design-name-container .design-name {
  margin: 0;
  padding: 0;
  border: none;
  background: none;
  color: #887f6e;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
}
.connections-grid .connections-grid-rows .connections-grid-row-content .connections-grid-cell.connections-grid-cell-name .name-container .design-name-container .design-name:hover {
  text-decoration: underline;
}
.connections-grid .connections-grid-rows .connections-grid-row-content .connections-grid-cell.connections-grid-cell-name .name-container .design-name-container .sprite-warning {
  margin-top: -8px;
}
.connections-grid .connections-grid-rows .connections-grid-row-content .connections-grid-cell.connections-grid-cell-name .name-container .anchor-name {
  color: #261d24;
  font-weight: 700;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.connections-grid .connections-grid-rows .connections-grid-row-content .connections-grid-cell.connections-grid-cell-name .name-container .anchor-description {
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.connections-grid .connections-grid-rows .connections-grid-row-content .connections-grid-cell.connections-grid-cell-name .name-container .anchor-description-container {
  display: flex;
  white-space: nowrap;
}
.connections-grid .connections-grid-rows .connections-grid-row-content .connections-grid-cell.connections-grid-cell-name .name-container .anchor-description-container .anchor-number-not-found {
  background-color: #f3f1ec;
  border-style: solid;
  border-width: 1px;
  border-color: #d7cebe;
  cursor: pointer;
  padding-right: 4px;
  display: flex;
  margin-top: 5px;
}
.connections-grid .connections-grid-rows .connections-grid-row-content .connections-grid-cell.connections-grid-cell-name .name-container .anchor-description-container .anchor-description {
  color: #261d24;
}
.connections-grid .connections-grid-rows .connections-grid-row-content .connections-grid-cell.connections-grid-cell-name .name-container .anchor-description-container .anchor-edit {
  padding: 0 16px;
  border: none;
  background: none;
  flex: 0 0 auto;
  margin: 0 2px;
  color: #5b96b8;
  font-weight: bold;
}
.connections-grid .connections-grid-rows .connections-grid-row-content .connections-grid-cell.connections-grid-cell-name .name-container .anchor-description-container .anchor-edit:hover {
  background: #e7e5e2;
}
.connections-grid .connections-grid-rows .connections-grid-row-content .connections-grid-cell.connections-grid-cell-name .name-container .anchor-description-container .anchor-edit.selected {
  background: #cfccc5;
}
.connections-grid .connections-grid-rows .connections-grid-row-content .connections-grid-cell.connections-grid-cell-name .name-container .design-container {
  display: flex;
  margin-right: 12px;
  margin-top: 5px;
}
.connections-grid .connections-grid-rows .connections-grid-row-content .connections-grid-cell.connections-grid-cell-name .name-container .design-container.info {
  background-color: #f3f1ec;
  border: 1px solid #d7cebe;
}
.connections-grid .connections-grid-rows .connections-grid-row-content .connections-grid-cell.connections-grid-cell-name .name-container .design-container.invalid {
  background-color: #ffaf00;
  border: 1px solid #efb24e;
}
.connections-grid .connections-grid-rows .connections-grid-row-content .connections-grid-cell.connections-grid-cell-name .name-container .design-container .design-invalid-description {
  margin: 0;
  padding: 0;
  border: none;
  background: none;
  color: #261d24;
  min-width: 0;
  overflow: hidden;
  max-width: 100%;
  padding: 8px 4px 4px 0;
}
.connections-grid .connections-grid-rows .connections-grid-row-content .connections-grid-cell.connections-grid-cell-name .number-of-anchors-container {
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 0 0 auto;
  width: 75px;
}
.connections-grid .connections-grid-rows .connections-grid-row-content .connections-grid-cell.connections-grid-cell-name .number-of-anchors-container .number-of-anchors {
  text-align: center;
  color: #261d24;
  font-size: 18px;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 8px;
}
.connections-grid .connections-grid-rows .connections-grid-row-content .connections-grid-cell.connections-grid-cell-name .number-of-anchors-container .line-container {
  border-left: 1px solid #ddd;
}
.connections-grid .connections-grid-rows .connections-grid-row-content .connections-grid-cell.connections-grid-cell-total .cell-content.bottom {
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.connections-grid .connections-grid-rows .connections-grid-row-content .connections-grid-cell.connections-grid-cell-total .cell-content.bottom .invalid-volume-container {
  margin-right: -12px;
}
.connections-grid .connections-grid-rows .connections-grid-row-content .connections-grid-cell.connections-grid-cell-total .total-value {
  color: #261d24;
  font-size: 18px;
  font-weight: 700;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-height: 34px;
}
.connections-grid .connections-grid-rows .connections-grid-row-content .connections-grid-cell.connections-grid-cell-selection {
  border-right: none;
}
.connections-grid .connections-grid-rows .connections-grid-row-content .connections-grid-cell.connections-grid-cell-selection .middle {
  align-items: center;
}
.connections-grid .connections-grid-rows .connections-grid-row-content .connections-grid-cell.connections-grid-cell-selection .middle .selection-checkbox {
  margin: 0;
  padding: 0;
}
.connections-grid .connections-grid-rows .connections-grid-row-content .connections-grid-cell .cell-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.connections-grid .connections-grid-rows .connections-grid-row-content .connections-grid-cell .cell-content.top {
  height: 16px;
  margin-bottom: 8px;
}
.connections-grid .connections-grid-rows .connections-grid-row-content .connections-grid-cell .cell-content.middle {
  margin-bottom: 16px;
}
.connections-grid .connections-grid-rows .connections-grid-row-content .connections-grid-cell .cell-content.bottom {
  margin-bottom: 16px;
}
.connections-grid .connections-grid-rows .connections-grid-row-loading {
  background: #f3f1ec;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #261d24;
}

.anchor-number-not-found {
  background-color: #f3f1ec;
  border-style: solid;
  border-width: 1px;
  border-color: #d7cebe;
  cursor: pointer;
  display: flex;
  margin-top: 5px;
}
.anchor-number-not-found .contact-hilti-text {
  margin: 0;
  padding: 0;
  border: none;
  background: none;
  color: #261d24;
  min-width: 0;
  overflow: hidden;
  max-width: 100%;
  padding: 8px 4px 4px 0;
}

.control-tooltip {
  text-align: left;
}
.control-tooltip .title {
  color: #fff;
}
.control-tooltip .content {
  white-space: pre-wrap;
}

.control-tooltip-popup {
  margin: 0 0 4px 4px;
  padding: 0;
  border: none;
  background: transparent;
}

.create-new-bom-modal .form.ng-submitted .dropdown.ng-invalid .button, .create-new-bom-modal .form.ng-submitted .new-project.ng-invalid {
  border-color: #df001b;
}
.create-new-bom-modal .modal-content .modal-body {
  font-size: 12px;
  padding: 16px;
}
.create-new-bom-modal .modal-content .modal-body .main-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
}
.create-new-bom-modal .modal-content .modal-body .main-container:last-child {
  margin-bottom: 0;
}
.create-new-bom-modal .modal-content .modal-body .main-container .title {
  margin: 0 0 16px 0;
  font-weight: 700;
  border-bottom: 1px solid #524f53;
  color: #524f53;
}
.create-new-bom-modal .modal-content .modal-body .main-container .main-content {
  flex: 1 0 auto;
}
.create-new-bom-modal .modal-content .modal-body .general .main-content {
  display: flex;
}
.create-new-bom-modal .modal-content .modal-body .general .main-content .name-text-box {
  flex: 1;
  margin-right: 16px;
}
.create-new-bom-modal .modal-content .modal-body .general .main-content .remarks-text-box {
  flex: 2;
}
.create-new-bom-modal .modal-content .modal-body .general .main-content .input {
  max-width: none;
}
.create-new-bom-modal .modal-table .table-header {
  display: flex;
  border-bottom: 1px solid #e7e5e2;
  border-top: 1px solid #e7e5e2;
  background-color: #e7e5e2;
  color: #524f53;
  font-weight: 700;
}
.create-new-bom-modal .modal-table .table-header > div {
  padding: 8px 16px;
  border-right: 1px solid #e7e5e2;
  flex: 1;
}
.create-new-bom-modal .modal-table .table-header > div:last-child {
  border-right: none;
}
.create-new-bom-modal .modal-table .table-body .table-row {
  display: flex;
  border-bottom: 1px solid #e7e5e2;
}
.create-new-bom-modal .modal-table .table-body .table-row > div {
  padding: 16px;
  border-right: 1px solid #e7e5e2;
  flex: 1;
  display: flex;
  align-items: center;
}
.create-new-bom-modal .modal-table .table-body .table-row > div:last-child {
  border-right: none;
  padding: 8px 16px;
}
.create-new-bom-modal .modal-table .table-body .table-row:last-child {
  border-bottom: none;
}

.design-file-info-modal.default-modal .modal-content .modal-body {
  font-size: 12px;
  padding: 16px;
}
.design-file-info-modal.default-modal .modal-content .modal-body .main-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
}
.design-file-info-modal.default-modal .modal-content .modal-body .main-container:last-child {
  margin-bottom: 0;
}
.design-file-info-modal.default-modal .modal-content .modal-body .main-container .title {
  margin: 0 0 16px 0;
  font-weight: 700;
  border-bottom: 1px solid #524f53;
  color: #524f53;
}
.design-file-info-modal.default-modal .modal-content .modal-body .main-container .main-content {
  flex: 1 0 auto;
  margin-bottom: 16px;
}
.design-file-info-modal.default-modal .modal-content .modal-body .details .details-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.design-file-info-modal.default-modal .modal-content .modal-body .details .main-content {
  display: flex;
  margin: 0 -16px;
}
.design-file-info-modal.default-modal .modal-content .modal-body .details .main-content .data-column {
  flex: 1;
  min-width: 0;
  padding: 0px 16px;
}
.design-file-info-modal.default-modal .modal-content .modal-body .details .main-content .data-column .control-row {
  margin-bottom: 16px;
  display: block;
}
.design-file-info-modal.default-modal .modal-content .modal-body .details .main-content .data-column .control-row:last-child {
  margin-bottom: 0;
}

/* INPUT */
/* RADIO BUTTON TEXT */
/* CHECKBOX TEXT */
/* CONTROL TITLE */
/* REGION HEADER */
/* LABEL TEXT */
/* REGION */
/* MENU */
/* DROPDOWN */
/* DROPDOWN-ITEM */
/* ROTATE INPUT */
/* ROTATE BUTTON */
/* BUTTON */
/* BUTTON DEFAULT */
/* BUTTON PRIMARY */
/* BUTTON CTA */
/* BUTTON GHOST */
/* BUTTON LINK */
/* MENU TABS */
.dropdown .control-container {
  position: relative;
  width: 100%;
}
.dropdown .control-container .item {
  display: flex;
  align-items: center;
  margin-left: -10px;
}
.dropdown .control-container .item .sub-item {
  flex: 0 0 16px;
}
.dropdown .control-container .item .text, .dropdown .control-container .item .image {
  margin: 0 0 0 10px;
  padding: 0;
}
.dropdown .control-container .item .text {
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 50%;
  text-align: left;
  width: 100%;
}
.dropdown .control-container .space {
  flex-grow: 1;
  min-width: 5px;
}
.dropdown .control-container .dropdown-button {
  background: #e4e1df;
  width: 100%;
  padding: 0;
  min-height: 32px;
  display: block;
  overflow: hidden;
  border: 1px solid #bab9ba;
  color: #524f53;
  font-weight: bold;
}
.dropdown .control-container .dropdown-button:focus {
  outline: 0;
}
.dropdown .control-container .dropdown-button[disabled] {
  opacity: 0.5;
  cursor: not-allowed;
}
.dropdown .control-container .dropdown-button[disabled] .caret-container .caret {
  color: #fff;
}
.dropdown .control-container .dropdown-button .button-item {
  position: relative;
  padding: 6px 8px;
  min-width: 0;
  min-height: 30px;
  overflow: hidden;
  width: 100%;
}
.dropdown .control-container .dropdown-button .caret-container {
  display: flex;
  flex-direction: column;
  width: 16px;
  background: #e4e1df;
  display: flex;
  justify-content: center;
  align-self: stretch;
  flex-shrink: 0;
  padding-right: 1px;
}
.dropdown .control-container .dropdown-button .caret-container .caret {
  margin-left: auto;
  margin-right: 20px;
  color: #524f53;
}
.dropdown .control-container .dropdown-button .caret-container .caret-up {
  border-top: none;
  border-bottom: 4px dashed;
  margin-bottom: 2px;
}
.dropdown .control-container .dropdown-button .text {
  white-space: nowrap;
}
.dropdown .control-container .dropdown-button-container {
  display: flex;
  align-items: center;
  position: relative;
  top: 0;
  left: 0;
}
.dropdown .control-container .dropdown-items {
  color: #524f53;
  background: #fff;
  display: none;
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  z-index: 10000;
  max-height: 336px;
  overflow-y: auto;
  overflow-x: hidden;
  text-overflow: ellipsis;
  background-clip: padding-box;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}
.dropdown .control-container .dropdown-items.opend {
  display: block;
}
.dropdown .control-container .dropdown-items.inline {
  padding-left: 5px;
  padding-right: 5px;
}
.dropdown .control-container .dropdown-items.inline .dropdown-item {
  display: inline-block;
  width: auto;
  vertical-align: middle;
}
.dropdown .control-container .dropdown-items .dropdown-item {
  color: #524f53;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  background: none;
}
.dropdown .control-container .dropdown-items .dropdown-item, .dropdown .control-container .dropdown-items .dropdown-item:hover, .dropdown .control-container .dropdown-items .dropdown-item:active {
  outline: none;
}
.dropdown .control-container .dropdown-items .dropdown-item:hover {
  border-width: 2px;
  border-color: #f3f2f0;
  background: #f3f2f0;
}
.dropdown .control-container .dropdown-items .dropdown-item.selected, .dropdown .control-container .dropdown-items .dropdown-item.selected:hover {
  border-width: 2px;
  border-color: #d8d8d3;
  background: #d8d8d3;
}
.dropdown .control-container .dropdown-items .dropdown-item.disabled, .dropdown .control-container .dropdown-items .dropdown-item.disabled:hover {
  cursor: not-allowed;
}
.dropdown .control-container .dropdown-items .dropdown-item .item {
  position: relative;
  padding: 5px 8px;
}
.dropdown .control-container .dropdown-items .dropdown-item .text {
  white-space: pre-line;
  font-weight: bold;
}
.dropdown .control-container .dropdown-items .dropdown-header {
  margin: 5px 0;
  padding: 2px 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  background: #222;
  margin-left: -5px;
  margin-right: -5px;
  color: #9b9b9b;
}

.grid-bom-control {
  height: 785px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
}
.grid-bom-control .grid {
  height: 785px;
}
.grid-bom-control .grid .ui-grid-top-panel {
  background: #f3f3f3;
  color: #111;
}
.grid-bom-control .grid .ui-grid-viewport {
  overflow: auto !important;
}
.grid-bom-control .grid .ui-grid-pager-row-count-label, .grid-bom-control .grid .ui-grid-pager-max-pages-number {
  line-height: 27px;
}
.grid-bom-control .grid .ui-grid-pager-max-pages-number {
  font-size: 10pt;
}
.grid-bom-control .grid .grid-cell {
  text-align: center;
  overflow: visible;
}
.grid-bom-control .grid .grid-cell-checkbox {
  line-height: 58px;
}
.grid-bom-control .grid .grid-cell textarea {
  width: 100%;
  height: 100%;
  min-height: 100%;
  max-height: 100%;
  min-width: 100%;
  max-width: 100%;
  resize: none;
  border: 1px solid #d4d4d4;
}
.grid-bom-control .grid .grid-cell input[type=text] {
  line-height: 58px;
  height: 100%;
  padding-left: 6px;
  padding-right: 6px;
}
.grid-bom-control .grid .grid-cell-label {
  text-align: left;
  padding-left: 6px;
  line-height: 58px;
}
.grid-bom-control .grid .grid-cell-btn {
  line-height: 58px;
}
.grid-bom-control .grid .grid-cell-numeric-label {
  text-align: right;
  padding-right: 6px;
}
.grid-bom-control .grid .grid-cell-numeric input {
  text-align: right;
}
.grid-bom-control .grid .grid-input input {
  max-width: 100% !important;
}

.grid-dropdown .button-item, .grid-dropdown .item {
  margin: 0 16px 0 6px;
}
.grid-dropdown .image, .grid-dropdown .sprite, .grid-dropdown .sprite-responsive {
  max-width: 100px;
}

.grid-bom-details-control {
  width: 100%;
  height: 590px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
}
.grid-bom-details-control .grid {
  height: 580px;
}
.grid-bom-details-control .grid .ui-grid-top-panel {
  background: #f3f3f3;
  color: #111;
}
.grid-bom-details-control .grid .dropdown-toggle {
  padding: 0;
  border: none;
  height: 36px;
}
.grid-bom-details-control .grid .button-item, .grid-bom-details-control .grid .item {
  margin: 0 16px 0 6px;
}
.grid-bom-details-control .grid .image, .grid-bom-details-control .grid .sprite, .grid-bom-details-control .grid .sprite-responsive {
  max-width: 100px;
}
.grid-bom-details-control .grid .orderAmount-cell-label {
  font-weight: bold;
  margin-right: 2px;
  margin-left: 2px;
}
.grid-bom-details-control .grid .ui-grid-viewport {
  overflow: auto !important;
}
.grid-bom-details-control .grid .ui-grid-pager-row-count-label, .grid-bom-details-control .grid .ui-grid-pager-max-pages-number {
  line-height: 27px;
}
.grid-bom-details-control .grid .ui-grid-pager-max-pages-number {
  font-size: 10pt;
}
.grid-bom-details-control .grid .grid-cell {
  text-align: center;
  overflow: visible;
}
.grid-bom-details-control .grid .grid-cell-checkbox {
  padding-top: 8px;
}
.grid-bom-details-control .grid .grid-cell-text {
  padding-top: 8px;
}
.grid-bom-details-control .grid .grid-cell-label {
  padding-top: 8px;
}
.grid-bom-details-control .grid .grid-cell-numeric-label {
  text-align: right;
  padding-right: 6px;
  padding-top: 8px;
}
.grid-bom-details-control .grid .grid-cell-numeric .round-button {
  width: 18px;
  margin-left: -12px;
}
.grid-bom-details-control .grid .grid-cell-numeric .round-button-circle {
  width: 18px;
  height: 0;
  padding-bottom: 100%;
  border-radius: 50%;
  overflow: hidden;
  background: #808080;
  color: #ffffff;
  text-align: center;
  padding-left: 2px;
  font-weight: bold;
  cursor: pointer;
}
.grid-bom-details-control .grid .grid-cell-numeric input {
  text-align: right;
}
.grid-bom-details-control .grid .grid-input input {
  max-width: 100% !important;
}

.grid-dropdown .button-item, .grid-dropdown .item {
  margin: 0 16px 0 6px;
}
.grid-dropdown .image, .grid-dropdown .sprite, .grid-dropdown .sprite-responsive {
  max-width: 100px;
}

.group-control .controls {
  display: flex;
  align-items: center;
}
.group-control .controls > .control {
  display: flex;
  align-items: center;
  margin-right: 10px;
}
.group-control .controls > .control:last-child {
  margin-right: 0;
  flex: 1;
}
.group-control .controls > .control .control-header {
  margin-bottom: 0;
  margin-right: 10px;
}
.group-control .controls > .control .control-header .control-label {
  min-width: 20px;
  color: #261d24;
}

.hilti-styled-control-button .hilti-styled-control-container .hilti-styled-button {
  max-width: 100%;
}
.hilti-styled-control-button .hilti-styled-control-container .hilti-styled-button.hilti-styled-size-full {
  width: 100%;
}

.hilti-styled-button {
  min-height: 45px;
  min-width: 96px;
  padding: 4px 10px;
  border-radius: 0;
  white-space: normal;
  margin: 0;
  outline: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: 700;
}
.hilti-styled-button[disabled] {
  cursor: not-allowed;
  opacity: 0.75;
}
.hilti-styled-button .hilti-styled-image-container .hilti-styled-image {
  margin: 0 auto;
}
.hilti-styled-button .hilti-styled-text {
  white-space: pre-line;
  overflow: hidden;
  text-overflow: ellipsis;
}

.hilti-styled-button-primary {
  background: #d2051e;
  border: 0;
  color: #ffffff;
  transition: background-color ease 0.3s;
}
.hilti-styled-button-primary:hover:not([disabled]) {
  background: #671a3d;
}
.hilti-styled-button-primary:active:not([disabled]) {
  transform: scale(0.97);
}

.hilti-styled-button-secondary {
  background: none;
  border: 3px solid #d2051e;
  color: #d2051e;
  transition: border-color ease 0.3s;
}
.hilti-styled-button-secondary:hover:not([disabled]) {
  border-color: #671a3d;
  color: #671a3d;
}
.hilti-styled-button-secondary:active:not([disabled]) {
  transform: scale(0.97);
}

.hilti-styled-button-gray {
  background: #413c41;
  border: 0;
  color: #fff;
  transition: background-color ease 0.3s;
}
.hilti-styled-button-gray:hover:not([disabled]) {
  background: #524f53;
}
.hilti-styled-button-gray:active:not([disabled]) {
  transform: scale(0.97);
}

.license-comparison-modal.base-modal .modal-full-screen,
.license-comparison-modal.base-modal .modal-content {
  display: block;
  min-height: 100%;
  min-width: 100%;
  border: 0;
  margin: 0;
  padding: 0;
  background: #f3f0eb;
  box-shadow: none;
}
.license-comparison-modal.base-modal .modal-content .section {
  display: flex;
  flex-direction: column;
}
.license-comparison-modal.base-modal .modal-content .modal-body {
  padding: 0;
}
.license-comparison-modal.base-modal .modal-content .modal-body .section {
  display: flex;
  flex-direction: column;
}
.license-comparison-modal.base-modal .modal-content .modal-body button {
  width: 220px;
  display: inline-block;
}
.license-comparison-modal.base-modal .modal-content .modal-body .modal-body-header {
  width: 100%;
  padding: 0 0 0 4%;
  float: left;
  background: #ffffff;
}
.license-comparison-modal.base-modal .modal-content .modal-body .modal-body-header .trial-header-img {
  float: right;
}
.license-comparison-modal.base-modal .modal-content .modal-body .modal-body-header .header-text {
  position: absolute;
  width: 60%;
  float: left;
  padding: 20px 0;
  display: flex;
  flex-direction: column;
}
.license-comparison-modal.base-modal .modal-content .modal-body .modal-body-header .header-text .body-header-title {
  color: #d2051e;
  font-size: 32px;
  white-space: pre-line;
}
.license-comparison-modal.base-modal .modal-content .modal-body .modal-body-header .header-text .body-header-sub-title {
  color: #671a3d;
  font-size: 24px;
  font-weight: bold;
  height: 70px;
}
.license-comparison-modal.base-modal .modal-content .modal-body .modal-body-header .header-text .body-header-Text {
  font-size: 16px;
  font-weight: bold;
  margin: 10px 0 0 0;
}
.license-comparison-modal.base-modal .modal-content .modal-body .modal-body-header .header-text .space {
  margin: 30px 0;
}
.license-comparison-modal.base-modal .modal-content .modal-body .modal-body-header .header-text .small-space {
  margin: 15px 0;
}
.license-comparison-modal.base-modal .modal-content .modal-body .modal-body-header .header-text .start-trial {
  display: flex;
}
.license-comparison-modal.base-modal .modal-content .modal-body .trial-info {
  padding: 0 4%;
  margin: 30px 0;
}
.license-comparison-modal.base-modal .modal-content .modal-body .trial-info .trial-info-title {
  color: #d2051e;
  font-size: 20px;
}
.license-comparison-modal.base-modal .modal-content .modal-body .trial-info .trial-info-text {
  font-size: 18px;
}
.license-comparison-modal.base-modal .modal-content .modal-body .trial-info .start-trial {
  padding: 0 10%;
  display: block;
  width: 100%;
}
.license-comparison-modal.base-modal .modal-content .modal-body .trial-info .start-trial button {
  display: block;
  float: right;
  width: unset;
  padding: 14px;
  font-size: 14px;
}
.license-comparison-modal.base-modal .modal-content .modal-body .trial-info .start-trial button#buy-now-button {
  margin-left: 30px;
}

.control-body {
  padding: 0;
  background: #f3f0eb;
  height: 100%;
}
.control-body .section {
  display: flex;
  flex-direction: column;
}
.control-body .table {
  padding: 0 10%;
  margin: 20px 0;
}
.control-body .table table {
  width: 100%;
}
.control-body .table tr {
  height: 60px;
  border-bottom: solid 1px #d7ceba;
  background: #ffffff;
}
.control-body .table tr.header {
  background: #f3f0eb;
}
.control-body .table td {
  background: #ffffff;
  font-size: 16px;
  margin: auto;
}
.control-body .table th {
  text-align: center;
  font-size: 16px;
  margin: auto;
}
.control-body .table th .free-text {
  font-size: 12px;
}
.control-body .table th.standard-premium {
  padding: 0 5px;
}
.control-body .table td.info {
  padding: 0 5px;
}
.control-body .table td.standard-premium {
  text-align: center;
}
.control-body .table td.standard-premium span.sprite {
  margin: auto;
}

.trial-banner-modal.base-modal .modal-full-screen,
.trial-banner-modal.base-modal .modal-content {
  width: 100% !important;
  border: 0;
  margin: 24px 0 0 0;
  padding: 0;
}
.trial-banner-modal.base-modal .modal-content .modal-body {
  padding: 0;
  background: #ebe6de;
  height: 100%;
}
.trial-banner-modal.base-modal .modal-content .modal-body .modal-body-header {
  background: #d7ceba;
  display: flex;
}
.trial-banner-modal.base-modal .modal-content .modal-body .modal-body-header .header-text {
  flex: 1;
  padding: 20px 40px 20px 80px;
  font-size: 24px;
  font-weight: bold;
  color: #d2051e;
}
.trial-banner-modal.base-modal .modal-content .modal-body .modal-body-header .header-text .red {
  color: #d2051e;
}
.trial-banner-modal.base-modal .modal-content .modal-body .modal-body-header .header-text .white {
  color: #ffffff;
}
.trial-banner-modal.base-modal .modal-content .modal-body .modal-body-header.grape {
  background: #671a3d;
}
.trial-banner-modal.base-modal .modal-content .modal-body .modal-body-header.grape .close {
  color: #ffffff;
}
.trial-banner-modal.base-modal .modal-content .modal-body .modal-body-header .close {
  font-size: 36px;
  color: #d2051e;
  opacity: 1;
}
.trial-banner-modal.base-modal .modal-content .modal-body .license-info {
  display: flex;
  flex-direction: column;
  padding: 30px 70px 40px 80px;
}
.trial-banner-modal.base-modal .modal-content .modal-body .license-info .section-title {
  font-size: 18px;
  font-weight: bold;
  padding-bottom: 20px;
  position: relative;
}
.trial-banner-modal.base-modal .modal-content .modal-body .license-info .section-title .red {
  color: #d2051e;
}
.trial-banner-modal.base-modal .modal-content .modal-body .license-info .section-title .days-left {
  background: #d7ceba;
  color: #d2051e;
  padding: 10px;
}
.trial-banner-modal.base-modal .modal-content .modal-body .license-info .info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.trial-banner-modal.base-modal .modal-content .modal-body .license-info .info .info-text {
  width: 50%;
  font-size: 16px;
}
.trial-banner-modal.base-modal .modal-content .modal-body .license-info .info .info-text .body-header-title {
  margin-right: 70px;
  margin-bottom: unset;
}
.trial-banner-modal.base-modal .modal-content .modal-body .license-info .info .info-text .click-here {
  background: none;
  border: none;
  font-weight: bold;
  color: #d2051e;
}
.trial-banner-modal.base-modal .modal-content .modal-body .license-info .info .info-text button {
  padding: unset;
}
.trial-banner-modal.base-modal .modal-content .modal-body .license-info .info .info-button {
  display: flex;
  justify-content: center;
  align-items: center;
}
.trial-banner-modal.base-modal .modal-content .modal-body .license-info .info .info-button button {
  display: block;
  float: right;
  font-size: 16px;
  padding: 12px 30px;
}
.trial-banner-modal.base-modal .modal-content .modal-body .license-info .info .info-button button > div.hilti-styled-text {
  overflow: unset;
  text-overflow: unset;
  white-space: nowrap;
}
.trial-banner-modal.base-modal .modal-content .modal-body .license-info .info .info-button button#purchase-button {
  padding: 15px 30px;
  margin-left: 50px;
}
.trial-banner-modal.base-modal .modal-content .modal-body .license-info .section-footer {
  position: relative;
  display: block;
  float: left;
  width: 100%;
  clear: both;
  padding-top: 60px;
  font-weight: 700;
}
.trial-banner-modal.base-modal .modal-content .modal-body .license-info .section-footer .section-logout {
  font-size: 18px;
  padding: 4px 4px 4px 0;
  cursor: pointer;
  border-bottom-style: solid;
  border-bottom-width: 2px;
  border-bottom-color: black;
  -webkit-transition: color 0.3s, border-bottom-color 0.3s;
  transition: color 0.3s, border-bottom-color 0.3s;
}
.trial-banner-modal.base-modal .modal-content .modal-body .license-info .section-footer .section-logout:hover {
  color: #d2051e;
  border-bottom-color: #d2051e;
}

/* INPUT */
/* RADIO BUTTON TEXT */
/* CHECKBOX TEXT */
/* CONTROL TITLE */
/* REGION HEADER */
/* LABEL TEXT */
/* REGION */
/* MENU */
/* DROPDOWN */
/* DROPDOWN-ITEM */
/* ROTATE INPUT */
/* ROTATE BUTTON */
/* BUTTON */
/* BUTTON DEFAULT */
/* BUTTON PRIMARY */
/* BUTTON CTA */
/* BUTTON GHOST */
/* BUTTON LINK */
/* MENU TABS */
.label-control {
  overflow-x: hidden;
}
.label-control .text-label {
  color: #261d24;
  margin: 0;
}

.loading .bar {
  display: flex;
  flex-direction: row;
  position: relative;
}
.loading .bar .fill {
  flex: 1;
  height: 100%;
  transform: skew(-30deg, 0deg);
  opacity: 0.1;
}
.loading .bar .space {
  flex: 0.6;
  height: 100%;
  transform: skew(-30deg, 0deg);
}
.loading .bar {
  width: 32px;
  height: 8px;
}
.loading .bar .fill-1 {
  animation: fade 1s 0s infinite linear;
  background-color: #fff;
  border: 1px solid #fafafa;
}
.loading .bar .fill-2 {
  animation: fade 1s 0.1s infinite linear;
  background-color: #fafafa;
}
.loading .bar .fill-3 {
  animation: fade 1s 0.2s infinite linear;
  background-color: #d7cebe;
}
.loading .bar .fill-4 {
  animation: fade 1s 0.3s infinite linear;
  background-color: #524f53;
}
.loading .bar .fill-5 {
  animation: fade 1s 0.4s infinite linear;
  background-color: #d2051e;
}

@keyframes fade {
  0% {
    opacity: 0.1;
  }
  10% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  60% {
    opacity: 0.1;
  }
  100% {
    opacity: 0.1;
  }
}
.default-modal .modal-content {
  border-radius: 0;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.5);
}
.default-modal .modal-content .modal-header {
  height: 48px;
  display: flex;
  align-items: center;
  background: #fff;
  padding: 0 16px;
  border-bottom: 1px solid #bab9ba;
  z-index: 1;
  position: relative;
  flex-direction: row;
}
.default-modal .modal-content .modal-header .modal-title {
  flex: 1 1 auto;
  margin-right: 16px;
  font-size: 12px;
  color: #524f53;
  font-weight: 700;
  text-transform: uppercase;
  text-overflow: ellipsis;
  overflow-x: hidden;
  white-space: nowrap;
}
.default-modal .modal-content .modal-header .close {
  margin-top: -8px;
  font-size: 26px;
  color: #524f53;
  text-shadow: none;
  opacity: 1;
  font-family: hilti-wide, Arial, sans-serif;
}
.default-modal .modal-content .modal-header .close:not([disabled]):hover {
  opacity: 1;
  color: #413c41;
}
.default-modal .modal-content .modal-header .close[disabled] {
  cursor: not-allowed;
}
.default-modal .modal-content .modal-footer {
  height: 48px;
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-align: left;
  background: #fff;
  border-top: 1px solid #bab9ba;
  z-index: 1;
  position: relative;
}
.default-modal .modal-content .modal-footer .modal-button:not(:first-child) {
  margin-left: 8px;
}
.default-modal .modal-content .modal-body {
  font-size: 12px;
  padding: 16px;
}
.default-modal .modal-content .modal-body .section, .default-modal .modal-content .modal-body.section {
  margin-bottom: 32px;
}
.default-modal .modal-content .modal-body .section:last-child, .default-modal .modal-content .modal-body.section:last-child {
  margin-bottom: 0;
}
.default-modal .modal-content .modal-body .section .control-row, .default-modal .modal-content .modal-body.section .control-row {
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
}
.default-modal .modal-content .modal-body .section .control-row:last-child, .default-modal .modal-content .modal-body.section .control-row:last-child {
  margin-bottom: 0;
}
.default-modal .modal-content .modal-body .section .control-row .input, .default-modal .modal-content .modal-body.section .control-row .input {
  width: 100%;
}
.default-modal .modal-content .modal-body .section .control-row.multiple, .default-modal .modal-content .modal-body.section .control-row.multiple {
  flex-direction: row;
}
.default-modal .modal-content .modal-body .section .control-row.multiple .control-column, .default-modal .modal-content .modal-body.section .control-row.multiple .control-column {
  flex: 1;
  min-width: 0;
  margin-right: 16px;
  display: flex;
  flex-direction: column;
}
.default-modal .modal-content .modal-body .section .control-row.multiple .control-column:last-child, .default-modal .modal-content .modal-body.section .control-row.multiple .control-column:last-child {
  margin-right: 0;
}
.default-modal .modal-content .modal-body .section .section-row, .default-modal .modal-content .modal-body.section .section-row {
  display: flex;
}
.default-modal .modal-content .modal-body .section .section-row .section-column, .default-modal .modal-content .modal-body.section .section-row .section-column {
  flex: 1;
  min-width: 0;
  padding: 0 16px;
}
.default-modal .modal-content .modal-body .section .section-row .section-column:first-child, .default-modal .modal-content .modal-body.section .section-row .section-column:first-child {
  padding-left: 0;
}
.default-modal .modal-content .modal-body .section .section-row .section-column:last-child, .default-modal .modal-content .modal-body.section .section-row .section-column:last-child {
  padding-right: 0;
}
.default-modal .modal-content .modal-body .section .section-row .section-column:last-child.separator, .default-modal .modal-content .modal-body.section .section-row .section-column:last-child.separator {
  border-right: none;
}
.default-modal .modal-content .modal-body .section .section-row .section-column.separator, .default-modal .modal-content .modal-body.section .section-row .section-column.separator {
  margin-top: -16px;
  padding-top: 16px;
  margin-bottom: -16px;
  padding-bottom: 16px;
  border-right: 1px solid #ccc;
}
.default-modal .modal-content .modal-body .section .section-row .size2, .default-modal .modal-content .modal-body.section .section-row .size2 {
  flex: 2 !important;
}
.default-modal .modal-content .modal-body .section .section-row .size3, .default-modal .modal-content .modal-body.section .section-row .size3 {
  flex: 3 !important;
}

.modal-open .modal {
  overflow: auto;
  text-align: center;
}
.modal-open .modal .modal-dialog {
  margin: 30px 0;
  display: inline-block;
  text-align: left;
  width: 480px;
}
.modal-open .modal .modal-xl {
  width: 1280px;
  max-width: calc(100vw - 60px);
  min-width: 880px;
}
.modal-open .modal .modal-lg {
  width: 800px;
}
.modal-open .modal .modal-sm {
  width: 300px;
}
.modal-open .modal .modal-auto {
  width: auto;
}

.popup-modal.default-modal .modal-content .modal-header .modal-title {
  flex: 0 1 auto;
  margin-right: 0;
}
.popup-modal.default-modal .modal-content .modal-header .info-container {
  flex: 1;
  margin-right: 16px;
}
.popup-modal.default-modal .modal-content .modal-header .info-container .info-button {
  display: block;
  padding: 0;
  background: none;
  border: none;
  margin: 0 0 0 16px;
}
.popup-modal.default-modal .modal-content .modal-body {
  padding: 0;
}
.popup-modal.default-modal .modal-content .modal-body .popup-table {
  width: 100%;
}
.popup-modal.default-modal .modal-content .modal-body .popup-table .table-cell {
  padding: 16px;
  flex-basis: 0;
  flex-grow: 1;
}
.popup-modal.default-modal .modal-content .modal-body .popup-table .table-header .table-row {
  color: #524f53;
  font-weight: 700;
  display: flex;
  align-items: center;
  background: #e7e5e2;
}
.popup-modal.default-modal .modal-content .modal-body .popup-table .table-body {
  overflow-y: auto;
  max-height: 0;
}
.popup-modal.default-modal .modal-content .modal-body .popup-table .table-body .table-row {
  border-bottom: 1px solid #e7e5e2;
  display: flex;
  align-items: center;
}
.popup-modal.default-modal .modal-content .modal-body .popup-table .table-body .table-row:nth-last-child(2) {
  border-bottom: none;
}

/* INPUT */
/* RADIO BUTTON TEXT */
/* CHECKBOX TEXT */
/* CONTROL TITLE */
/* REGION HEADER */
/* LABEL TEXT */
/* REGION */
/* MENU */
/* DROPDOWN */
/* DROPDOWN-ITEM */
/* ROTATE INPUT */
/* ROTATE BUTTON */
/* BUTTON */
/* BUTTON DEFAULT */
/* BUTTON PRIMARY */
/* BUTTON CTA */
/* BUTTON GHOST */
/* BUTTON LINK */
/* MENU TABS */
.radio-button-control {
  overflow-x: hidden;
}
.radio-button-control .control-container .radio .text {
  overflow-x: hidden;
  text-overflow: ellipsis;
}

/* INPUT */
/* RADIO BUTTON TEXT */
/* CHECKBOX TEXT */
/* CONTROL TITLE */
/* REGION HEADER */
/* LABEL TEXT */
/* REGION */
/* MENU */
/* DROPDOWN */
/* DROPDOWN-ITEM */
/* ROTATE INPUT */
/* ROTATE BUTTON */
/* BUTTON */
/* BUTTON DEFAULT */
/* BUTTON PRIMARY */
/* BUTTON CTA */
/* BUTTON GHOST */
/* BUTTON LINK */
/* MENU TABS */
.region > .title-container {
  display: flex;
  align-items: center;
  padding: 0 0 0 8px;
  height: auto;
}
.region > .title-container .drag-handle {
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
  margin: 7px 0;
}
.region > .title-container .drag-handle .title {
  overflow-x: hidden;
  text-overflow: ellipsis;
  margin: 0 4px 0 0;
  background: none;
}
.region > .title-container .favorite {
  border: none;
  background: none;
  padding: 0;
}
.region > .title-container .region-collapse {
  border: none;
  background: none;
  padding: 0;
}
.region > .controls {
  display: flex;
  flex-wrap: wrap;
  margin-left: -16px;
  padding: 4px 16px;
}
.region > .controls > .control {
  width: 50%;
  padding: 4px 0 4px 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.region > .controls > .control.react-label-control {
  justify-content: flex-start;
}
.region > .controls > .control.size-100 {
  width: 100%;
}

/* INPUT */
/* RADIO BUTTON TEXT */
/* CHECKBOX TEXT */
/* CONTROL TITLE */
/* REGION HEADER */
/* LABEL TEXT */
/* REGION */
/* MENU */
/* DROPDOWN */
/* DROPDOWN-ITEM */
/* ROTATE INPUT */
/* ROTATE BUTTON */
/* BUTTON */
/* BUTTON DEFAULT */
/* BUTTON PRIMARY */
/* BUTTON CTA */
/* BUTTON GHOST */
/* BUTTON LINK */
/* MENU TABS */
.select-region-language-modal.default-modal .modal-content .modal-body {
  padding: 0;
  font-size: 12px;
}
.select-region-language-modal.default-modal .modal-content .modal-body .content {
  padding: 16px;
}
.select-region-language-modal.default-modal .modal-content .modal-body .content .message {
  margin: 0;
}
.select-region-language-modal.default-modal .modal-content .modal-body .content .bottom-dropdown-container {
  padding: 16px 0px;
}
.select-region-language-modal.default-modal .modal-content .modal-body .content .button-container {
  display: flex;
  justify-content: flex-end;
}
.select-region-language-modal.default-modal .modal-content .modal-body .options {
  display: flex;
  flex-direction: column;
}
.select-region-language-modal.default-modal .modal-content .modal-body .options .option {
  display: flex;
  align-items: center;
  border-top: 1px solid #ccc;
  cursor: pointer;
}
.select-region-language-modal.default-modal .modal-content .modal-body .options .option, .select-region-language-modal.default-modal .modal-content .modal-body .options .option:hover, .select-region-language-modal.default-modal .modal-content .modal-body .options .option:active, .select-region-language-modal.default-modal .modal-content .modal-body .options .option:visited, .select-region-language-modal.default-modal .modal-content .modal-body .options .option:focus {
  text-decoration: none;
}
.select-region-language-modal.default-modal .modal-content .modal-body .options .option .image-container {
  flex: 0 0 62px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #ccc;
}
.select-region-language-modal.default-modal .modal-content .modal-body .options .option .name-container {
  flex: 1;
  height: 48px;
  display: flex;
  align-items: center;
}
.select-region-language-modal.default-modal .modal-content .modal-body .options .option .name-container .name {
  margin: 0 0 0 16px;
}
.select-region-language-modal.default-modal .modal-content .modal-body .options .option .name-container .space {
  flex: 1;
}
.select-region-language-modal.default-modal .modal-content .modal-body .options .option .name-container .arrow {
  margin: 0 16px 0 0;
}
.select-region-language-modal.default-modal .modal-content .modal-body .options .option:disabled {
  cursor: not-allowed;
}

.share-project-modal.default-modal .modal-content .modal-body {
  padding: 0;
  margin: 0;
}
.share-project-modal.default-modal .modal-content .modal-body .section {
  padding: 16px;
  margin-bottom: 0;
}
.share-project-modal.default-modal .modal-content .modal-body .section .section-inline {
  display: flex;
}
.share-project-modal.default-modal .modal-content .modal-body .section .section-inline .control-row {
  flex: 1 0 75%;
}
.share-project-modal.default-modal .modal-content .modal-body .section .section-inline .share-project-add-button {
  flex: 1 0 25%;
  margin-top: 21px;
  justify-content: flex-end;
  display: flex;
  margin-bottom: 16px;
}
.share-project-modal.default-modal .modal-content .modal-body .table-share-project {
  width: 100%;
}
.share-project-modal.default-modal .modal-content .modal-body .table-share-project tr {
  border-bottom: 1px solid #ddd;
}
.share-project-modal.default-modal .modal-content .modal-body .table-share-project .table-share-project-row {
  height: 34px;
}
.share-project-modal.default-modal .modal-content .modal-body .table-share-project .table-share-project-row td {
  text-align: left;
}
.share-project-modal.default-modal .modal-content .modal-body .table-share-project .table-share-project-row td:nth-child(1) {
  width: 75%;
  padding-left: 16px;
  padding-right: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;
  max-width: 0;
}
.share-project-modal.default-modal .modal-content .modal-body .table-share-project .table-share-project-row td:nth-child(3) {
  height: 32px;
  vertical-align: middle;
}
.share-project-modal.default-modal .modal-content .modal-body .table-share-project .table-share-project-row td:nth-child(3) .action-buttons {
  display: inline-table;
  width: 100%;
  height: 32px;
  vertical-align: middle;
  padding-right: 2px;
}
.share-project-modal.default-modal .modal-content .modal-body .table-share-project .table-share-project-row td:nth-child(3) .action-buttons button {
  background: none;
  border: 0;
  display: inline-block;
  float: right;
}
.share-project-modal.default-modal .modal-content .modal-body .table-share-project .table-share-project-row td:nth-child(3) .action-buttons .button-delete {
  margin: 0 2px 0 1px;
}
.share-project-modal.default-modal .modal-content .modal-body .table-share-project .table-share-project-row-header {
  color: #524f53;
  background: #ccc;
  height: 39px;
  margin-left: 20px;
  margin-right: 20px;
}
.share-project-modal.default-modal .modal-content .modal-body .table-share-project .table-share-project-row-header th {
  text-align: left;
}
.share-project-modal.default-modal .modal-content .modal-body .table-share-project .table-share-project-row-header th:nth-child(1) {
  width: 33.33%;
  padding-left: 16px;
}
.share-project-modal.default-modal .modal-content .modal-body .table-share-project .table-share-project-row-header th:nth-child(2) {
  width: 33.33%;
}
.share-project-modal.default-modal .modal-content .modal-body .table-share-project .table-share-project-row-header th:nth-child(3) {
  width: 33.33%;
  padding-right: 16px;
  text-align: right;
}

.support-modal.default-modal .modal-content .modal-body .support-message {
  width: 100%;
  min-height: 100px;
  resize: vertical;
}
.support-modal.default-modal .modal-content .modal-body .notification-support {
  background: #f3f1ec;
  color: #261d24;
  padding: 8px;
  margin-bottom: 16px;
}
.support-modal.default-modal .modal-content .modal-body .notification-support p {
  display: flex;
  align-items: flex-start;
  margin-bottom: 4px;
}
.support-modal.default-modal .modal-content .modal-body .notification-support p:last-child {
  margin-bottom: 0;
}
.support-modal.default-modal .modal-content .modal-body .notification-support .support-user-manual {
  display: flex;
  margin-bottom: 4px;
}
.support-modal.default-modal .modal-content .modal-body .notification-support .support-user-manual .link-wrapper {
  padding-left: 4px;
}
.support-modal.default-modal .modal-content .modal-body .notification-support .support-user-manual:last-child {
  margin-bottom: 0;
}
.support-modal.default-modal .modal-content .modal-body .notification-support .support-info-header {
  font-weight: 700;
}
.support-modal.default-modal .modal-content .modal-body .additional-data-support {
  background: #f3f1ec;
  color: #261d24;
  padding: 8px;
  margin-bottom: 16px;
}
.support-modal.default-modal .modal-content .modal-body .additional-data-support p {
  display: flex;
  align-items: flex-start;
  margin-bottom: 4px;
}
.support-modal.default-modal .modal-content .modal-body .additional-data-support p:last-child {
  margin-bottom: 0;
}
.support-modal.default-modal .modal-content .modal-body .additional-data-support .support-additional-data-header {
  font-weight: 700;
}
.support-modal.default-modal .modal-content .modal-body .notification-faq {
  background: #d8d8d3;
  color: #261d24;
  padding: 8px;
  margin-bottom: 10px;
}
.support-modal.default-modal .modal-content .modal-body .notification-faq .faq-info-header {
  font-weight: 700;
}
.support-modal.default-modal .modal-content .modal-body .notification-faq .faq-info {
  display: inline;
}

/* INPUT */
/* RADIO BUTTON TEXT */
/* CHECKBOX TEXT */
/* CONTROL TITLE */
/* REGION HEADER */
/* LABEL TEXT */
/* REGION */
/* MENU */
/* DROPDOWN */
/* DROPDOWN-ITEM */
/* ROTATE INPUT */
/* ROTATE BUTTON */
/* BUTTON */
/* BUTTON DEFAULT */
/* BUTTON PRIMARY */
/* BUTTON CTA */
/* BUTTON GHOST */
/* BUTTON LINK */
/* MENU TABS */
.text-box .text-box-container {
  flex: 1 0 auto;
  display: flex;
}
.text-box .text-box-container .input-container {
  position: relative;
  flex: 1;
}
.text-box .text-box-container .input-container .input {
  width: 100%;
}
.text-box .text-box-container .input-container .input.postfix {
  position: absolute;
  background: white;
  box-shadow: none;
  border-color: transparent;
}
.text-box .text-box-container .input-container .input.postfix[disabled] {
  background: white;
  box-shadow: none;
  border-color: transparent;
}
.text-box .text-box-container .input-container .input.data {
  position: relative;
}
.text-box .custom-error {
  color: red;
}

.unauthorized-access-modal .modal-content .modal-body {
  white-space: pre-line;
  overflow: hidden;
  word-wrap: break-word;
}

/* INPUT */
/* RADIO BUTTON TEXT */
/* CHECKBOX TEXT */
/* CONTROL TITLE */
/* REGION HEADER */
/* LABEL TEXT */
/* REGION */
/* MENU */
/* DROPDOWN */
/* DROPDOWN-ITEM */
/* ROTATE INPUT */
/* ROTATE BUTTON */
/* BUTTON */
/* BUTTON DEFAULT */
/* BUTTON PRIMARY */
/* BUTTON CTA */
/* BUTTON GHOST */
/* BUTTON LINK */
/* MENU TABS */
.user-agreement-modal.default-modal .modal-content .modal-body {
  padding: 0;
  height: 200px;
  text-align: center;
}
.user-agreement-modal.default-modal .modal-content .modal-body .content {
  padding: 16px;
  overflow-x: hidden;
  overflow-y: scroll;
  max-height: 500px;
}
.user-agreement-modal.default-modal .modal-content .modal-body .content .message {
  margin: 0;
}
.user-agreement-modal.default-modal .modal-content .modal-body .user-agreement-title {
  color: #d2051e;
  font-size: 22px;
  margin: 16px 0 8px 0;
}
.user-agreement-modal.default-modal .modal-content .modal-body .user-agreement-sub-title {
  color: #524f53;
  font-size: 16px;
  margin: 0;
}
.user-agreement-modal.default-modal .modal-content .modal-body .open-document,
.user-agreement-modal.default-modal .modal-content .modal-body .open-document:hover,
.user-agreement-modal.default-modal .modal-content .modal-body .open-document:visited,
.user-agreement-modal.default-modal .modal-content .modal-body .open-document:active {
  text-decoration: none;
  font-size: 16px;
  color: #111;
  padding: 0;
  display: inline-flex;
  align-items: center;
  margin: 48px 0 0 0;
  margin-left: 32px;
  font-weight: 700;
}
.user-agreement-modal.default-modal .modal-content .modal-body .open-document:hover,
.user-agreement-modal.default-modal .modal-content .modal-body .open-document:hover:hover,
.user-agreement-modal.default-modal .modal-content .modal-body .open-document:visited:hover,
.user-agreement-modal.default-modal .modal-content .modal-body .open-document:active:hover {
  text-decoration: underline;
}
.user-agreement-modal.default-modal .modal-content .modal-footer {
  justify-content: space-between;
  height: auto;
  align-items: flex-end;
}
.user-agreement-modal.default-modal .modal-content .modal-footer .checkboxes-agreement {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  flex: 1;
}
.user-agreement-modal.default-modal .modal-content .modal-footer .checkboxes-agreement .checkbox-with-link {
  display: flex;
  flex-direction: row;
}
.user-agreement-modal.default-modal .modal-content .modal-footer .checkboxes-agreement .checkbox-with-link .checkbox {
  align-items: flex-start;
  margin-bottom: 16px;
}
.user-agreement-modal.default-modal .modal-content .modal-footer .checkboxes-agreement .checkbox-with-link .checkbox-link {
  color: #d2051e;
  cursor: pointer;
  margin-left: 5px;
}
.user-agreement-modal.default-modal .modal-content .modal-footer .checkboxes-agreement .checkbox-with-link .checkbox-link:hover {
  text-decoration: underline;
}
.user-agreement-modal.default-modal .modal-content .modal-footer .checkboxes-agreement .checkbox-with-link .checkbox-after-link {
  color: #261d24;
  margin-left: 5px;
}
.user-agreement-modal.default-modal .modal-content .modal-footer .checkboxes-agreement .checkbox {
  align-items: flex-start;
  margin-bottom: 8px;
}
.user-agreement-modal.default-modal .modal-content .modal-footer .buttonContainer {
  margin-left: auto;
}
.user-agreement-modal.default-modal .modal-content .modal-footer .modal-button {
  margin: 0px 0px 15px 20px;
}

/* INPUT */
/* RADIO BUTTON TEXT */
/* CHECKBOX TEXT */
/* CONTROL TITLE */
/* REGION HEADER */
/* LABEL TEXT */
/* REGION */
/* MENU */
/* DROPDOWN */
/* DROPDOWN-ITEM */
/* ROTATE INPUT */
/* ROTATE BUTTON */
/* BUTTON */
/* BUTTON DEFAULT */
/* BUTTON PRIMARY */
/* BUTTON CTA */
/* BUTTON GHOST */
/* BUTTON LINK */
/* MENU TABS */
.user-agreement-privacy-modal.default-modal .modal-content .modal-body {
  padding: 0;
  height: 500px;
  justify-content: center;
  display: flex;
  align-items: center;
}
.user-agreement-privacy-modal.default-modal .modal-content .modal-body .content {
  max-width: 80%;
  padding: 16px;
  display: flex;
  flex-direction: column;
}
.user-agreement-privacy-modal.default-modal .modal-content .modal-body .content .content-row {
  display: flex;
  margin-bottom: 20px;
}
.user-agreement-privacy-modal.default-modal .modal-content .modal-body .content .content-row .title {
  color: #d2051e;
  font-size: 22px;
}
.user-agreement-privacy-modal.default-modal .modal-content .modal-body .content .content-row .sub-title {
  color: #671c3e;
  font-size: 16px;
  font-weight: bold;
}
.user-agreement-privacy-modal.default-modal .modal-content .modal-body .content .content-row.first {
  flex-direction: column;
  text-align: center;
}
.user-agreement-privacy-modal.default-modal .modal-content .modal-body .content .content-row.second {
  margin-left: 78px;
}
.user-agreement-privacy-modal.default-modal .modal-content .modal-body .content .content-row.third {
  margin-left: 30px;
}
.user-agreement-privacy-modal.default-modal .modal-content .modal-body .content .content-row .checkbox {
  align-items: flex-start;
  margin-bottom: 16px;
  margin-top: 15px;
}
.user-agreement-privacy-modal.default-modal .modal-content .modal-body .content .content-row .checkbox.diagnostics {
  margin-left: 20px;
}
.user-agreement-privacy-modal.default-modal .modal-content .modal-body .content .content-row .checkbox.notify {
  margin-left: 13px;
}
.user-agreement-privacy-modal.default-modal .modal-content .modal-body .content .content-row .checkbox .checkbox-label {
  display: flex;
  flex-direction: column;
}
.user-agreement-privacy-modal.default-modal .modal-content .modal-body .content .content-row .checkbox .checkbox-label .text {
  max-width: 350px;
}
.user-agreement-privacy-modal.default-modal .modal-content .modal-body .content .content-row .checkbox .checkbox-label .sub-text {
  max-width: 350px;
  color: #929292;
}

.default-modal.settings-modal.user-agreement-settings-modal .section .control-row {
  width: 100%;
  min-width: 0;
}
.default-modal.settings-modal.user-agreement-settings-modal .section .control-row .data-item {
  display: flex;
  align-items: flex-start;
  margin-bottom: 16px;
}
.default-modal.settings-modal.user-agreement-settings-modal .section .control-row .data-item .name-container {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.default-modal.settings-modal.user-agreement-settings-modal .section .control-row .data-item .name-container .name {
  color: #261d24;
  margin-bottom: 5px;
}
.default-modal.settings-modal.user-agreement-settings-modal .section .control-row .data-item .name-container .description {
  color: #999;
}

.user-settings-modal.default-modal .modal-content .modal-body {
  display: flex;
  padding: 16px;
  flex-direction: column;
}
.user-settings-modal.default-modal .modal-content .modal-body .settings-data {
  display: flex;
  font-size: 12px;
}
.user-settings-modal.default-modal .modal-content .modal-body .settings-data .content {
  max-width: 300px;
  min-width: 0;
  margin: 0 16px;
  flex: 1;
}
.user-settings-modal.default-modal .modal-content .modal-body .settings-data .content #user-settings-my-subscription-button {
  margin-top: 36px;
  width: 100%;
}
.user-settings-modal.default-modal .modal-content .modal-body .settings-data .content:first-child {
  margin-left: 0;
}
.user-settings-modal.default-modal .modal-content .modal-body .settings-data .content:last-child {
  margin-right: 0;
}
.user-settings-modal.default-modal .modal-content .modal-body .settings-data .content .content-title {
  margin: 0 0 16px 0;
  color: #524f53;
  font-weight: 700;
  border-bottom: 1px solid #999;
}
.user-settings-modal.default-modal .modal-content .modal-body .settings-data .content .control-row {
  margin-bottom: 16px;
}
.user-settings-modal.default-modal .modal-content .modal-body .settings-data .content .control-row:last-child {
  margin-bottom: 0;
}
.user-settings-modal.default-modal .modal-content .modal-body .sap-account-data-disclaimer {
  margin: 16px 0px 0px 0px;
}

.shortcut-icon-popup-modal .modal-content .modal-body .shortcut-icon-popup div {
  display: block;
}