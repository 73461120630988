﻿@import '../../../src/styles/control-helpers.scss';
@import '../../../src/styles/colors.scss';

.select-region-language-modal.default-modal {
    .modal-content .modal-body {
        padding: 0;
        font-size: 12px;

        .content {
            padding: 16px;

            .message {
                margin: 0;
            }

            .bottom-dropdown-container {
                padding: 16px 0px;
            }

            .button-container{
                display:flex;
                justify-content: flex-end;
            }
        }

        .options {
            display: flex;
            flex-direction: column;

            .option {
                display: flex;
                align-items: center;
                border-top: 1px solid $color-metal;
                cursor: pointer;

                &, &:hover, &:active, &:visited, &:focus {
                    text-decoration: none;
                }

                .image-container {
                    flex: 0 0 62px;
                    height: 48px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-right: 1px solid $color-metal;
                }

                .name-container {
                    flex: 1;
                    height: 48px;
                    display: flex;
                    align-items: center;

                    .name {
                        margin: 0 0 0 16px;
                    }

                    .space {
                        flex: 1;
                    }

                    .arrow {
                        margin: 0 16px 0 0;
                    }
                }

                &:disabled {
                    cursor: not-allowed;
                }
            }
        }
    }
}
