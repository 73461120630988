.control-button {
    .button-element {
        white-space: normal;
        max-width: 100%;

        &.size-full {
            width: 100%;
        }

        .image-container {
            .image {
                margin: 0 auto;
            }
        }

        .text {
            white-space: pre-line;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}