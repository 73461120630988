﻿@import 'control-helpers.scss';
@import 'colors.scss';

.button {
    @include controlStyle($style-button);
    min-width: 96px;
    min-height: 32px;
    padding: 0 8px;
    border: 1px solid;
    margin: 0;
    outline: none;
    display: block;

    &[disabled] {
        cursor: not-allowed;
    }
}

// default
.button-default {
    @include controlStyle($style-button-default);

    &:hover:not([disabled]):not(:active) {
        @include controlStyle($style-button-default-hover);
    }

    &:focus:not(:active) {
        @include controlStyle($style-button-default-focus);
    }

    &:active:not([disabled]) {
        @include controlStyle($style-button-default-active);
    }

    &[disabled] {
        @include controlStyle($style-button-default-disabled);
    }

    &.active {
        @include controlStyle($style-button-default-selected);
    }

    &.active[disabled] {
        @include controlStyle($style-button-default-selected-disabled);
    }
}

// primary
.button-primary {
    @include controlStyle($style-button-primary);

    &:hover:not([disabled]):not(:active) {
        @include controlStyle($style-button-primary-hover);
    }

    &:focus:not(:active) {
        @include controlStyle($style-button-primary-focus);
    }

    &:active:not([disabled]) {
        @include controlStyle($style-button-primary-active);
    }

    &[disabled] {
        @include controlStyle($style-button-primary-disabled);
    }

    &.active {
        @include controlStyle($style-button-primary-selected);
    }

    &.active[disabled] {
        @include controlStyle($style-button-primary-selected-disabled);
    }
}

// CTA
.button-CTA {
    @include controlStyle($style-button-CTA);

    &:hover:not([disabled]):not(:active) {
        @include controlStyle($style-button-CTA-hover);
    }

    &:focus:not(:active) {
        @include controlStyle($style-button-CTA-focus);
    }

    &:active:not([disabled]) {
        @include controlStyle($style-button-CTA-active);
    }

    &[disabled] {
        @include controlStyle($style-button-CTA-disabled);
    }

    &.active {
        @include controlStyle($style-button-CTA-selected);
    }

    &.active[disabled] {
        @include controlStyle($style-button-CTA-selected-disabled);
    }
}

// ghost
.button-ghost {
    @include controlStyle($style-button-ghost);

    &.button-ghost-white {
        background: $color-white;
    }

    &:hover:not([disabled]):not(:active) {
        @include controlStyle($style-button-ghost-hover);
    }

    &:focus:not(:active) {
        @include controlStyle($style-button-ghost-focus);
    }

    &:active:not([disabled]) {
        @include controlStyle($style-button-ghost-active);
    }

    &[disabled] {
        @include controlStyle($style-button-ghost-disabled);
    }

    &.active {
        @include controlStyle($style-button-ghost-selected);
    }

    &.active[disabled] {
        @include controlStyle($style-button-ghost-selected-disabled);
    }
}

// link
.button-link {
    @include controlStyle($style-button-link);

    &:hover:not([disabled]):not(:active) {
        @include controlStyle($style-button-link-hover);
    }

    &:focus:not(:active) {
        @include controlStyle($style-button-link-focus);
    }

    &:active:not([disabled]) {
        @include controlStyle($style-button-link-active);
    }

    &[disabled] {
        @include controlStyle($style-button-link-disabled);
    }

    &.active {
        @include controlStyle($style-button-link-selected);
    }

    &.active[disabled] {
        @include controlStyle($style-button-link-selected-disabled);
    }
}

// rich
.button-rich {
    min-width: 200px;
    border: 1px solid $color-medium-gray;
    background: $color-white;
    outline: none;
    padding: 0;
    margin: 0;
    display: block;

    &:hover:not([disabled]) {
        background: $color-light-gray;
    }

    &:focus {
        border-color: $color-gambogeish-gray;
    }

    &[disabled] {
        background: $color-silver;

        .button-rich-content .button-rich-text {
            color: $color-metal;
        }
    }

    .button-rich-content {
        min-height: 48px;
        display: flex;
        align-items: center;

        .button-rich-seperator {
            width: 0px;
            height: 48px;
            border-left: 1px solid  $color-medium-gray;
            margin-left: -1px;
        }

        .button-rich-image {
            border-right: 1px solid $color-medium-gray;
            padding: 8px;
            min-width: 0;
            flex: 0 0 40%;
            margin: 0;
            align-items: center;
            mix-blend-mode: multiply;
        }

        .button-rich-text {
            border-left: 1px solid $color-medium-gray;
            margin: 0 0 0 -1px;
            padding: 8px 0 8px 8px;
            min-width: 0;
            flex: 1;
            display: flex;
            align-items: center;
            text-align: left;
        }

        .button-rich-caret {
            flex: 0 0 24px;
        }
    }
}

// icon
.button-icon {
    padding: 0;
    min-width: 0;

    &:disabled {
        span.sprite {
            opacity: 0.25;
        }
    }
}
