﻿@import 'colors.scss';
@import 'main.scss';

.dropdown-menu-container {
    &.open .dropdown-menu {
        display: flex;
        margin-bottom: 5px;
    }

    .dropdown-toggle:focus {
        outline: auto 5px -webkit-focus-ring-color;
    }

    .dropdown-menu-button {
        margin: 0;
        padding: 0;
        border: none;
        background: none;
    }
}

.dropdown-menu {
    background: $color-silver;
    padding: 2px 0;
    flex-direction: column;

    .dropdown-from-body {
        display: flex;
        flex-direction: column;
    }

    .dropdown-item-button {
        display: flex;
        flex-grow: 1;
        margin: 0 3px;
        padding: 3px 3px;
        border: none;
        background: none;
        color: $color-iron;
        text-align: left;
        font-size: $font-size-main;
        white-space: nowrap;
        text-decoration: none;

        &:last-child {
            border-bottom: none;
        }

        &:hover {
            text-decoration: none;
            background: $color-light-gray;
        }
        &[disabled] {
            color: $color-gray;
            pointer-events: none;
        }
    }
}
