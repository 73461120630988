﻿@import '../../../src/styles/colors.scss';

.default-modal.settings-modal.user-agreement-settings-modal {
    .section {
        .control-row {
            width: 100%;
            min-width: 0;

            .data-item {
                display: flex;
                align-items: flex-start;
                margin-bottom: 16px;

                .name-container {
                    display: flex;
                    flex-direction: column;
                    flex: 1;

                    .name{
                        color:$color-eggplant;
                        margin-bottom: 5px;
                    }

                    .description {
                        color: $color-gray;
                    }
                }
            }
        }
    }
}
