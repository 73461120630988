﻿@import '../../src/styles/control-helpers.scss';
@import '../../src/styles/colors.scss';

.control {
    .control-header {
        display: flex;
        align-items: center;

        .control-label {
            &:not(.ng-hide) + .tooltip-control-wrapper {
                margin-left: 4px;
            }
        }

        .tooltip-control-wrapper {
            margin-bottom: 4px;
        }
    }
}

// textbox
form.ng-submitted .text-box.ng-invalid .text-box-container .input.data,
// .text-box.ng-touched.ng-invalid .text-box-container .input.data,

// input
form.ng-submitted .input.ng-invalid,
// .input.ng-touched.ng-invalid,

// dropdown
form.ng-submitted .dropdown.ng-invalid .control-container .dropdown-button,
// .dropdown.ng-touched.ng-invalid .control-container .button,

// textarea
form.ng-submitted textarea.ng-invalid,
textarea.ng-touched.ng-invalid {
    background: $color-yellow;
}
