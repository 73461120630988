﻿@import 'control-vars.scss';
@import 'control-style.scss';

// input
$style-input: $style-input-color $style-input-size $style-input-weight $style-input-border-width $style-input-border-color $style-input-border-radius $style-input-background $style-input-opacity $style-input-box-shadow;
$style-input-hover: $style-input-hover-color $style-input-hover-size $style-input-hover-weight $style-input-hover-border-width $style-input-hover-border-color $style-input-hover-border-radius $style-input-hover-background $style-input-hover-opacity $style-input-hover-box-shadow;
$style-input-focus: $style-input-focus-color $style-input-focus-size $style-input-focus-weight $style-input-focus-border-width $style-input-focus-border-color $style-input-focus-border-radius $style-input-focus-background $style-input-focus-opacity $style-input-focus-box-shadow;
$style-input-active: $style-input-active-color $style-input-active-size $style-input-active-weight $style-input-active-border-width $style-input-active-border-color $style-input-active-border-radius $style-input-active-background $style-input-active-opacity $style-input-active-box-shadow;
$style-input-disabled: $style-input-disabled-color $style-input-disabled-size $style-input-disabled-weight $style-input-disabled-border-width $style-input-disabled-border-color $style-input-disabled-border-radius $style-input-disabled-background $style-input-disabled-opacity $style-input-disabled-box-shadow;

// dropdown
$style-dropdown: $style-dropdown-color $style-dropdown-size $style-dropdown-weight $style-dropdown-border-width $style-dropdown-border-color $style-dropdown-border-radius $style-dropdown-background $style-dropdown-opacity $style-dropdown-box-shadow;
$style-dropdown-hover: $style-dropdown-hover-color $style-dropdown-hover-size $style-dropdown-hover-weight $style-dropdown-hover-border-width $style-dropdown-hover-border-color $style-dropdown-hover-border-radius $style-dropdown-hover-background $style-dropdown-hover-opacity $style-dropdown-hover-box-shadow;
$style-dropdown-focus: $style-dropdown-focus-color $style-dropdown-focus-size $style-dropdown-focus-weight $style-dropdown-focus-border-width $style-dropdown-focus-border-color $style-dropdown-focus-border-radius $style-dropdown-focus-background $style-dropdown-focus-opacity $style-dropdown-focus-box-shadow;
$style-dropdown-active: $style-dropdown-active-color $style-dropdown-active-size $style-dropdown-active-weight $style-dropdown-active-border-width $style-dropdown-active-border-color $style-dropdown-active-border-radius $style-dropdown-active-background $style-dropdown-active-opacity $style-dropdown-active-box-shadow;
$style-dropdown-disabled: $style-dropdown-disabled-color $style-dropdown-disabled-size $style-dropdown-disabled-weight $style-dropdown-disabled-border-width $style-dropdown-disabled-border-color $style-dropdown-disabled-border-radius $style-dropdown-disabled-background $style-dropdown-disabled-opacity $style-dropdown-disabled-box-shadow;
$style-dropdown-selected: $style-dropdown-selected-color $style-dropdown-selected-size $style-dropdown-selected-weight $style-dropdown-selected-border-width $style-dropdown-selected-border-color $style-dropdown-selected-border-radius $style-dropdown-selected-background $style-dropdown-selected-opacity $style-dropdown-selected-box-shadow;

// dropdown-item
$style-dropdown-item: $style-dropdown-item-color $style-dropdown-item-size $style-dropdown-item-weight $style-dropdown-item-border-width $style-dropdown-item-border-color $style-dropdown-item-border-radius $style-dropdown-item-background $style-dropdown-item-opacity $style-dropdown-item-box-shadow;
$style-dropdown-item-hover: $style-dropdown-item-hover-color $style-dropdown-item-hover-size $style-dropdown-item-hover-weight $style-dropdown-item-hover-border-width $style-dropdown-item-hover-border-color $style-dropdown-item-hover-border-radius $style-dropdown-item-hover-background $style-dropdown-item-hover-opacity $style-dropdown-item-hover-box-shadow;
$style-dropdown-item-focus: $style-dropdown-item-focus-color $style-dropdown-item-focus-size $style-dropdown-item-focus-weight $style-dropdown-item-focus-border-width $style-dropdown-item-focus-border-color $style-dropdown-item-focus-border-radius $style-dropdown-item-focus-background $style-dropdown-item-focus-opacity $style-dropdown-item-focus-box-shadow;
$style-dropdown-item-active: $style-dropdown-item-active-color $style-dropdown-item-active-size $style-dropdown-item-active-weight $style-dropdown-item-active-border-width $style-dropdown-item-active-border-color $style-dropdown-item-active-border-radius $style-dropdown-item-active-background $style-dropdown-item-active-opacity $style-dropdown-item-active-box-shadow;
$style-dropdown-item-disabled: $style-dropdown-item-disabled-color $style-dropdown-item-disabled-size $style-dropdown-item-disabled-weight $style-dropdown-item-disabled-border-width $style-dropdown-item-disabled-border-color $style-dropdown-item-disabled-border-radius $style-dropdown-item-disabled-background $style-dropdown-item-disabled-opacity $style-dropdown-item-disabled-box-shadow;
$style-dropdown-item-selected: $style-dropdown-item-selected-color $style-dropdown-item-selected-size $style-dropdown-item-selected-weight $style-dropdown-item-selected-border-width $style-dropdown-item-selected-border-color $style-dropdown-item-selected-border-radius $style-dropdown-item-selected-background $style-dropdown-item-selected-opacity $style-dropdown-item-selected-box-shadow;

// button
$style-button: $style-button-color $style-button-size $style-button-weight $style-button-border-width $style-button-border-color $style-button-border-radius $style-button-background $style-button-opacity $style-button-box-shadow;
$style-button-hover: $style-button-hover-color $style-button-hover-size $style-button-hover-weight $style-button-hover-border-width $style-button-hover-border-color $style-button-hover-border-radius $style-button-hover-background $style-button-hover-opacity $style-button-hover-box-shadow;
$style-button-focus: $style-button-focus-color $style-button-focus-size $style-button-focus-weight $style-button-focus-border-width $style-button-focus-border-color $style-button-focus-border-radius $style-button-focus-background $style-button-focus-opacity $style-button-focus-box-shadow;
$style-button-active: $style-button-active-color $style-button-active-size $style-button-active-weight $style-button-active-border-width $style-button-active-border-color $style-button-active-border-radius $style-button-active-background $style-button-active-opacity $style-button-active-box-shadow;
$style-button-disabled: $style-button-disabled-color $style-button-disabled-size $style-button-disabled-weight $style-button-disabled-border-width $style-button-disabled-border-color $style-button-disabled-border-radius $style-button-disabled-background $style-button-disabled-opacity $style-button-disabled-box-shadow;
$style-button-selected: $style-button-selected-color $style-button-selected-size $style-button-selected-weight $style-button-selected-border-width $style-button-selected-border-color $style-button-selected-border-radius $style-button-selected-background $style-button-selected-opacity $style-button-selected-box-shadow;
$style-button-selected-disabled: $style-button-selected-disabled-color $style-button-selected-disabled-size $style-button-selected-disabled-weight $style-button-selected-disabled-border-width $style-button-selected-disabled-border-color $style-button-selected-disabled-border-radius $style-button-selected-disabled-background $style-button-selected-disabled-opacity $style-button-selected-disabled-box-shadow;

// button default
$style-button-default: $style-button-default-color $style-button-default-size $style-button-default-weight $style-button-default-border-width $style-button-default-border-color $style-button-default-border-radius $style-button-default-background $style-button-default-opacity $style-button-default-box-shadow;
$style-button-default-hover: $style-button-default-hover-color $style-button-default-hover-size $style-button-default-hover-weight $style-button-default-hover-border-width $style-button-default-hover-border-color $style-button-default-hover-border-radius $style-button-default-hover-background $style-button-default-hover-opacity $style-button-default-hover-box-shadow;
$style-button-default-focus: $style-button-default-focus-color $style-button-default-focus-size $style-button-default-focus-weight $style-button-default-focus-border-width $style-button-default-focus-border-color $style-button-default-focus-border-radius $style-button-default-focus-background $style-button-default-focus-opacity $style-button-default-focus-box-shadow;
$style-button-default-active: $style-button-default-active-color $style-button-default-active-size $style-button-default-active-weight $style-button-default-active-border-width $style-button-default-active-border-color $style-button-default-active-border-radius $style-button-default-active-background $style-button-default-active-opacity $style-button-default-active-box-shadow;
$style-button-default-disabled: $style-button-default-disabled-color $style-button-default-disabled-size $style-button-default-disabled-weight $style-button-default-disabled-border-width $style-button-default-disabled-border-color $style-button-default-disabled-border-radius $style-button-default-disabled-background $style-button-default-disabled-opacity $style-button-default-disabled-box-shadow;
$style-button-default-selected: $style-button-default-selected-color $style-button-default-selected-size $style-button-default-selected-weight $style-button-default-selected-border-width $style-button-default-selected-border-color $style-button-default-selected-border-radius $style-button-default-selected-background $style-button-default-selected-opacity $style-button-default-selected-box-shadow;
$style-button-default-selected-disabled: $style-button-default-selected-disabled-color $style-button-default-selected-disabled-size $style-button-default-selected-disabled-weight $style-button-default-selected-disabled-border-width $style-button-default-selected-disabled-border-color $style-button-default-selected-disabled-border-radius $style-button-default-selected-disabled-background $style-button-default-selected-disabled-opacity $style-button-default-selected-disabled-box-shadow;

// button primary
$style-button-primary: $style-button-primary-color $style-button-primary-size $style-button-primary-weight $style-button-primary-border-width $style-button-primary-border-color $style-button-primary-border-radius $style-button-primary-background $style-button-primary-opacity $style-button-primary-box-shadow;
$style-button-primary-hover: $style-button-primary-hover-color $style-button-primary-hover-size $style-button-primary-hover-weight $style-button-primary-hover-border-width $style-button-primary-hover-border-color $style-button-primary-hover-border-radius $style-button-primary-hover-background $style-button-primary-hover-opacity $style-button-primary-hover-box-shadow;
$style-button-primary-focus: $style-button-primary-focus-color $style-button-primary-focus-size $style-button-primary-focus-weight $style-button-primary-focus-border-width $style-button-primary-focus-border-color $style-button-primary-focus-border-radius $style-button-primary-focus-background $style-button-primary-focus-opacity $style-button-primary-focus-box-shadow;
$style-button-primary-active: $style-button-primary-active-color $style-button-primary-active-size $style-button-primary-active-weight $style-button-primary-active-border-width $style-button-primary-active-border-color $style-button-primary-active-border-radius $style-button-primary-active-background $style-button-primary-active-opacity $style-button-primary-active-box-shadow;
$style-button-primary-disabled: $style-button-primary-disabled-color $style-button-primary-disabled-size $style-button-primary-disabled-weight $style-button-primary-disabled-border-width $style-button-primary-disabled-border-color $style-button-primary-disabled-border-radius $style-button-primary-disabled-background $style-button-primary-disabled-opacity $style-button-primary-disabled-box-shadow;
$style-button-primary-selected: $style-button-primary-selected-color $style-button-primary-selected-size $style-button-primary-selected-weight $style-button-primary-selected-border-width $style-button-primary-selected-border-color $style-button-primary-selected-border-radius $style-button-primary-selected-background $style-button-primary-selected-opacity $style-button-primary-selected-box-shadow;
$style-button-primary-selected-disabled: $style-button-primary-selected-disabled-color $style-button-primary-selected-disabled-size $style-button-primary-selected-disabled-weight $style-button-primary-selected-disabled-border-width $style-button-primary-selected-disabled-border-color $style-button-primary-selected-disabled-border-radius $style-button-primary-selected-disabled-background $style-button-primary-selected-disabled-opacity $style-button-primary-selected-disabled-box-shadow;

// button CTA (call to action)
$style-button-CTA: $style-button-CTA-color $style-button-CTA-size $style-button-CTA-weight $style-button-CTA-border-width $style-button-CTA-border-color $style-button-CTA-border-radius $style-button-CTA-background $style-button-CTA-opacity $style-button-CTA-box-shadow;
$style-button-CTA-hover: $style-button-CTA-hover-color $style-button-CTA-hover-size $style-button-CTA-hover-weight $style-button-CTA-hover-border-width $style-button-CTA-hover-border-color $style-button-CTA-hover-border-radius $style-button-CTA-hover-background $style-button-CTA-hover-opacity $style-button-CTA-hover-box-shadow;
$style-button-CTA-focus: $style-button-CTA-focus-color $style-button-CTA-focus-size $style-button-CTA-focus-weight $style-button-CTA-focus-border-width $style-button-CTA-focus-border-color $style-button-CTA-focus-border-radius $style-button-CTA-focus-background $style-button-CTA-focus-opacity $style-button-CTA-focus-box-shadow;
$style-button-CTA-active: $style-button-CTA-active-color $style-button-CTA-active-size $style-button-CTA-active-weight $style-button-CTA-active-border-width $style-button-CTA-active-border-color $style-button-CTA-active-border-radius $style-button-CTA-active-background $style-button-CTA-active-opacity $style-button-CTA-active-box-shadow;
$style-button-CTA-disabled: $style-button-CTA-disabled-color $style-button-CTA-disabled-size $style-button-CTA-disabled-weight $style-button-CTA-disabled-border-width $style-button-CTA-disabled-border-color $style-button-CTA-disabled-border-radius $style-button-CTA-disabled-background $style-button-CTA-disabled-opacity $style-button-CTA-disabled-box-shadow;
$style-button-CTA-selected: $style-button-CTA-selected-color $style-button-CTA-selected-size $style-button-CTA-selected-weight $style-button-CTA-selected-border-width $style-button-CTA-selected-border-color $style-button-CTA-selected-border-radius $style-button-CTA-selected-background $style-button-CTA-selected-opacity $style-button-CTA-selected-box-shadow;
$style-button-CTA-selected-disabled: $style-button-CTA-selected-disabled-color $style-button-CTA-selected-disabled-size $style-button-CTA-selected-disabled-weight $style-button-CTA-selected-disabled-border-width $style-button-CTA-selected-disabled-border-color $style-button-CTA-selected-disabled-border-radius $style-button-CTA-selected-disabled-background $style-button-CTA-selected-disabled-opacity $style-button-CTA-selected-disabled-box-shadow;

// button ghost
$style-button-ghost: $style-button-ghost-color $style-button-ghost-size $style-button-ghost-weight $style-button-ghost-border-width $style-button-ghost-border-color $style-button-ghost-border-radius $style-button-ghost-background $style-button-ghost-opacity $style-button-ghost-box-shadow;
$style-button-ghost-hover: $style-button-ghost-hover-color $style-button-ghost-hover-size $style-button-ghost-hover-weight $style-button-ghost-hover-border-width $style-button-ghost-hover-border-color $style-button-ghost-hover-border-radius $style-button-ghost-hover-background $style-button-ghost-hover-opacity $style-button-ghost-hover-box-shadow;
$style-button-ghost-focus: $style-button-ghost-focus-color $style-button-ghost-focus-size $style-button-ghost-focus-weight $style-button-ghost-focus-border-width $style-button-ghost-focus-border-color $style-button-ghost-focus-border-radius $style-button-ghost-focus-background $style-button-ghost-focus-opacity $style-button-ghost-focus-box-shadow;
$style-button-ghost-active: $style-button-ghost-active-color $style-button-ghost-active-size $style-button-ghost-active-weight $style-button-ghost-active-border-width $style-button-ghost-active-border-color $style-button-ghost-active-border-radius $style-button-ghost-active-background $style-button-ghost-active-opacity $style-button-ghost-active-box-shadow;
$style-button-ghost-disabled: $style-button-ghost-disabled-color $style-button-ghost-disabled-size $style-button-ghost-disabled-weight $style-button-ghost-disabled-border-width $style-button-ghost-disabled-border-color $style-button-ghost-disabled-border-radius $style-button-ghost-disabled-background $style-button-ghost-disabled-opacity $style-button-ghost-disabled-box-shadow;
$style-button-ghost-selected: $style-button-ghost-selected-color $style-button-ghost-selected-size $style-button-ghost-selected-weight $style-button-ghost-selected-border-width $style-button-ghost-selected-border-color $style-button-ghost-selected-border-radius $style-button-ghost-selected-background $style-button-ghost-selected-opacity $style-button-ghost-selected-box-shadow;
$style-button-ghost-selected-disabled: $style-button-ghost-selected-disabled-color $style-button-ghost-selected-disabled-size $style-button-ghost-selected-disabled-weight $style-button-ghost-selected-disabled-border-width $style-button-ghost-selected-disabled-border-color $style-button-ghost-selected-disabled-border-radius $style-button-ghost-selected-disabled-background $style-button-ghost-selected-disabled-opacity $style-button-ghost-selected-disabled-box-shadow;

// button link
$style-button-link: $style-button-link-color $style-button-link-size $style-button-link-weight $style-button-link-border-width $style-button-link-border-color $style-button-link-border-radius $style-button-link-background $style-button-link-opacity $style-button-link-box-shadow;
$style-button-link-hover: $style-button-link-hover-color $style-button-link-hover-size $style-button-link-hover-weight $style-button-link-hover-border-width $style-button-link-hover-border-color $style-button-link-hover-border-radius $style-button-link-hover-background $style-button-link-hover-opacity $style-button-link-hover-box-shadow;
$style-button-link-focus: $style-button-link-focus-color $style-button-link-focus-size $style-button-link-focus-weight $style-button-link-focus-border-width $style-button-link-focus-border-color $style-button-link-focus-border-radius $style-button-link-focus-background $style-button-link-focus-opacity $style-button-link-focus-box-shadow;
$style-button-link-active: $style-button-link-active-color $style-button-link-active-size $style-button-link-active-weight $style-button-link-active-border-width $style-button-link-active-border-color $style-button-link-active-border-radius $style-button-link-active-background $style-button-link-active-opacity $style-button-link-active-box-shadow;
$style-button-link-disabled: $style-button-link-disabled-color $style-button-link-disabled-size $style-button-link-disabled-weight $style-button-link-disabled-border-width $style-button-link-disabled-border-color $style-button-link-disabled-border-radius $style-button-link-disabled-background $style-button-link-disabled-opacity $style-button-link-disabled-box-shadow;
$style-button-link-selected: $style-button-link-selected-color $style-button-link-selected-size $style-button-link-selected-weight $style-button-link-selected-border-width $style-button-link-selected-border-color $style-button-link-selected-border-radius $style-button-link-selected-background $style-button-link-selected-opacity $style-button-link-selected-box-shadow;
$style-button-link-selected-disabled: $style-button-link-selected-disabled-color $style-button-link-selected-disabled-size $style-button-link-selected-disabled-weight $style-button-link-selected-disabled-border-width $style-button-link-selected-disabled-border-color $style-button-link-selected-disabled-border-radius $style-button-link-selected-disabled-background $style-button-link-selected-disabled-opacity $style-button-link-selected-disabled-box-shadow;

// radion button text
$style-radio-button-text: $style-radio-button-text-color $style-radio-button-text-size $style-radio-button-text-weight $style-radio-button-text-border-width $style-radio-button-text-border-color $style-radio-button-text-border-radius $style-radio-button-text-background $style-radio-button-text-opacity $style-radio-button-text-box-shadow;
$style-radio-button-text-hover: $style-radio-button-text-hover-color $style-radio-button-text-hover-size $style-radio-button-text-hover-weight $style-radio-button-text-hover-border-width $style-radio-button-text-hover-border-color $style-radio-button-text-hover-border-radius $style-radio-button-text-hover-background $style-radio-button-text-hover-opacity $style-radio-button-text-hover-box-shadow;
$style-radio-button-text-focus: $style-radio-button-text-focus-color $style-radio-button-text-focus-size $style-radio-button-text-focus-weight $style-radio-button-text-focus-border-width $style-radio-button-text-focus-border-color $style-radio-button-text-focus-border-radius $style-radio-button-text-focus-background $style-radio-button-text-focus-opacity $style-radio-button-text-focus-box-shadow;
$style-radio-button-text-active: $style-radio-button-text-active-color $style-radio-button-text-active-size $style-radio-button-text-active-weight $style-radio-button-text-active-border-width $style-radio-button-text-active-border-color $style-radio-button-text-active-border-radius $style-radio-button-text-active-background $style-radio-button-text-active-opacity $style-radio-button-text-active-box-shadow;
$style-radio-button-text-disabled: $style-radio-button-text-disabled-color $style-radio-button-text-disabled-size $style-radio-button-text-disabled-weight $style-radio-button-text-disabled-border-width $style-radio-button-text-disabled-border-color $style-radio-button-text-disabled-border-radius $style-radio-button-text-disabled-background $style-radio-button-text-disabled-opacity $style-radio-button-text-disabled-box-shadow;

// checkbox text
$style-checkbox-text: $style-checkbox-text-color $style-checkbox-text-size $style-checkbox-text-weight $style-checkbox-text-border-width $style-checkbox-text-border-color $style-checkbox-text-border-radius $style-checkbox-text-background $style-checkbox-text-opacity $style-checkbox-text-box-shadow;
$style-checkbox-text-hover: $style-checkbox-text-hover-color $style-checkbox-text-hover-size $style-checkbox-text-hover-weight $style-checkbox-text-hover-border-width $style-checkbox-text-hover-border-color $style-checkbox-text-hover-border-radius $style-checkbox-text-hover-background $style-checkbox-text-hover-opacity $style-checkbox-text-hover-box-shadow;
$style-checkbox-text-focus: $style-checkbox-text-focus-color $style-checkbox-text-focus-size $style-checkbox-text-focus-weight $style-checkbox-text-focus-border-width $style-checkbox-text-focus-border-color $style-checkbox-text-focus-border-radius $style-checkbox-text-focus-background $style-checkbox-text-focus-opacity $style-checkbox-text-focus-box-shadow;
$style-checkbox-text-active: $style-checkbox-text-active-color $style-checkbox-text-active-size $style-checkbox-text-active-weight $style-checkbox-text-active-border-width $style-checkbox-text-active-border-color $style-checkbox-text-active-border-radius $style-checkbox-text-active-background $style-checkbox-text-active-opacity $style-checkbox-text-active-box-shadow;
$style-checkbox-text-disabled: $style-checkbox-text-disabled-color $style-checkbox-text-disabled-size $style-checkbox-text-disabled-weight $style-checkbox-text-disabled-border-width $style-checkbox-text-disabled-border-color $style-checkbox-text-disabled-border-radius $style-checkbox-text-disabled-background $style-checkbox-text-disabled-opacity $style-checkbox-text-disabled-box-shadow;

// control title
$style-control-title: $style-control-title-color $style-control-title-size $style-control-title-weight $style-control-title-border-width $style-control-title-border-color $style-control-title-border-radius $style-control-title-background $style-control-title-opacity $style-control-title-box-shadow;
$style-control-title-hover: $style-control-title-hover-color $style-control-title-hover-size $style-control-title-hover-weight $style-control-title-hover-border-width $style-control-title-hover-border-color $style-control-title-hover-border-radius $style-control-title-hover-background $style-control-title-hover-opacity $style-control-title-hover-box-shadow;
$style-control-title-focus: $style-control-title-focus-color $style-control-title-focus-size $style-control-title-focus-weight $style-control-title-focus-border-width $style-control-title-focus-border-color $style-control-title-focus-border-radius $style-control-title-focus-background $style-control-title-focus-opacity $style-control-title-focus-box-shadow;
$style-control-title-active: $style-control-title-active-color $style-control-title-active-size $style-control-title-active-weight $style-control-title-active-border-width $style-control-title-active-border-color $style-control-title-active-border-radius $style-control-title-active-background $style-control-title-active-opacity $style-control-title-active-box-shadow;
$style-control-title-disabled: $style-control-title-disabled-color $style-control-title-disabled-size $style-control-title-disabled-weight $style-control-title-disabled-border-width $style-control-title-disabled-border-color $style-control-title-disabled-border-radius $style-control-title-disabled-background $style-control-title-disabled-opacity $style-control-title-disabled-box-shadow;

// region
$style-region: $style-region-color $style-region-size $style-region-weight $style-region-border-width $style-region-border-color $style-region-border-radius $style-region-background $style-region-opacity $style-region-box-shadow;
$style-region-hover: $style-region-hover-color $style-region-hover-size $style-region-hover-weight $style-region-hover-border-width $style-region-hover-border-color $style-region-hover-border-radius $style-region-hover-background $style-region-hover-opacity $style-region-hover-box-shadow;
$style-region-focus: $style-region-focus-color $style-region-focus-size $style-region-focus-weight $style-region-focus-border-width $style-region-focus-border-color $style-region-focus-border-radius $style-region-focus-background $style-region-focus-opacity $style-region-focus-box-shadow;
$style-region-active: $style-region-active-color $style-region-active-size $style-region-active-weight $style-region-active-border-width $style-region-active-border-color $style-region-active-border-radius $style-region-active-background $style-region-active-opacity $style-region-active-box-shadow;
$style-region-disabled: $style-region-disabled-color $style-region-disabled-size $style-region-disabled-weight $style-region-disabled-border-width $style-region-disabled-border-color $style-region-disabled-border-radius $style-region-disabled-background $style-region-disabled-opacity $style-region-disabled-box-shadow;

// region header
$style-region-header: $style-region-header-color $style-region-header-size $style-region-header-weight $style-region-header-border-width $style-region-header-border-color $style-region-header-border-radius $style-region-header-background $style-region-header-opacity $style-region-header-box-shadow;
$style-region-header-hover: $style-region-header-hover-color $style-region-header-hover-size $style-region-header-hover-weight $style-region-header-hover-border-width $style-region-header-hover-border-color $style-region-header-hover-border-radius $style-region-header-hover-background $style-region-header-hover-opacity $style-region-header-hover-box-shadow;
$style-region-header-focus: $style-region-header-focus-color $style-region-header-focus-size $style-region-header-focus-weight $style-region-header-focus-border-width $style-region-header-focus-border-color $style-region-header-focus-border-radius $style-region-header-focus-background $style-region-header-focus-opacity $style-region-header-focus-box-shadow;
$style-region-header-active: $style-region-header-active-color $style-region-header-active-size $style-region-header-active-weight $style-region-header-active-border-width $style-region-header-active-border-color $style-region-header-active-border-radius $style-region-header-active-background $style-region-header-active-opacity $style-region-header-active-box-shadow;
$style-region-header-disabled: $style-region-header-disabled-color $style-region-header-disabled-size $style-region-header-disabled-weight $style-region-header-disabled-border-width $style-region-header-disabled-border-color $style-region-header-disabled-border-radius $style-region-header-disabled-background $style-region-header-disabled-opacity $style-region-header-disabled-box-shadow;

// label
$style-label-text: $style-label-text-color $style-label-text-size $style-label-text-weight $style-label-text-border-width $style-label-text-border-color $style-label-text-border-radius $style-label-text-background $style-label-text-opacity $style-label-text-box-shadow;
$style-label-text-hover: $style-label-text-hover-color $style-label-text-hover-size $style-label-text-hover-weight $style-label-text-hover-border-width $style-label-text-hover-border-color $style-label-text-hover-border-radius $style-label-text-hover-background $style-label-text-hover-opacity $style-label-text-hover-box-shadow;
$style-label-text-focus: $style-label-text-focus-color $style-label-text-focus-size $style-label-text-focus-weight $style-label-text-focus-border-width $style-label-text-focus-border-color $style-label-text-focus-border-radius $style-label-text-focus-background $style-label-text-focus-opacity $style-label-text-focus-box-shadow;
$style-label-text-active: $style-label-text-active-color $style-label-text-active-size $style-label-text-active-weight $style-label-text-active-border-width $style-label-text-active-border-color $style-label-text-active-border-radius $style-label-text-active-background $style-label-text-active-opacity $style-label-text-active-box-shadow;
$style-label-text-disabled: $style-label-text-disabled-color $style-label-text-disabled-size $style-label-text-disabled-weight $style-label-text-disabled-border-width $style-label-text-disabled-border-color $style-label-text-disabled-border-radius $style-label-text-disabled-background $style-label-text-disabled-opacity $style-label-text-disabled-box-shadow;

// rotate button
$style-rotate-button: $style-rotate-button-color $style-rotate-button-size $style-rotate-button-weight $style-rotate-button-border-width $style-rotate-button-border-color $style-rotate-button-border-radius $style-rotate-button-background $style-rotate-button-opacity $style-rotate-button-box-shadow;
$style-rotate-button-hover: $style-rotate-button-hover-color $style-rotate-button-hover-size $style-rotate-button-hover-weight $style-rotate-button-hover-border-width $style-rotate-button-hover-border-color $style-rotate-button-hover-border-radius $style-rotate-button-hover-background $style-rotate-button-hover-opacity $style-rotate-button-hover-box-shadow;
$style-rotate-button-focus: $style-rotate-button-focus-color $style-rotate-button-focus-size $style-rotate-button-focus-weight $style-rotate-button-focus-border-width $style-rotate-button-focus-border-color $style-rotate-button-focus-border-radius $style-rotate-button-focus-background $style-rotate-button-focus-opacity $style-rotate-button-focus-box-shadow;
$style-rotate-button-active: $style-rotate-button-active-color $style-rotate-button-active-size $style-rotate-button-active-weight $style-rotate-button-active-border-width $style-rotate-button-active-border-color $style-rotate-button-active-border-radius $style-rotate-button-active-background $style-rotate-button-active-opacity $style-rotate-button-active-box-shadow;
$style-rotate-button-disabled: $style-rotate-button-disabled-color $style-rotate-button-disabled-size $style-rotate-button-disabled-weight $style-rotate-button-disabled-border-width $style-rotate-button-disabled-border-color $style-rotate-button-disabled-border-radius $style-rotate-button-disabled-background $style-rotate-button-disabled-opacity $style-rotate-button-disabled-box-shadow;

// rotate input
$style-rotate-input: $style-rotate-input-color $style-rotate-input-size $style-rotate-input-weight $style-rotate-input-border-width $style-rotate-input-border-color $style-rotate-input-border-radius $style-rotate-input-background $style-rotate-input-opacity $style-rotate-input-box-shadow;
$style-rotate-input-hover: $style-rotate-input-hover-color $style-rotate-input-hover-size $style-rotate-input-hover-weight $style-rotate-input-hover-border-width $style-rotate-input-hover-border-color $style-rotate-input-hover-border-radius $style-rotate-input-hover-background $style-rotate-input-hover-opacity $style-rotate-input-hover-box-shadow;
$style-rotate-input-focus: $style-rotate-input-focus-color $style-rotate-input-focus-size $style-rotate-input-focus-weight $style-rotate-input-focus-border-width $style-rotate-input-focus-border-color $style-rotate-input-focus-border-radius $style-rotate-input-focus-background $style-rotate-input-focus-opacity $style-rotate-input-focus-box-shadow;
$style-rotate-input-active: $style-rotate-input-active-color $style-rotate-input-active-size $style-rotate-input-active-weight $style-rotate-input-active-border-width $style-rotate-input-active-border-color $style-rotate-input-active-border-radius $style-rotate-input-active-background $style-rotate-input-active-opacity $style-rotate-input-active-box-shadow;
$style-rotate-input-disabled: $style-rotate-input-disabled-color $style-rotate-input-disabled-size $style-rotate-input-disabled-weight $style-rotate-input-disabled-border-width $style-rotate-input-disabled-border-color $style-rotate-input-disabled-border-radius $style-rotate-input-disabled-background $style-rotate-input-disabled-opacity $style-rotate-input-disabled-box-shadow;

// menu
$style-menu: $style-menu-color $style-menu-size $style-menu-weight $style-menu-border-width $style-menu-border-color $style-menu-border-radius $style-menu-background $style-menu-opacity $style-menu-box-shadow;
$style-menu-hover: $style-menu-hover-color $style-menu-hover-size $style-menu-hover-weight $style-menu-hover-border-width $style-menu-hover-border-color $style-menu-hover-border-radius $style-menu-hover-background $style-menu-hover-opacity $style-menu-hover-box-shadow;
$style-menu-focus: $style-menu-focus-color $style-menu-focus-size $style-menu-focus-weight $style-menu-focus-border-width $style-menu-focus-border-color $style-menu-focus-border-radius $style-menu-focus-background $style-menu-focus-opacity $style-menu-focus-box-shadow;
$style-menu-active: $style-menu-active-color $style-menu-active-size $style-menu-active-weight $style-menu-active-border-width $style-menu-active-border-color $style-menu-active-border-radius $style-menu-active-background $style-menu-active-opacity $style-menu-active-box-shadow;
$style-menu-disabled: $style-menu-disabled-color $style-menu-disabled-size $style-menu-disabled-weight $style-menu-disabled-border-width $style-menu-disabled-border-color $style-menu-disabled-border-radius $style-menu-disabled-background $style-menu-disabled-opacity $style-menu-disabled-box-shadow;

// menu tabs
$style-menu-tabs: $style-menu-tabs-color $style-menu-tabs-size $style-menu-tabs-weight $style-menu-tabs-border-width $style-menu-tabs-border-color $style-menu-tabs-border-radius $style-menu-tabs-background $style-menu-tabs-opacity $style-menu-tabs-box-shadow;


// HELPERS

// color
@mixin controlColor($color1, $color2: null) {
    @if $color1 {
        color: $color1;
    }
    @else if $color2 {
        color: $color2;
    }
}

// font-size
@mixin controlSize($size1, $size2: null) {
    @if $size1 {
        font-size: $size1;
    }
    @else if $size2 {
        font-size: $size2;
    }
}

// font-weight
@mixin controlWeight($weight1, $weight2: null) {
    @if $weight1 {
        font-weight: $weight1;
    }
    @else if $weight2 {
        font-weight: $weight2;
    }
}

// border-width
@mixin controlBorderWidth($width1, $width2: null) {
    @if $width1 {
        border-width: $width1;
    }
    @else if $width2 {
        border-width: $width2;
    }
}

// border-color
@mixin controlBorderColor($color1, $color2: null) {
    @if $color1 {
        border-color: $color1;
    }
    @else if $color2 {
        border-color: $color2;
    }
}

// border-radius
@mixin controlBorderRadius($radius1, $radius2: null) {
    @if $radius1 {
        border-radius: $radius1;
    }
    @else if $radius2 {
        border-radius: $radius2;
    }
}

// background
@mixin controlBackground($background1, $background2: null) {
    @if $background1 {
        background: $background1;
    }
    @else if $background2 {
        background: $background2;
    }
}

// opacity
@mixin controlOpacity($opacity1, $opacity2: null) {
    @if $opacity1 {
        opacity: $opacity1;
    }
    @else if $opacity2 {
        opacity: $opacity2;
    }
}

// box-shadow
@mixin controlBoxShadow($boxShadow1, $boxShadow2: null) {
    @if $boxShadow1 {
        box-shadow: $boxShadow1;
    }
    @else if $boxShadow2 {
        box-shadow: $boxShadow2;
    }
}

// style
@mixin controlStyle($style1, $style2: null) {
    $color1: nth($style1, 1);
    $size1: nth($style1, 2);
    $weight1: nth($style1, 3);
    $borderWidth1: nth($style1, 4);
    $borderColor1: nth($style1, 5);
    $borderRadius1: nth($style1, 6);
    $background1: nth($style1, 7);
    $opacity1: nth($style1, 8);
    $boxShadow1: nth($style1, 9);

    @if $style2 {
        $color2: nth($style2, 1);
        $size2: nth($style2, 2);
        $weight2: nth($style2, 3);
        $borderWidth2: nth($style2, 4);
        $borderColor2: nth($style2, 5);
        $borderRadius2: nth($style2, 6);
        $background2: nth($style2, 7);
        $opacity2: nth($style2, 8);
        $boxShadow2: nth($style2, 9);

        @include controlColor($color1, $color2);
        @include controlSize($size1, $size2);
        @include controlWeight($weight1, $weight2);
        @include controlBorderWidth($borderWidth1, $borderWidth2);
        @include controlBorderColor($borderColor1, $borderColor2);
        @include controlBorderRadius($borderRadius1, $borderRadius2);
        @include controlBackground($background1, $background2);
        @include controlOpacity($opacity1, $opacity2);
        @include controlBoxShadow($boxShadow1, $boxShadow2);
    }
    @else {
        @include controlColor($color1);
        @include controlSize($size1);
        @include controlWeight($weight1);
        @include controlBorderWidth($borderWidth1);
        @include controlBorderColor($borderColor1);
        @include controlBorderRadius($borderRadius1);
        @include controlBackground($background1);
        @include controlOpacity($opacity1);
        @include controlBoxShadow($boxShadow1);
    }
}
