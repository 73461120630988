﻿@import '../../App/Controls/Loading/LoadingFunctions.scss';
@import 'control-helpers.scss';
@import 'sprites.scss';

.bom-view {
    display: flex;
    flex-direction: column;
    flex: 1;
    font-size: 12px;

    .content {
        flex: 1 1 auto;
        display: flex;
        align-items: stretch;
        $width-right-navigation: 272px;
        $width-left-navigation: 48px;
        height: 100%;

        .left-navigation {
            flex: 0 0 auto;
            width: $width-left-navigation;
            min-width: 0;
            $left-navigation-background-rgba: rgba(0, 0, 0, 0.5);

            &-menu {
                width: 100%;
                display: flex;
                flex-grow: 1;
                height: 100%;

                .tab-menu {
                    height: 100%;
                }
            }
        }

        .content-control {
            flex: 1 0 auto;
            display: flex;
            align-items: stretch;

            .content-control-body {
                flex: 1 0 auto;
                display: flex;
                align-items: stretch;
                width: 0;

                .navigation {
                    flex: 0 0 auto;
                    width: 272px;
                    min-width: 0;
                    min-height: 100%;
                    padding-bottom: 100px;

                    .navigation-container {

                        .hidden {
                            display: none;
                        }
                    }

                    .boms-container .container-content {
                        padding: 0;

                        .no-boms {
                            margin: 16px;
                            color: $color-iron;

                            .title {
                                color: $color-gray;
                                font-weight: 700;
                                margin: 0 0 4px 0;
                            }

                            .message {
                                margin: 0;
                            }
                        }

                        .boms {
                            /*border-top: 1px solid $color-0xddd;*/
                            display: flex;
                            flex-direction: column;

                            .bom-container {

                                .bom {
                                    display: flex;
                                    align-items: center;
                                    cursor: pointer;
                                    height: 36px;

                                    &:hover {
                                        background: $color-light-gray;
                                    }

                                    &.selected {
                                        background: $color-light-yellowish-gray;

                                        .selector {
                                            background: $color-light-yellowish-gray;
                                        }
                                    }

                                    .selector {
                                        width: 8px;
                                        align-self: stretch;
                                        flex: 0 0 auto;
                                    }

                                    .text {
                                        max-height: 2.85714286em;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        padding: 0 8px 0 8px;
                                        min-width: 0;
                                        color: $color-iron;
                                    }
                                }
                            }
                        }

                        .boms-pagination {
                            padding: 8px 16px;
                            margin: 0;
                            border-top: 1px solid $color-0xddd;
                            display: flex;

                            .hide-pagination {
                                visibility: hidden;
                            }

                            .button {
                                width: 48px;
                                height: 32px;

                                .sprite {
                                    margin: 0 auto;
                                }

                                &:hover {
                                    z-index: 1;
                                }

                                &:focus {
                                    z-index: 2;
                                }
                            }

                            .boms-pagination-down {
                                border-top-right-radius: 0px;
                                border-bottom-right-radius: 0px;

                                .sprite {
                                    @include sprite($sprite-arrow-down-medium);
                                }

                                &[disabled] .sprite {
                                    @include sprite($sprite-arrow-down-gray);
                                }
                            }

                            .boms-pagination-up {
                                border-top-left-radius: 0px;
                                border-bottom-left-radius: 0px;
                                margin-left: -1px;

                                .sprite {
                                    @include sprite($sprite-arrow-up-medium);
                                }

                                &[disabled] .sprite {
                                    @include sprite($sprite-arrow-up-gray);
                                }
                            }
                        }
                    }
                }

                .main {
                    flex: 1;
                    border: 1px solid $color-medium-gray;
                    border-top: 0;
                    border-bottom: 0;
                    min-width: 0;

                    .main-top {
                        min-height: 96px;
                        padding: 8px 16px;
                        border-bottom: 1px solid $color-medium-gray;
                        z-index: 1;
                        position: relative;
                        display: flex;
                        flex-direction: column;

                        .included-label {
                            margin: 0;
                            font-weight: 700;
                        }

                        .included-text {
                            margin: 0;
                            word-wrap: break-word;
                        }

                        .remarks {
                            flex-grow: 1;
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-end;
                        }
                    }

                    .main-container {
                        .bom-details-grid {
                            .grid-header {
                                display: flex;
                                background-color:$color-light-gambogeish-gray;
                                color: $color-iron;
                                font-weight: 700;

                                .cell {
                                    padding: 8px 16px;
                                    border-right: 1px solid $color-light-gambogeish-gray;

                                    &:last-child {
                                        border-right: none;
                                    }
                                }
                            }

                            .grid-body {
                                .grid-row {
                                    display: flex;
                                    border-bottom: 1px solid $color-light-gambogeish-gray;

                                    .cell {
                                        padding: 16px;
                                        border-right: 1px solid $color-light-gambogeish-gray;

                                        &:last-child {
                                            border-right: none;
                                            padding: 8px 16px;
                                        }

                                        .text-box-container {
                                            width: 100%;
                                        }
                                    }
                                }
                            }

                            .cell {
                                flex: 1;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                            }

                            .item-number-cell {
                                flex: 0.75;
                                display: flex;
                            }

                            .required-cell {
                                flex: 0.75;
                            }
                        }
                    }
                }

                .main-right {
                    flex: 0 0 auto;
                    width: 272px;

                    .button {
                        border: none;
                        border-radius: 0;
                        display: block;
                        height: 48px;
                        min-width: 0;
                    }

                    .transfer-online-button {
                        font-size: 14px;
                        padding: 0;
                        width: 100%;

                        .transfer-online-button-content {
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            .text {
                                margin-left: 36px;
                            }
                        }
                    }

                    .bottom-row {
                        display: flex;
                        overflow: hidden;

                        .button {
                            border-top: 1px solid black;
                        }

                        .print-button {
                            padding: 0 16px;
                            min-width: 75px;
                        }

                        .export-excel-button {
                            font-size: 14px;
                            padding: 0;
                            flex: 1;

                            .export-excel-button-content {
                                display: flex;
                                align-items: center;
                                justify-content: center;

                                .text {
                                    margin-left: 36px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
