﻿@import '../../../src/styles/colors.scss';

.popup-modal.default-modal {
    .modal-content {
        .modal-header {
            .modal-title {
                flex: 0 1 auto;
                margin-right: 0;
            }

            .info-container {
                flex: 1;
                margin-right: 16px;

                .info-button {
                    display: block;
                    padding: 0;
                    background: none;
                    border: none;
                    margin: 0 0 0 16px;
                }
            }
        }

        .modal-body {
            padding: 0;

            .popup-table {
                width: 100%;

                .table-cell {
                    padding: 16px;
                    flex-basis: 0;
                    flex-grow: 1;
                }

                .table-header {
                    .table-row {
                        color: $color-iron;
                        font-weight: 700;
                        display: flex;
                        align-items: center;
                        background: $color-light-gambogeish-gray;
                    }
                }

                .table-body {
                    overflow-y: auto;
                    max-height: 0;

                    .table-row {
                        border-bottom: 1px solid $color-light-gambogeish-gray;
                        display: flex;
                        align-items: center;

                        &:nth-last-child(2) {
                            border-bottom: none;
                        }
                    }
                }
            }
        }
    }
}
