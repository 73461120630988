﻿@import '../../../src/styles/colors.scss';

.support-modal.default-modal {
    .modal-content .modal-body {
        .support-message {
            width: 100%;
            min-height: 100px;
            resize: vertical;
        }

        .notification-support {
            background: $color-light-amberish-gray;
            color: $color-eggplant;
            padding: 8px;
            margin-bottom: 16px;

            p {
                display: flex;
                align-items: flex-start;
                margin-bottom: 4px;

                &:last-child {
                    margin-bottom: 0;
                }
            }

            .support-user-manual {
                display: flex;
                margin-bottom: 4px;

                .link-wrapper {
                    padding-left: 4px;
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }

            .support-info-header {
                font-weight: 700;
            }
        }

        .additional-data-support {
            background: $color-light-amberish-gray;
            color: $color-eggplant;
            padding: 8px;
            margin-bottom: 16px;

            p {
                display: flex;
                align-items: flex-start;
                margin-bottom: 4px;

                &:last-child {
                    margin-bottom: 0;
                }
            }

            .support-additional-data-header {
                font-weight: 700;
            }
        }

        .notification-faq {
            background: $color-light-yellowish-gray;
            color: $color-eggplant;
            padding: 8px;
            margin-bottom: 10px;

            .faq-info-header {
                font-weight: 700;
            }

            .faq-info {
                display: inline;
            }
        }
    }
}
