﻿@import 'colors.scss';
@import 'control-helpers.scss';

.radio {
    display: inline-flex;
    align-items: center;

    &.disabled .radio-label {
        @include controlStyle($style-radio-button-text-disabled);
    }

    .radio-button {
        flex-shrink: 0;
    }

    .radio-label {
        @include controlStyle($style-radio-button-text);
        padding-left: 8px;
        cursor: default;
    }
}

.radio-button {
    width: 16px;
    height: 16px;
    position: relative;
    outline: none;

    &:hover input:not([disabled]) {
        &:checked + span {
            background: $color-silver;

            &:after {
                background: $color-gray;
            }
        }

        & + span:after {
            background: $color-silver;
        }
    }

    &:focus span {
        border-color: $color-gambogeish-gray;
    }

    input {
        display: none;

        &:checked {
            & + span:after {
                background: $color-dark-blue-violetish-gray;
            }

            &[disabled] + span:after {
                background: $color-gray;
            }
        }

        &[disabled] + span {
            background: $color-silver;
            border-color: $color-gray;
            cursor: not-allowed;
        }
    }

    span {
        width: 16px;
        height: 16px;
        left: 0;
        top: 0;
        position: absolute;
        border: 1px solid $color-iron;
        background: $color-white;
        border-radius: 8px;

        &:after {
            content: ' ';
            position: absolute;
            width: 10px;
            height: 10px;
            left: 2px;
            top: 2px;
            border-radius: 5px;
        }
    }
}
